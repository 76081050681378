import { CONNECTION_ACTION, SUPPLIER_OF_GOODS } from 'constants/dataSets';
import {
  useCreateLogicalConnectionMutation,
  useCreateServiceConnectionMutation,
  useCreateSupplierConnectionMutation,
  useDeleteLogicalConnectionMutation,
  useDeleteServiceConnectionMutation,
  useDeleteSupplierConnectionMutation,
  useUpdateLogicalConnectionMutation,
  useUpdateServiceConnectionMutation,
  useUpdateSupplierConnectionMutation,
} from 'store/slices/valueChainMap/apis/valueChainMapApi';

import type { Connection, Edge } from '@xyflow/react';
import type IExpandedState from 'components/ConnectionVCModal/interfaces/IExpandedState';
import type { ISubmitConnection } from '../interfaces/ISubmitConnection';

const useCreateConnection = ({ selectedEdge }: { selectedEdge: Edge[] }) => {
  const [createLogicalConnection,
    { isLoading: isCreateLogicalConnectionLoading }] = useCreateLogicalConnectionMutation();
  const [updateLogicalConnection,
    { isLoading: isUpdateLogicalConnectionLoading }] = useUpdateLogicalConnectionMutation();
  const [deleteLogicalConnection,
    { isLoading: isDeleteLogicalConnectionLoading }] = useDeleteLogicalConnectionMutation();

  const [createSupplierConnection,
    { isLoading: isCreateSupplierConnectionLoading }] = useCreateSupplierConnectionMutation();
  const [updateSupplierConnection,
    { isLoading: isUpdateSupplierConnectionLoading }] = useUpdateSupplierConnectionMutation();
  const [deleteSupplierConnection,
    { isLoading: isDeleteSupplierConnectionLoading }] = useDeleteSupplierConnectionMutation();

  const [createServiceConnection,
    { isLoading: isCreateServiceConnectionLoading }] = useCreateServiceConnectionMutation();
  const [updateServiceConnection,
    { isLoading: isUpdateServiceConnectionLoading }] = useUpdateServiceConnectionMutation();
  const [deleteServiceConnection,
    { isLoading: isDeleteServiceConnectionLoading }] = useDeleteServiceConnectionMutation();

  const getNumber = (number: string): number => {
    if (!number) return 0;
    return Number(number.replace(/,/g, ''));
  };

  const transportMethods = (values: ISubmitConnection) => Object.keys(values).filter(
    (key) => values[key as keyof ISubmitConnection] === true,
  );

  const createConnection = (
    values: ISubmitConnection,
    currentConnection: Connection | undefined,
    connection: IExpandedState,
    valueChainMapId?: string,
  ) => {
    if (connection.logical) {
      createLogicalConnection({
        valueChainMapId: Number(valueChainMapId),
        fromUnitId: Number(currentConnection?.source.split('_')[1]),
        toUnitId: Number(currentConnection?.target.split('_')[1]),
        connectionType: values?.connectionType?.value ?? null,
        comment: values?.commentLogical !== '' ? values?.commentLogical : null,
        ownership: values?.ownershipPercentage ? getNumber(values?.ownershipPercentage) : null,
      });
    }

    if (connection.supplier) {
      createSupplierConnection({
        valueChainMapId: Number(valueChainMapId),
        fromUnitId: Number(currentConnection?.source.split('_')[1]),
        toUnitId: Number(currentConnection?.target.split('_')[1]),
        transportMethods: transportMethods(values),
        distance: values?.distance !== '' ? values?.distance : null,
        monetaryValue: values?.monetaryVolumes ? getNumber(values?.monetaryVolumes) : null,
        currency: values?.unitMonetaryVolumes?.value ?? null,
        quantity: values?.quantity ? getNumber(values?.quantity) : null,
        quantityUnit: values?.unitCurrency?.value ?? null,
      });
    }

    if (connection.service) {
      createServiceConnection({
        valueChainMapId: Number(valueChainMapId),
        fromUnitId: Number(currentConnection?.source.split('_')[1]),
        toUnitId: Number(currentConnection?.target.split('_')[1]),
        serviceProviderType: values?.typeOfServiceProvider?.value ?? null,
        monetaryValue: values?.monetaryValues ? getNumber(values?.monetaryValues) : null,
        monetaryCurrency: values?.unitCurrencyValues?.value ?? null,
        comment: values?.commentService !== '' ? values?.commentService : null,
      });
    }
  };

  const updateConnection = (
    values: ISubmitConnection,
    connection: IExpandedState,
    valueChainMapId?: string,
  ) => {
    const expanded = {
      logical: false,
      supplier: false,
      service: false,
    };
    selectedEdge.forEach((item) => {
      expanded[item?.data?.connectionType as keyof IExpandedState] = true;
      if (item?.data?.connectionType === SUPPLIER_OF_GOODS) {
        expanded.supplier = true;
      }
    });
    const toId = values?.idToLogical || values?.idToSupplier || values?.idToService || 0;
    if (expanded.logical && connection.logical) {
      updateLogicalConnection({
        id: values?.idLogical ?? 0,
        toUnitId: values?.idToLogical ?? 0,
        connectionType: values?.connectionType?.value ?? null,
        comment: values?.commentLogical !== '' ? values?.commentLogical : null,
        ownership: values?.ownershipPercentage ? getNumber(values?.ownershipPercentage) : null,
      });
    }
    if (expanded.supplier && connection.supplier) {
      updateSupplierConnection({
        id: values?.idSupplier ?? 0,
        toUnitId: values?.idToSupplier ?? 0,
        transportMethods: transportMethods(values),
        distance: values?.distance !== '' ? values?.distance : null,
        monetaryValue: values?.monetaryVolumes ? getNumber(values?.monetaryVolumes) : null,
        currency: values?.unitMonetaryVolumes?.value ?? null,
        quantity: values?.quantity ? getNumber(values?.quantity) : null,
        quantityUnit: values?.unitCurrency?.value ?? null,
      });
    }
    if (expanded.service && connection.service) {
      updateServiceConnection({
        id: values?.idService ?? 0,
        toUnitId: values?.idToService ?? 0,
        serviceProviderType: values?.typeOfServiceProvider?.value ?? null,
        monetaryValue: values?.monetaryValues ? getNumber(values?.monetaryValues) : null,
        monetaryCurrency: values?.unitCurrencyValues?.value ?? null,
        comment: values?.commentService !== '' ? values?.commentService : null,
      });
    }

    if (!expanded.logical && connection.logical) {
      createLogicalConnection({
        valueChainMapId: Number(valueChainMapId),
        fromUnitId: values?.idFrom ?? 0,
        toUnitId: toId,
        connectionType: values?.connectionType?.value ?? null,
        comment: values?.commentLogical !== '' ? values?.commentLogical : null,
        ownership: values?.ownershipPercentage ? getNumber(values?.ownershipPercentage) : null,
      });
    }
    if (!expanded.supplier && connection.supplier) {
      createSupplierConnection({
        valueChainMapId: Number(valueChainMapId),
        fromUnitId: values?.idFrom ?? 0,
        toUnitId: toId,
        transportMethods: transportMethods(values).length > 0 ? transportMethods(values) : null,
        distance: values?.distance !== '' ? values?.distance : null,
        monetaryValue: values?.monetaryVolumes ? getNumber(values?.monetaryVolumes) : null,
        currency: values?.unitMonetaryVolumes?.value ?? null,
        quantity: values?.quantity ? getNumber(values?.quantity) : null,
        quantityUnit: values?.unitCurrency?.value ?? null,
      });
    }
    if (!expanded.service && connection.service) {
      createServiceConnection({
        valueChainMapId: Number(valueChainMapId),
        fromUnitId: values?.idFrom ?? 0,
        toUnitId: toId,
        serviceProviderType: values?.typeOfServiceProvider?.value ?? null,
        monetaryValue: values?.monetaryValues ? getNumber(values?.monetaryValues) : null,
        monetaryCurrency: values?.unitCurrencyValues?.value ?? null,
        comment: values?.commentService !== '' ? values?.commentService : null,
      });
    }

    if (expanded.logical && !connection.logical) {
      deleteLogicalConnection({
        id: values?.idLogical ?? 0,
      });
    }
    if (expanded.supplier && !connection.supplier) {
      deleteSupplierConnection({
        id: values?.idSupplier ?? 0,
      });
    }
    if (expanded.service && !connection.service) {
      deleteServiceConnection({
        id: values?.idService ?? 0,
      });
    }
  };

  const onActionConnect = (
    values: ISubmitConnection,
    currentConnection: Connection | undefined,
    connection: IExpandedState,
    valueChainMapId?: string,
    action?: string,
  ) => {
    if (action === CONNECTION_ACTION.CREATE) {
      createConnection(values, currentConnection, connection, valueChainMapId);
    }
    if (action === CONNECTION_ACTION.UPDATE) {
      updateConnection(values, connection, valueChainMapId);
    }
  };

  return {
    isCreateLogicalConnectionLoading,
    isUpdateLogicalConnectionLoading,
    isDeleteLogicalConnectionLoading,
    isCreateSupplierConnectionLoading,
    isUpdateSupplierConnectionLoading,
    isDeleteSupplierConnectionLoading,
    isCreateServiceConnectionLoading,
    isUpdateServiceConnectionLoading,
    isDeleteServiceConnectionLoading,
    onActionConnect,
  };
};

export default useCreateConnection;
