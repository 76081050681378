import { ReactComponent as AuditorIcon } from 'assets/icons/auditor.svg';
import { ReactComponent as ContributorIcon } from 'assets/icons/contributor.svg';
import { ReactComponent as SpeakerIcon } from 'assets/icons/speaker.svg';
import { ReactComponent as UserSettingsIcon } from 'assets/icons/user-settings.svg';
import { USERS_ROLE } from 'constants/interfaces';

import type { IRoleIcon } from './interfaces/IRoleIcon';

function RoleIcon({ role }: IRoleIcon) {
  const roleIcons = {
    [USERS_ROLE.administrator]: <UserSettingsIcon />,
    [USERS_ROLE.facilitator]: <SpeakerIcon />,
    [USERS_ROLE.contributor]: <ContributorIcon />,
    [USERS_ROLE.auditor]: <AuditorIcon />,
    [USERS_ROLE.companyAdmin]: <UserSettingsIcon />,
    [USERS_ROLE.signatory]: <AuditorIcon />,
    [USERS_ROLE.admin]: null,
  };

  return role ? roleIcons?.[role] : null;
}

export default RoleIcon;
