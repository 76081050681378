import { VALUE_CHAIN_MAP_LIST_TAG } from '../store/tags/valueChainMap';

import type IDeleteValueChainItemFromList from './interfaces/IDeleteValueChainItemFromList';
import type IUpdateValueChainMapList from './interfaces/IUpdateValueChainMapList';

export const updateValueChainMapList = ({
  valueChainMapApi, state, updatedData, dispatch, chainId,
}: IUpdateValueChainMapList) => {
  valueChainMapApi.util.selectInvalidatedBy(state, [VALUE_CHAIN_MAP_LIST_TAG])
    .forEach(({ endpointName, originalArgs }) => {
      if (endpointName === 'getValueChainMapList') {
        dispatch(valueChainMapApi.util.updateQueryData(
          endpointName,
          originalArgs,
          (cache) => {
            const parseCache = cache;
            const index = parseCache.data.items.findIndex((item) => item.id === +chainId);

            if (index !== undefined && index !== -1) {
              const { createdBy } = parseCache.data.items[index];
              parseCache.data.items[index] = { ...updatedData.data, createdBy };
            }

            return parseCache;
          },
        ));
      }
    });
};

export const deleteValueChainItemFromList = ({
  valueChainMapApi, state, dispatch, chainId,
}: IDeleteValueChainItemFromList) => {
  valueChainMapApi.util.selectInvalidatedBy(state, [VALUE_CHAIN_MAP_LIST_TAG])
    .forEach(({ endpointName, originalArgs }) => {
      if (endpointName === 'getValueChainMapList') {
        dispatch(valueChainMapApi.util.updateQueryData(
          endpointName,
          originalArgs,
          (cache) => {
            const parseCache = cache;
            const index = parseCache.data.items.findIndex((item) => item.id === chainId);

            if (index !== undefined && index !== -1) {
              const cachedItems = [...parseCache.data.items];
              cachedItems.splice(index, 1);

              parseCache.data.items = cachedItems;
            }

            return parseCache;
          },
        ));
      }
    });
};
