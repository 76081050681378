import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DataSetsRecordButtons from 'components/DataSetsRecordButtons/DataSetsRecordButtons';
import DatePicker from 'components/DatePicker/DatePicker';
import FieldWithCurrency from 'components/FieldWithCurrency/FieldWithCurrency';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import NumberField from 'components/NumberField/NumberField';
import Panel from 'components/Panel/Panel';
import Select from 'components/Select/Select';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import { DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import {
  ADDRESS_BUILDING,
  ADDRESS_CITY, ADDRESS_COORDINATES,
  ADDRESS_COUNTRY, ADDRESS_POST_CODE, ADDRESS_STATE, ADDRESS_STREET,
  ASSET_CATEGORY,
  BUSINESS_UNIT_TYPE, DATE_CLOSED, DATE_OPENED,
  END_DATE,
  EQUITY_SHAREHOLDING, EU_TAXONOMY_CODE, FINANCIAL_CONTROL,
  LEGAL_NAME, NACE_CODE, NACE_SECTION,
  NAME, NET_BOOK, NET_BOOK_CURRENCY, NUMBER_OF_EMPLOYEES,
  ORG_PATH, OWNED_LEASED,
  OWNERSHIP,
  PROFIT_CENTER_ID,
  START_DATE,
  UNIQUE_IDENTIFIER,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { NUMBER_FORMATS } from 'constants/interfaces';

import type IFieldError from 'store/types/IFieldError';
import type { ICreateOwnOperationsView } from './interfaces/ICreateOwnOperationsView';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './sass/CreateOwnOperationsView.module.scss';
import stylesCurrency from 'components/FieldWithCurrency/scss/FieldWithCurrency.module.scss';

function CreateOwnOperationsView({
  control, onOwnOperationsSubmit, isConfirmationModalOpen, onHandleCloseConfirmation,
  onDiscardChangesConfirm, enumData, naceCodesData, naceDivisions, onNaceSelectionChange, profitCenters, isPageLoading,
  onFindCountries, onFindStates, onFindCities, isStatesDisabled, isCitiesDisabled, onChangeCountry, onChangeState,
  dateOpened, onDateOpenedChange, startDate, onStartDateChange,
}: ICreateOwnOperationsView) {
  const { t } = useTranslation();
  const { recordId = '' } = useParams();
  const placeholder = recordId ? t('common.notProvided') : '';

  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <div className={styles.wrapper}>
          <Loader isPageLoading={isPageLoading} isFullLoader={false} />
          <DataSetsPanel title={t('ownOperations.selections.general')}>
            <Controller
              control={control}
              name={ORG_PATH}
              render={({
                field: {
                  name,
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <TextField
                  name={name}
                  value={value}
                  label={t('ownOperations.fields.orgPath')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                  required
                  placeholder={placeholder}
                  className={styles.fullRow}
                />
              )}
            />
            <Controller
              name={NAME}
              control={control}
              render={({
                field: {
                  name,
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  name={name}
                  label={t('ownOperations.fields.name')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                  placeholder={placeholder}
                  required
                />
              )}
            />
            <Controller
              name={UNIQUE_IDENTIFIER}
              control={control}
              render={({
                field: {
                  value,
                  name,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  name={name}
                  label={t('ownOperations.fields.uniqueIdentifier')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                  placeholder={placeholder}
                  disabled={!!recordId}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name={LEGAL_NAME}
              render={({
                field: {
                  name,
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <TextField
                  name={name}
                  value={value}
                  label={t('ownOperations.fields.legalEntity')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                  required
                  placeholder={placeholder}
                />
              )}
            />
            <Controller
              control={control}
              name={PROFIT_CENTER_ID}
              render={({
                field: {
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <Select
                  label={t('ownOperations.fields.profitCentreID')}
                  placeholder={recordId ? placeholder : t('ownOperations.fields.profitCentreID')}
                  options={profitCenters}
                  value={value || undefined}
                  error={error?.message}
                  onChange={onChange}
                  isSearchable
                  isMulti
                />
              )}
            />
            <Controller
              name={VALUE_CHAIN_SECTION}
              control={control}
              render={({
                field: {
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <Select
                  label={t('ownOperations.fields.valueChain')}
                  placeholder={t('ownOperations.fields.valueChain')}
                  options={enumData?.[DATA_SETS_ENUM_TYPES.valueChainSection]}
                  onChange={onChange}
                  value={value?.value ? value : undefined}
                  error={(error as IFieldError)?.value?.message}
                  required
                />
              )}
            />
            <Controller
              name={OWNERSHIP}
              control={control}
              render={({
                field: {
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <Select
                  label={t('ownOperations.fields.ownership')}
                  placeholder={recordId ? placeholder : t('ownOperations.fields.ownership')}
                  options={enumData?.[DATA_SETS_ENUM_TYPES.ownership]}
                  value={value?.value ? value : undefined}
                  onChange={onChange}
                  error={(error as IFieldError)?.value?.message}
                />
              )}
            />
            <Controller
              name={EQUITY_SHAREHOLDING}
              control={control}
              render={({
                field: {
                  name,
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <NumberField
                  placeholder={placeholder}
                  value={value}
                  name={name}
                  error={error?.message}
                  label={t('ownOperations.fields.equityShareholding')}
                  onChange={(values) => onChange(values.value)}
                  type={NUMBER_FORMATS.percent}
                />
              )}
            />
            <Controller
              control={control}
              name={FINANCIAL_CONTROL}
              render={({
                field: {
                  name,
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <NumberField
                  value={value}
                  placeholder={placeholder}
                  type={NUMBER_FORMATS.percent}
                  name={name}
                  label={t('ownOperations.fields.financialControl')}
                  onChange={(values) => onChange(values.value)}
                  error={error?.message}
                />
              )}
            />
            <Controller
              name={BUSINESS_UNIT_TYPE}
              control={control}
              render={({
                field: {
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <Select
                  label={t('ownOperations.fields.physicalOrLogical')}
                  placeholder={t('ownOperations.fields.physicalOrLogical')}
                  options={enumData?.[DATA_SETS_ENUM_TYPES.businessUnitType]}
                  error={(error as IFieldError)?.value?.message}
                  value={value?.value ? value : undefined}
                  onChange={onChange}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name={ASSET_CATEGORY}
              render={({
                field: {
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <Select
                  label={t('ownOperations.fields.assetCategory')}
                  placeholder={t('ownOperations.fields.assetCategory')}
                  options={enumData?.[DATA_SETS_ENUM_TYPES.assetCategory]}
                  value={value || undefined}
                  onChange={onChange}
                  error={(error as IFieldError)?.message}
                  required
                  isMulti
                />
              )}
            />
            <Controller
              control={control}
              name={OWNED_LEASED}
              render={({
                field: {
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <Select
                  label={t('ownOperations.fields.owned')}
                  placeholder={recordId ? placeholder : t('ownOperations.fields.owned')}
                  options={enumData?.[DATA_SETS_ENUM_TYPES.ownedLeased]}
                  value={value?.value ? value : undefined}
                  onChange={onChange}
                  error={(error as IFieldError)?.value?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={NUMBER_OF_EMPLOYEES}
              render={({
                field: {
                  name,
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <NumberField
                  name={name}
                  value={value}
                  label={t('ownOperations.fields.employees')}
                  error={error?.message}
                  onChange={(values) => onChange(values.value)}
                  decimalScale={0}
                  required
                  placeholder={placeholder}
                />
              )}
            />
            <Controller
              name={DATE_OPENED}
              control={control}
              render={({
                field: {
                  value,
                  name,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <DatePicker
                  label={t('ownOperations.fields.dateOpened')}
                  placeholder={placeholder}
                  value={value}
                  onChange={(date) => {
                    onDateOpenedChange(date);
                    onChange(date);
                  }}
                  name={name}
                  error={error?.message}
                  required
                />
              )}
            />
            <Controller
              name={DATE_CLOSED}
              control={control}
              render={({
                field: {
                  value,
                  name,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <DatePicker
                  placeholder={placeholder}
                  label={t('ownOperations.fields.dateClosed')}
                  name={name}
                  onChange={onChange}
                  minDate={dateOpened}
                  value={value}
                  error={error?.message}
                />
              )}
            />
          </DataSetsPanel>

          <DataSetsPanel title={t('ownOperations.selections.details')}>
            <Controller
              name={NACE_SECTION}
              control={control}
              render={({
                field: {
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <Select
                  placeholder={t('ownOperations.fields.industry')}
                  label={t('ownOperations.fields.industry')}
                  value={value?.value ? value : undefined}
                  options={naceCodesData}
                  onChange={(newValue) => {
                    onChange(newValue);
                    onNaceSelectionChange();
                  }}
                  required
                  error={(error as IFieldError)?.value?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={NACE_CODE}
              render={({
                field: {
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <Select
                  label={t('ownOperations.fields.sector')}
                  placeholder={t('ownOperations.fields.sector')}
                  options={naceDivisions}
                  value={value || null}
                  onChange={onChange}
                  error={(error as IFieldError)?.value?.message}
                />
              )}
            />
            <Controller
              name={EU_TAXONOMY_CODE}
              control={control}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  value={value}
                  placeholder={placeholder}
                  name={name}
                  error={error?.message}
                  label={t('ownOperations.fields.taxonomy')}
                  onChange={(event) => onChange(event.target.value)}
                />
              )}
            />
          </DataSetsPanel>

          <DataSetsPanel title={t('ownOperations.selections.financials')}>
            <Controller
              name={START_DATE}
              control={control}
              render={({
                field: {
                  name,
                  onChange,
                  value,
                },
                fieldState: { error },
              }) => (
                <DatePicker
                  error={error?.message}
                  label={t('suppliers.fields.startDate')}
                  onChange={(date) => {
                    onStartDateChange(date);
                    onChange(date);
                  }}
                  value={value}
                  name={name}
                  maxDate={new Date()}
                  placeholder={placeholder}
                  required
                />
              )}
            />
            <Controller
              name={END_DATE}
              control={control}
              render={({
                field: {
                  name,
                  value,
                  onChange,
                },
                fieldState: { error },
              }) => (
                <DatePicker
                  label={t('suppliers.fields.endDate')}
                  placeholder={placeholder}
                  name={name}
                  onChange={onChange}
                  minDate={startDate}
                  value={value}
                  error={error?.message}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name={NET_BOOK}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <FieldWithCurrency name={name} label={t('ownOperations.fields.netBook')}>
                  <div className={stylesCurrency.fieldWithCurrency}>
                    <NumberField
                      name={name}
                      value={value}
                      error={error?.message}
                      onChange={(values) => onChange(values.value)}
                      required
                      placeholder={placeholder}
                    />
                    <Controller
                      control={control}
                      name={NET_BOOK_CURRENCY}
                      render={({
                        field: { value: valueCurrency, onChange: onChangeCurrency },
                        fieldState: { error: errorCurrency },
                      }) => (
                        <Select
                          placeholder={t('common.currency')}
                          options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                          value={valueCurrency?.value ? valueCurrency : undefined}
                          onChange={onChangeCurrency}
                          error={(errorCurrency as IFieldError)?.value?.message}
                          className={stylesCurrency.select}
                          required
                        />
                      )}
                    />
                  </div>
                </FieldWithCurrency>
              )}
            />
          </DataSetsPanel>

          <DataSetsPanel title={t('ownOperations.selections.address')}>
            <Controller
              control={control}
              name={ADDRESS_COUNTRY}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Select
                  label={t('ownOperations.fields.country')}
                  placeholder={null}
                  value={value || null}
                  onChange={(newValue) => {
                    onChange(newValue);
                    onChangeCountry();
                  }}
                  error={error?.message}
                  loadOptions={onFindCountries}
                  isSearchable
                  isAsync
                  isClearable
                  className={styles.asyncSelect}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name={ADDRESS_STATE}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Select
                  label={t('ownOperations.fields.state')}
                  placeholder={null}
                  value={value}
                  onChange={(newValue) => {
                    onChange(newValue);
                    onChangeState();
                  }}
                  error={error?.message}
                  loadOptions={onFindStates}
                  isSearchable
                  isAsync
                  isClearable
                  className={clsx(styles.asyncSelect, {
                    [styles.inactiveSelect]: isStatesDisabled,
                  })}
                  isDisabled={isStatesDisabled}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name={ADDRESS_POST_CODE}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  value={value}
                  placeholder={placeholder}
                  name={name}
                  label={t('ownOperations.fields.post')}
                  onChange={(event) => onChange(event.target.value)}
                  error={error?.message}
                />
              )}
            />
            <Controller
              name={ADDRESS_CITY}
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Select
                  label={t('ownOperations.fields.town')}
                  value={value || null}
                  placeholder={null}
                  onChange={onChange}
                  loadOptions={onFindCities}
                  error={error?.message}
                  isClearable
                  isAsync
                  isSearchable
                  className={clsx(styles.asyncSelect, {
                    [styles.inactiveSelect]: isCitiesDisabled || isStatesDisabled,
                  })}
                  isDisabled={isCitiesDisabled}
                />
              )}
            />
            <Controller
              control={control}
              name={ADDRESS_STREET}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  value={value}
                  name={name}
                  placeholder={placeholder}
                  label={t('ownOperations.fields.street')}
                  onChange={(event) => onChange(event.target.value)}
                  error={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={ADDRESS_BUILDING}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  value={value}
                  placeholder={placeholder}
                  name={name}
                  error={error?.message}
                  label={t('ownOperations.fields.building')}
                  onChange={(event) => onChange(event.target.value)}
                />
              )}
            />
            <Controller
              name={ADDRESS_COORDINATES}
              control={control}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  value={value}
                  name={name}
                  placeholder={placeholder}
                  label={t('ownOperations.fields.coordinates')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                />
              )}
            />
          </DataSetsPanel>

        </div>
        <Separator />
        <Modal
          open={isConfirmationModalOpen}
          title={t('createNewUser.cancelModalTitle')}
          description={t('clients.cancelModalText')}
          confirmTitle={t('common.discardChanges')}
          closeTitle={t('common.keepEditing')}
          confirmVariant="error"
          onClose={() => onHandleCloseConfirmation(false)}
          onReject={() => onHandleCloseConfirmation(false)}
          onConfirm={onDiscardChangesConfirm}
          isLoading={isPageLoading}
        />
        <DataSetsRecordButtons
          onClose={onHandleCloseConfirmation}
          onSubmit={onOwnOperationsSubmit}
          isLoading={isPageLoading}
        />
      </Panel>
    </div>
  );
}

export default CreateOwnOperationsView;
