import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import clsx from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import getIcon from 'utils/getIcon';

import type ICheckboxItem from '../interfaces/ICheckboxItem';

import styles from '../sass/CheckboxParent.module.scss';

function CheckboxItem({
  name, checked, onChange, label, type, showSquare, isExpanded, toggleAccordion, hideChevron,
  icon,
}: ICheckboxItem) {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.line} />
      { !hideChevron ? (
        <div
          className={clsx(styles.iconWrapper, { [styles.active]: isExpanded })}
          onClick={toggleAccordion}
          role="button"
          tabIndex={0}
          onKeyDown={toggleAccordion}
        >
          <ChevronIcon />
        </div>
      ) : null}
      <Checkbox
        name={name}
        checked={checked}
        value={String(checked)}
        onChange={(target) => onChange(target, type)}
        label={(<span>{label}</span>)}
        showSquare={showSquare}
        icon={getIcon(icon ?? '')}
      />
    </div>
  );
}
export default CheckboxItem;
