import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DatePicker from 'components/DatePicker/DatePicker';
import FieldWithCurrency from 'components/FieldWithCurrency/FieldWithCurrency';
import NumberField from 'components/NumberField/NumberField';
import Select from 'components/Select/Select';
import { DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import {
  END_DATE,
  NET_BOOK,
  NET_BOOK_CURRENCY,
  START_DATE,
} from 'constants/formFields';
import { MENU_POSITION } from 'constants/general';

import type IFieldError from 'store/types/IFieldError';
import type IDataSetPanelOwnOperationsFinansials from '../../interfaces/IDataSetPanelOwnOperationsFinansials';

import stylesCurrency from 'components/FieldWithCurrency/scss/FieldWithCurrency.module.scss';

export default function DataSetPanelOwnOperationsFinansials({
  control,
  onStartDateChange,
  startDate,
  enumData,
  placeholder,
}: IDataSetPanelOwnOperationsFinansials) {
  const { t } = useTranslation();
  return (
    <DataSetsPanel title={t('ownOperations.selections.financials')}>
      <Controller
        name={START_DATE}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <DatePicker
            error={error?.message}
            label={t('suppliers.fields.startDate')}
            onChange={(date) => {
              onStartDateChange(date);
              onChange(date);
            }}
            placeholder={placeholder}
            name={name}
            maxDate={new Date()}
            value={value}
          />
        )}
      />
      <Controller
        name={END_DATE}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <DatePicker
            error={error?.message}
            placeholder={placeholder}
            label={t('suppliers.fields.endDate')}
            name={name}
            onChange={onChange}
            minDate={startDate}
            value={value}
          />
        )}
      />
      <Controller
        name={NET_BOOK}
        control={control}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <FieldWithCurrency name={name} label={t('ownOperations.fields.netBook')}>
            <div className={stylesCurrency.fieldWithCurrency}>
              <NumberField
                value={value}
                name={name}
                onChange={(values) => onChange(values.value)}
                error={error?.message}
                placeholder={placeholder}
                required
              />
              <Controller
                name={NET_BOOK_CURRENCY}
                control={control}
                render={({
                  field: { value: valueCurrency, onChange: onChangeCurrency },
                  fieldState: { error: errorCurrency },
                }) => (
                  <Select
                    options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                    placeholder={t('common.currency')}
                    onChange={onChangeCurrency}
                    value={valueCurrency?.value ? valueCurrency : undefined}
                    className={stylesCurrency.select}
                    required
                    error={(errorCurrency as IFieldError)?.value?.message}
                    menuPosition={MENU_POSITION.ABSOLUTE}
                  />
                )}
              />
            </div>
          </FieldWithCurrency>
        )}
      />
    </DataSetsPanel>
  );
}
