import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DatePicker from 'components/DatePicker/DatePicker';
import NumberField from 'components/NumberField/NumberField';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import {
  DATA_SETS_ENUM_TYPES,
} from 'constants/dataSets';
import {
  ASSET_CATEGORY,
  BUSINESS_UNIT_TYPE,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_SHAREHOLDING,
  FINANCIAL_CONTROL,
  LEGAL_NAME,
  NAME,
  NUMBER_OF_EMPLOYEES,
  ORG_PATH,
  OWNED_LEASED,
  OWNERSHIP,
  PROFIT_CENTER_ID,
  UNIQUE_IDENTIFIER,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { MENU_POSITION } from 'constants/general';
import { NUMBER_FORMATS } from 'constants/interfaces';

import type IFieldError from 'store/types/IFieldError';
import type IDataSetPanelOwnOperationsGeneral from '../../interfaces/IDataSetPanelOwnOperationsGeneral';

import styles from '../sass/ProfitCentreModal.module.scss';

export default function DataSetPanelOwnOperationsGeneral({
  control,
  recordId,
  enumData,
  onDateOpenedChange,
  dateOpened,
  placeholder,
  preparedProfitCenterList,
}: IDataSetPanelOwnOperationsGeneral) {
  const { t } = useTranslation();
  return (
    <DataSetsPanel title={t('ownOperations.selections.general')}>
      <Controller
        name={ORG_PATH}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <TextField
            placeholder={placeholder}
            className={styles.fullRow}
            value={value}
            name={name}
            error={error?.message}
            label={t('ownOperations.fields.orgPath')}
            onChange={(event) => onChange(event.target.value)}
            required
          />
        )}
      />
      <Controller
        name={NAME}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <TextField
            placeholder={placeholder}
            name={name}
            label={t('ownOperations.fields.name')}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            error={error?.message}
            required
          />
        )}
      />
      <Controller
        name={UNIQUE_IDENTIFIER}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <TextField
            name={name}
            value={value}
            label={t('ownOperations.fields.uniqueIdentifier')}
            error={error?.message}
            onChange={(event) => onChange(event.target.value)}
            required
            placeholder={placeholder}
            disabled={!!recordId}
          />
        )}
      />
      <Controller
        name={LEGAL_NAME}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <TextField
            value={value}
            name={name}
            error={error?.message}
            label={t('ownOperations.fields.legalEntity')}
            onChange={(event) => onChange(event.target.value)}
            placeholder={placeholder}
            required
          />
        )}
      />
      <Controller
        name={PROFIT_CENTER_ID}
        control={control}
        render={({
          field: {
            onChange,
            value,
          },
          fieldState: { error },
        }) => (
          <Select
            placeholder={recordId ? placeholder : t('ownOperations.fields.profitCentreID')}
            label={t('ownOperations.fields.profitCentreID')}
            value={value}
            options={preparedProfitCenterList}
            error={error?.message}
            onChange={onChange}
            isSearchable
            isMulti
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={VALUE_CHAIN_SECTION}
        control={control}
        render={({
          field: {
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <Select
            placeholder={t('ownOperations.fields.valueChain')}
            label={t('ownOperations.fields.valueChain')}
            value={value?.value ? value : undefined}
            error={(error as IFieldError)?.value?.message}
            options={enumData?.[DATA_SETS_ENUM_TYPES.valueChainSection]}
            onChange={onChange}
            required
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={OWNERSHIP}
        control={control}
        render={({
          field: {
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <Select
            placeholder={recordId ? placeholder : t('ownOperations.fields.ownership')}
            label={t('ownOperations.fields.ownership')}
            value={value?.value ? value : undefined}
            options={enumData?.[DATA_SETS_ENUM_TYPES.ownership]}
            error={(error as IFieldError)?.value?.message}
            onChange={onChange}
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={EQUITY_SHAREHOLDING}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <NumberField
            value={value}
            name={name}
            error={error?.message}
            placeholder={placeholder}
            label={t('ownOperations.fields.equityShareholding')}
            type={NUMBER_FORMATS.percent}
            onChange={(values) => onChange(values.value)}
          />
        )}
      />
      <Controller
        name={FINANCIAL_CONTROL}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <NumberField
            label={t('ownOperations.fields.financialControl')}
            name={name}
            value={value}
            onChange={(values) => onChange(values.value)}
            error={error?.message}
            placeholder={placeholder}
            type={NUMBER_FORMATS.percent}
          />
        )}
      />
      <Controller
        name={BUSINESS_UNIT_TYPE}
        control={control}
        render={({
          field: {
            onChange,
            value,
          },
          fieldState: { error },
        }) => (
          <Select
            placeholder={t('ownOperations.fields.physicalOrLogical')}
            label={t('ownOperations.fields.physicalOrLogical')}
            value={value}
            options={enumData?.[DATA_SETS_ENUM_TYPES.businessUnitType]}
            error={(error as IFieldError)?.value?.message}
            required
            isDisabled
            onChange={onChange}
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={ASSET_CATEGORY}
        control={control}
        render={({
          field: {
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <Select
            placeholder={t('ownOperations.fields.assetCategory')}
            label={t('ownOperations.fields.assetCategory')}
            value={value || undefined}
            options={enumData?.[DATA_SETS_ENUM_TYPES.assetCategory]}
            error={(error as IFieldError)?.message}
            onChange={onChange}
            isMulti
            required
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={OWNED_LEASED}
        control={control}
        render={({
          field: {
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <Select
            label={t('ownOperations.fields.owned')}
            options={enumData?.[DATA_SETS_ENUM_TYPES.ownedLeased]}
            error={(error as IFieldError)?.value?.message}
            placeholder={recordId ? placeholder : t('ownOperations.fields.owned')}
            onChange={onChange}
            value={value?.value ? value : undefined}
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={NUMBER_OF_EMPLOYEES}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <NumberField
            value={value}
            name={name}
            error={error?.message}
            label={t('ownOperations.fields.employees')}
            decimalScale={0}
            onChange={(values) => onChange(values.value)}
            placeholder={placeholder}
            required
          />
        )}
      />
      <Controller
        name={DATE_OPENED}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <DatePicker
            error={error?.message}
            label={t('ownOperations.fields.dateOpened')}
            onChange={(date) => {
              onDateOpenedChange(date);
              onChange(date);
            }}
            value={value}
            name={name}
            required
            placeholder={placeholder}
          />
        )}
      />
      <Controller
        name={DATE_CLOSED}
        control={control}
        render={({
          field: {
            name,
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <DatePicker
            error={error?.message}
            label={t('ownOperations.fields.dateClosed')}
            name={name}
            onChange={onChange}
            minDate={dateOpened}
            placeholder={placeholder}
            value={value}
          />
        )}
      />
    </DataSetsPanel>
  );
}
