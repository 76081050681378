import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type { IGeneral } from './interfaces/IGeneral';

const selectGeneral = (state: RootState): IGeneral => state.general;

const selectFullScreenActive = createSelector(selectGeneral, (generalState) => generalState.isFullScreenActive);

export default selectFullScreenActive;
