import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button/Button';
import {
  BUTTON_VARIANT, COMPANY_MAP_TABS,
} from '../../../../constants/interfaces';

import type { ITopCenterControls } from './interfaces/ITopCenterControls';

import styles from './sass/TopCenterControls.module.scss';

function TopCenterControls({
  onTabHandler, currentTab,
}: ITopCenterControls) {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <div className={styles.centerPanel}>
        <div className={styles.switcher}>
          <Button
            title={t('valueChainMap.businessStructure')}
            onClick={() => onTabHandler(COMPANY_MAP_TABS.BUSINESS_STRUCTURE)}
            variant={currentTab === COMPANY_MAP_TABS.BUSINESS_STRUCTURE ? BUTTON_VARIANT.primary : BUTTON_VARIANT.ghost}
          />
          <Button
            title={t('valueChainMap.valueChain')}
            onClick={() => onTabHandler(COMPANY_MAP_TABS.VALUE_CHAIN_MAP)}
            variant={currentTab === COMPANY_MAP_TABS.VALUE_CHAIN_MAP ? BUTTON_VARIANT.primary : BUTTON_VARIANT.ghost}
          />
        </div>
      </div>
    </div>
  );
}

export default TopCenterControls;
