import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Panel from 'components/Panel/Panel';
import Select from 'components/Select/Select';
import {
  ACCESS, PILLAR_MODULES, PILLARS,
} from 'constants/formFields';
import { USER_PILLAR_ACCESS_TYPES } from 'constants/interfaces';
import { USER_ACCESS_TYPES } from 'constants/user';

import type ISelectedValue from 'store/slices/companyAdminUsers/interfaces/ISelectedValue';
import type { IPillarSelection } from '../../interfaces/IPillarSelection';

import styles from './sass/PermissionsForm.module.scss';

function PillarSelection({
  control, pillarModules, pillarIndex, pillarName, isDisabled,
}: IPillarSelection) {
  const { t } = useTranslation();
  const options = USER_ACCESS_TYPES.filter((item) => item.value !== USER_PILLAR_ACCESS_TYPES.dimmedDisabled);
  return (
    <Panel key={window.crypto.randomUUID()} className={styles.permissionPanel}>
      <div className={styles.header}>
        <p>{t(`pillarsAndModules.${pillarName}`)}</p>
      </div>
      <div className={styles.selectionContent}>
        <h4 className={styles.subtitle}>
          {t('permissions.featureModule')}
        </h4>
        {pillarModules?.map((module, i) => (
          <div className={styles.container} key={window.crypto.randomUUID()}>
            <div className={styles.item}>
              <p className={styles.itemTitle}>
                {t(`pillarsAndModules.${module.name}`)}
              </p>
            </div>
            <div className={styles.item}>
              <Controller
                control={control}
                name={`${PILLARS}.${pillarIndex}.${PILLAR_MODULES}.${i}.${ACCESS}`}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <Select
                    label={t('common.access')}
                    placeholder={value === USER_PILLAR_ACCESS_TYPES.dimmedDisabled ? t('common.disabled')
                      : t('common.access')}
                    name={name}
                    value={USER_ACCESS_TYPES.find((item) => item.value === value)}
                    error={error?.message}
                    options={options}
                    onChange={(newValue) => {
                      const { value: selectedValue } = newValue as ISelectedValue;
                      onChange(selectedValue);
                    }}
                    isDisabled={isDisabled || value === USER_PILLAR_ACCESS_TYPES.dimmedDisabled}
                  />
                )}
              />
            </div>
          </div>
        ))}
      </div>
    </Panel>
  );
}

export default PillarSelection;
