import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as UndoIcon } from 'assets/icons/undo.svg';

import IconButton from '../../../../components/IconButton/IconButton';
import { ICON_BUTTON_SIZES } from '../../../../constants/interfaces';

import styles from './sass/TopRightControls.module.scss';

function TopRightControls() {
  const { t } = useTranslation();

  return (
    <div className={styles.rightPanel}>
      <div className={styles.rightContent}>
        {/* // For the future update
            <Tooltip hint={t('valueChainMap.showLog')} position={TOOLTIP_POSITIONS.BOTTOM}>
              <IconButton
                size={ICON_BUTTON_SIZES.large}
                icon={<HistoryIcon />}
                onClick={() => {}}
                className={styles.icon}
              />
            </Tooltip>

            <div className={styles.divider} />
          */}
        <IconButton
          size={ICON_BUTTON_SIZES.large}
          icon={<UndoIcon />}
          onClick={() => {}}
          className={styles.icon}
        />
        <IconButton
          size={ICON_BUTTON_SIZES.large}
          icon={<UndoIcon />}
          onClick={() => {}}
          className={styles.undoIcon}
          disabled
        />
        <div className={styles.divider} />
        <div className={styles.saveContainer}>
          <div className={styles.checkMarkContainer}>
            <CheckmarkIcon width={12} height={12} />
          </div>
          <p className={styles.saveTitle}>{t('valueChainMap.saved')}</p>
        </div>
      </div>
    </div>
  );
}

export default TopRightControls;
