import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ActionIcon } from 'assets/icons/action.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import IconButton from 'components/IconButton/IconButton';
import RenameModal from 'components/RenameModal/RenameModal';
import Tooltip from 'components/Tooltip/Tooltip';
import useDropdownDirection from 'hooks/useDropdownDirection';
import useOutsideClick from 'hooks/useOutsideClick';
import DeleteModal from 'pages/CompanyAdminPages/ValueChainMap/components/DeleteModal/DeleteModal';

import DeactivationModal from '../../../../../components/DeactivationModals/DeactivationModal';
import { VALUE_CHAIN_STATUS } from '../../../../../constants/interfaces';
import ArchiveModal from '../ArchiveModal/ArchiveModal';
import MoreActionsDropdown from './components/MoreActionsDropdown';

import type IValueChainMapTableActions from '../../interfaces/IValueChainActions';

import styles from './scss/ValueChainMapTableActions.module.scss';

function ValueChainMapTableActions({
  onHandleEdit,
  isArchived,
  isSignOffAccess,
  onRenameValueChain,
  valueChainItemId,
  onDeleteValueChain,
  valueChainName,
  vcMapActions,
  tableRef,
  onArchiveValueChain,
  onDeArchiveValueChain,
  onDublicateValueChain,
  onReviewValueChain,
}: IValueChainMapTableActions) {
  const { t } = useTranslation();
  const [isMoreActionsModalOpen, setIsMoreActionsModalOpen] = useState(false);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const [isDublicateModalOpen, setDublicateModalOpen] = useState(false);
  const [isReWorkModalOpen, setReWorkModalOpen] = useState(false);
  const modalRef = useOutsideClick(() => setIsMoreActionsModalOpen(false));

  const { dropdownDirection } = useDropdownDirection({ isMoreActionsModalOpen, modalRef, tableRef });

  const renameHandler = (name: string) => {
    onRenameValueChain(valueChainItemId, name);
    setRenameModalOpen(false);
  };

  const deleteHandler = () => {
    onDeleteValueChain();
    setDeleteModalOpen(false);
  };

  const archiveHandler = (reason: string) => {
    onArchiveValueChain(valueChainItemId, reason);
    setArchiveModalOpen(false);
  };

  const reWorkHandler = (reason: string) => {
    onReviewValueChain(valueChainItemId, reason, VALUE_CHAIN_STATUS.requiresRework);
    setReWorkModalOpen(false);
  };

  const deArchiveHandler = () => {
    setIsMoreActionsModalOpen(false);
    onDeArchiveValueChain(valueChainItemId);
  };

  const dublicateHandler = (name: string) => {
    onDublicateValueChain(valueChainItemId, name);
    setDublicateModalOpen(false);
  };

  const openRenameModal = () => {
    setRenameModalOpen(true);
    setIsMoreActionsModalOpen(false);
  };

  const openDublicateModal = () => {
    setDublicateModalOpen(true);
    setIsMoreActionsModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {!isArchived ? (
          <>
            {vcMapActions.canEdit ? (
              <Tooltip hint={t('valueChainMap.edit')}>
                <IconButton
                  icon={<PenIcon />}
                  onClick={onHandleEdit}
                  className={styles.iconButton}
                />
              </Tooltip>
            ) : null}
            {vcMapActions.canDelete ? (
              <Tooltip hint={t('valueChainMap.delete')}>
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => setDeleteModalOpen(true)}
                  className={styles.iconButton}
                />
              </Tooltip>
            ) : null}
          </>
        ) : null}
        {isSignOffAccess ? (
          <>
            <Tooltip hint={t('valueChainMap.approve')}>
              <IconButton
                icon={<CheckMarkIcon />}
                onClick={() => onReviewValueChain(valueChainItemId, '', VALUE_CHAIN_STATUS.approved)}
                className={styles.iconButton}
              />
            </Tooltip>
            <Tooltip hint={t('valueChainMap.returnForReWork')}>
              <IconButton
                icon={<RefreshIcon />}
                onClick={() => setReWorkModalOpen(true)}
                className={styles.iconButton}
              />
            </Tooltip>
          </>
        ) : null}
        <div ref={modalRef}>
          {isSignOffAccess ? null : (
            <IconButton
              icon={<ActionIcon />}
              onClick={() => setIsMoreActionsModalOpen(!isMoreActionsModalOpen)}
              className={styles.iconButton}
            />
          )}
          {isMoreActionsModalOpen ? (
            <MoreActionsDropdown
              canRename={vcMapActions.canRename}
              isArchived={isArchived}
              setRenameModalOpen={openRenameModal}
              dropdownDirection={dropdownDirection}
              setArchiveModalOpen={setArchiveModalOpen}
              deArchiveHandler={deArchiveHandler}
              dublicateHandler={openDublicateModal}
            />
          ) : null}
        </div>
      </div>
      <RenameModal
        onSubmit={renameHandler}
        onReject={() => setRenameModalOpen(false)}
        open={isRenameModalOpen}
        name={valueChainName}
      />
      <RenameModal
        onSubmit={dublicateHandler}
        onReject={() => setDublicateModalOpen(false)}
        open={isDublicateModalOpen}
        name={`${t('valueChainMap.copyOf')} ${valueChainName}`}
      />
      <DeleteModal
        open={isDeleteModalOpen}
        onReject={() => setDeleteModalOpen(false)}
        isLoading={false}
        name={valueChainName}
        onConfirm={deleteHandler}
      />
      <DeactivationModal
        open={isReWorkModalOpen}
        onReject={() => setReWorkModalOpen(false)}
        isLoading={false}
        onSubmit={reWorkHandler}
        text={t('valueChainMap.reWorkReason')}
      />
      <ArchiveModal
        open={isArchiveModalOpen}
        onReject={() => setArchiveModalOpen(false)}
        isLoading={false}
        onSubmit={archiveHandler}
      />
    </div>
  );
}

export default ValueChainMapTableActions;
