import {
  END_DATE,
  FINISHED_GOOD_STOCK_AMOUNT, FINISHED_GOOD_STOCK_AMOUNT_CURRENCY,
  GROSS_MARGIN_ROLLING_AMOUNT, GROSS_MARGIN_ROLLING_CURRENCY,
  GROSS_MARGIN_ROLLING_PERCENTS,
  PROFIT_CENTRE_NAME, PROFIT_CENTRE_UNIQUE_IDENTIFIER,
  REVENUE_ROLLING_AMOUNT,
  REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
  START_DATE,
} from 'constants/formFields';

import getFormValue from '../getFormValue';

import type { IGetProfitCentreDefaultValues } from './interfaces/IGetProfitCentreDefaultValues';

function getProfitCentreDefaultValues({ values }: IGetProfitCentreDefaultValues) {
  const {
    name, uniqueIdentifier, totalRevenueRolling12MActual, totalRevenueRolling12MPercents,
    totalGrossMarginRolling12MActual, totalGrossMarginRolling12MPercents, wipFinishedGoodsStock,
    startDate, endDate,
  } = values || {};

  const defaultOption = { value: '', label: '' };

  return {
    [PROFIT_CENTRE_NAME]: name || '',
    [PROFIT_CENTRE_UNIQUE_IDENTIFIER]: uniqueIdentifier || '',
    [REVENUE_ROLLING_PERCENTS]: getFormValue({ value: totalRevenueRolling12MPercents }),
    [GROSS_MARGIN_ROLLING_PERCENTS]: getFormValue({ value: totalGrossMarginRolling12MPercents }),
    [REVENUE_ROLLING_AMOUNT]: getFormValue({ value: totalRevenueRolling12MActual?.amount }),
    [GROSS_MARGIN_ROLLING_AMOUNT]: getFormValue({ value: totalGrossMarginRolling12MActual?.amount }),
    [FINISHED_GOOD_STOCK_AMOUNT]: getFormValue({ value: wipFinishedGoodsStock?.amount }),
    [REVENUE_ROLLING_CURRENCY]: totalRevenueRolling12MActual?.currency ? {
      value: totalRevenueRolling12MActual.currency, label: totalRevenueRolling12MActual.currency,
    } : defaultOption,
    [GROSS_MARGIN_ROLLING_CURRENCY]: totalGrossMarginRolling12MActual?.currency ? {
      value: totalGrossMarginRolling12MActual.currency, label: totalGrossMarginRolling12MActual.currency,
    } : defaultOption,
    [START_DATE]: startDate || '',
    [END_DATE]: endDate || '',
    [FINISHED_GOOD_STOCK_AMOUNT_CURRENCY]: wipFinishedGoodsStock?.currency ? {
      value: wipFinishedGoodsStock.currency,
      label: wipFinishedGoodsStock.currency,
    } : defaultOption,
  };
}

export default getProfitCentreDefaultValues;
