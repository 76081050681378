import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { BUTTON_VARIANT } from 'constants/interfaces';

import type { IFooterProps } from '../interfaces/IFooterProps';

import styles from '../scss/MappingMultipleModal.module.scss';

function Footer({
  matched,
  total,
  progressStyle,
  onCloseMappingModal,
  onMappingModalSubmit,
  isLoading,
  disabled,
}: IFooterProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.progressWrapper}>
        <div
          className={styles.progressBar}
          style={progressStyle}
        />
        <div>
          {t('dataSetsManagement.mappingModal.fieldsAreMapped', {
            matched,
            total,
          })}
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          title={t('common.close')}
          variant={BUTTON_VARIANT.cancel}
          onClick={onCloseMappingModal}
        />
        <Button
          title={t('common.confirm')}
          variant={BUTTON_VARIANT.primary}
          onClick={onMappingModalSubmit}
          isLoading={isLoading}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default Footer;
