import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Separator from 'components/Separator/Separator';
import { PRODUCT_SUPPLIERS_SOURCE, TABLES_REQUIRED_VALUE } from 'constants/dataSets';
import { EMPTY_SYMBOL, MAPPING_FIELDS_PREFIX } from 'constants/general';
import { useAppDispatch } from 'store/hooks/useApp';
import { selfDataSets } from 'store/slices/dataSets/selectors';
import { setDataSetsState } from 'store/slices/dataSets/slice';

import i18n from '../../i18n';
import Loader from '../Loader/Loader';
import Backdrop from '../Modal/Backdrop';
import Footer from './components/Footer';
import MultipleHeaderItem from './components/MultipleHeaderItem';
import MultipleItem from './components/MultipleItem';

import type { TUseProfitCenterImportRecordsMutation } from 'pages/UserPages/ProfitCentres/interfaces/TUseProfitCenterImportRecordsMutation';
import type { ICurrentMapping } from 'store/slices/dataSets/interfaces/ICurrentMapping';
import type ISourceValue from 'store/slices/dataSets/interfaces/ISourceValue';
import type IPercentageCSSProperties from 'utils/interfaces/ICustomCSSProperties';
import type { IMapColumns } from './interfaces/IMapColumns';
import type IMappingMultipleModal from './interfaces/IMappingMultipleModal';
import type IOption from './interfaces/IOption';
import type { ISheetTarget } from './interfaces/ISheetTarget';
import type { TMappingSchema } from './interfaces/TMappingSchema';

import styles from './scss/MappingMultipleModal.module.scss';

function MappingMultipleModal<T extends TUseProfitCenterImportRecordsMutation>({
  isMappingModalVisible,
  onCloseMappingModal,
  setIsImportErrorsModalVisible,
  importRecords,
  isLoading,
}: IMappingMultipleModal<T>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [sheetTargets, setSheetTargets] = useState<ISheetTarget>();
  const [sheetSources, setSheetSources] = useState<ISheetTarget>();
  const [mappingColumns, setMappingColumns] = useState<IMapColumns>();
  const [matched, setMatched] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const dataSets = useSelector(selfDataSets);
  const {
    control,
    watch,
    setError,
    setValue,
  } = useForm<TMappingSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { target: {}, sources: {} },
  });
  const currentValues = watch();

  useEffect(() => {
    if (currentValues?.sources) {
      const sources = Object.keys(currentValues.sources)
        .filter((itemFilter) => itemFilter.includes('_'))
        .map((item) => ({
          label: item,
          value: currentValues.sources[item],
        }));
      const matchedCount = sources.filter((item) => item.value && item.value !== '').length;
      setMatched(matchedCount);
    }
  }, [currentValues]);

  useEffect(() => {
    if (mappingColumns && sheetTargets && sheetSources) {
      const keys = Object.keys(mappingColumns).map((item) => item);
      keys.forEach((item) => {
        if (currentValues.sources[item] !== undefined && currentValues.sources[item]) {
          const sourceItem = currentValues.sources[item];
          const sourceValue = typeof sourceItem === 'string'
            ? { value: sourceItem }
            : sourceItem as { value: string };
          sheetSources[sourceValue.value]?.forEach((source, index) => {
            if (currentValues.sources[`${item}_${index}`] === undefined
            && source === sheetTargets[item][index]) {
              setValue(`${MAPPING_FIELDS_PREFIX.source}${item}_${index}`, {
                value: source,
                label: source,
              });
            }
          });
        }
      });
    }
  }, [mappingColumns, setValue, setError, currentValues, sheetTargets, sheetSources]);

  const createMappingColumns = useCallback(() => {
    const getAllMaps: Record<string, any> = {};
    dataSets?.mappingColumns?.map?.forEach((item) => {
      if (item.target) {
        getAllMaps[item.target] = item;
      }
    });
    return getAllMaps;
  }, [dataSets?.mappingColumns?.map]);

  const createDataSources = useCallback(() => {
    const dataSources = Object.keys(dataSets?.mappingColumns?.sheetSources ?? {})
      .filter((item) => item !== TABLES_REQUIRED_VALUE);
    return dataSources.reduce<ISheetTarget>((acc, item) => ({
      ...acc,
      [item]: dataSets?.mappingColumns?.sheetSources[item] ?? [],
    }), {});
  }, [dataSets?.mappingColumns?.sheetSources]);

  useEffect(() => {
    if (dataSets?.mappingColumns) {
      setSheetTargets(dataSets?.mappingColumns?.sheetTargets);
      const getSources = createDataSources();
      setSheetSources(getSources);
      const getAllMaps = createMappingColumns();
      setMappingColumns(getAllMaps);
    }
  }, [createDataSources, createMappingColumns, dataSets]);

  useEffect(() => {
    if (sheetTargets) {
      Object.keys(sheetTargets).forEach((item) => {
        setValue(item, item);
        setError(item, { message: EMPTY_SYMBOL });
      });
    }
  }, [sheetTargets, setValue, setError, dataSets?.mappingColumns?.map]);

  const initialTotal = useCallback(() => {
    let totalColumns = 0;
    let matchedColumns = 0;
    if (mappingColumns) {
      Object.keys(mappingColumns).forEach((item) => {
        const column = mappingColumns[item];
        totalColumns += column.map.length;
        matchedColumns += column.map.filter((mapItem) => mapItem.isMatched).length;
      });
    }
    setMatched(matchedColumns);
    setTotal(totalColumns);
  }, [mappingColumns]);

  useEffect(() => {
    if (mappingColumns) {
      Object.keys(mappingColumns).forEach((item) => {
        const column = mappingColumns[item];
        column.map.forEach((mapItem, index) => {
          if (mapItem.target) {
            setValue(
              `${MAPPING_FIELDS_PREFIX.target}${mapItem.target.replace(/'/g, '')}_${index}`,
              mapItem.target.replace(/'/g, ''),
            );
            if (!mapItem.isMatched) {
              setError(`${MAPPING_FIELDS_PREFIX.target}${mapItem.target.replace(/'/g, '')}`, { message: EMPTY_SYMBOL });
            }
          }
        });
      });
      initialTotal();
    }
  }, [mappingColumns, setValue, setError, initialTotal]);

  const getOptions = (
    sheetSourcesData: ISheetTarget,
    item: IOption | string,
  ) => (item && typeof item !== 'string' ? sheetSourcesData[item.value]?.map((itemSource) => ({
    value: itemSource.replace(/'/g, ''),
    label: itemSource.replace(/'/g, ''),
  })) : []);

  const progressStyle: IPercentageCSSProperties = {
    '--percentage': `${(matched && total) || (matched === 0 && total) ? (matched / total) * 100 : 100}%`,
  };

  const getCurrentMapping = (
    mappingColumnsData: IMapColumns,
    sourceName: string,
    currentValuesData: TMappingSchema,
  ) => {
    const currentMapping: ICurrentMapping[] = [];

    mappingColumnsData[sourceName].map.forEach(({ target }, index) => {
      const sourceItem = currentValuesData.sources[`${sourceName}_${index}`] as ISourceValue | string;
      const source = typeof sourceItem === 'object' && sourceItem !== null ? sourceItem.value : sourceItem || '';

      currentMapping.push({ target, source });
    });

    return currentMapping;
  };

  const onMappingModalSubmit = () => {
    if (mappingColumns) {
      const sources = Object.keys(dataSets?.mappingColumns?.sheetSources || {}).map((item) => item);
      const requestData = {
        url: dataSets?.mappingColumns?.url || '',
        dryRun: false,
        Main: {
          source: sources[0],
          map: getCurrentMapping(mappingColumns, currentValues.Main as string, currentValues),
        },
        [PRODUCT_SUPPLIERS_SOURCE]: {
          source: sources[1],
          map: getCurrentMapping(mappingColumns, currentValues['Product Suppliers'] as string, currentValues),
        },
      };

      importRecords(requestData).unwrap().then((importRecordsResponse) => {
        dispatch(setDataSetsState({ mappingColumns: importRecordsResponse.data }));
        if (importRecordsResponse?.data?.sheetErrors) {
          dispatch(setDataSetsState({ mappingColumns: importRecordsResponse.data }));
          setIsImportErrorsModalVisible(true);
        }
        onCloseMappingModal();
      }).catch(() => { toast.error(i18n.t('notifications.somethingWentWrong')); });
    }
  };

  return isMappingModalVisible ? createPortal(
    <Backdrop>
      <Loader isPageLoading={isLoading} />
      <div className={styles.root}>
        <h2 className={styles.title}>{t('dataSetsManagement.mappingModal.title')}</h2>
        <Separator />
        <div className={styles.wrapperContainer}>
          {sheetTargets && sheetSources ? (Object.keys(sheetTargets)).map((item, index) => (
            <div
              key={`sheetTargets_${item}`}
              className={styles.wrapperForm}
            >
              <MultipleHeaderItem
                index={index}
                item={item}
                control={control}
                sheetSources={sheetSources}
                wrapperRef={wrapperRef}
              />
              <div className={styles.content}>
                <div className={styles.header}>
                  <div>{t('dataSetsManagement.mappingModal.targetColumn')}</div>
                  <div className={styles.paddingLeft}>{t('dataSetsManagement.mappingModal.sourceColumn')}</div>
                </div>
                <div className={styles.wrapper} ref={wrapperRef}>
                  {mappingColumns ? mappingColumns[item]?.map?.map(
                    (source: { target: string, isMatched?: boolean }, index55: number) => (
                      <MultipleItem
                        key={[source.target, index55].join('_')}
                        source={source}
                        index={index55}
                        item={item}
                        control={control}
                        watch={watch}
                        getOptions={getOptions}
                        sheetSources={sheetSources}
                        wrapperRef={wrapperRef}
                      />
                    ),
                  ) : null}
                </div>
              </div>
            </div>
          )) : null}
        </div>
        <Separator />
        <Footer
          matched={matched}
          total={total}
          progressStyle={progressStyle}
          onCloseMappingModal={onCloseMappingModal}
          onMappingModalSubmit={onMappingModalSubmit}
          isLoading={isLoading}
          disabled={matched !== total}
        />
      </div>
    </Backdrop>,
    document.body,
  ) : null;
}

export default MappingMultipleModal;
