import { useTranslation } from 'react-i18next';
import { ReactComponent as OwnOperationIcon } from 'assets/icons/factory.svg';
import { ReactComponent as ProfitCenterIcon } from 'assets/icons/profitCenter.svg';
import EmptyList from 'components/EmptyList/EmptyList';
import Loader from 'components/Loader/Loader';

import type IProfitCenterList from '../../interfaces/IProfitCenterList';

import styles from '../sass/ProfitCentreModal.module.scss';

export default function ProfitCenterList({
  list,
  isLoading,
  ownOperation,
  onSelectItem,
  selectedItem,
}: IProfitCenterList) {
  const isListEmpty = list.length === 0 && !isLoading;
  const { t } = useTranslation();

  return (
    <div className={`${styles.profitCenterList} ${isListEmpty ? styles.emptyList : ''}`}>
      <Loader isFullLoader={false} isPageLoading={isLoading} />
      {!isLoading && !isListEmpty ? list.map((profitCenter) => (
        <div
          key={`profitCenter_${profitCenter.id}`}
          className={`${styles.profitCenter} ${profitCenter.id === selectedItem?.id ? styles.isSelected : ''}`}
          onClick={() => onSelectItem(profitCenter)}
          aria-hidden="true"
        >
          {
          ownOperation
            ? <OwnOperationIcon className={styles.profitCenter_icon} />
            : <ProfitCenterIcon className={styles.profitCenter_icon} />
          }
          <span>
            {profitCenter?.name || ''}
            {' '}
            (
            {profitCenter?.uniqueIdentifier || ''}
            )
          </span>
        </div>
      )) : null}
      {isListEmpty && !isLoading ? (
        <EmptyList
          text={t('valueChainMap.noResults')}
        />
      ) : null}
    </div>
  );
}
