import { useTranslation } from 'react-i18next';
import {
  Panel,
  ReactFlow, useReactFlow,
} from '@xyflow/react';
import { ReactComponent as ProfitCenterIcon } from 'assets/icons/profitCenter.svg';
import { clsx } from 'clsx';

import DeactivationInfoModal from '../../../../../components/DeactivationModals/DeactivationInfoModal';
import DeactivationModal from '../../../../../components/DeactivationModals/DeactivationModal';
import Loader from '../../../../../components/Loader/Loader';
import { REACT_FLOW } from '../../../../../constants/general';
import { BUSINESS_STRUCTURE_MODALS } from '../../../../../constants/interfaces';
import ColorsTip from './components/ColorsTip';
import ConfirmStructureChangeModal from './components/ConfirmStructureChangeModal';
import CustomNode from './components/CustomNode';

import type { IBusinessStructureView } from './interfaces/IBusinessStructure';

import '@xyflow/react/dist/style.css';
import styles from './sass/BusinessStructureView.module.scss';

const edgeOptions = {
  animated: false,
  style: {
    stroke: '#5A5F60',
  },
};

function BusinessStructureView({
  nodes, edges, updateEdgeStyles, onNodesChange, onEdgesChange, onConnect, onMoveEnd, isLoading, disablingItem,
  onDisableConfirm, closeModal, currentOpenedModal, onDragStart, onDragStop, onNodeDrag, onConfirmStructureChange,
  targetNode, draggingNode, onCancelStructureChange, isFullScreenActive, openProfitCenterModal, isAccessDisabled,
}: IBusinessStructureView) {
  const { t } = useTranslation();
  const { getZoom } = useReactFlow();

  const nodeTypes = {
    custom: CustomNode,
  };

  return (
    <>
      {!isAccessDisabled && (
        <Panel className={styles.rightCenterPanel} position="bottom-right">
          <button
            onClick={openProfitCenterModal}
            type="button"
            className={styles.profitCenterWrapper}
          >
            <ProfitCenterIcon width={24} height={24} />
            <p>{t('valueChainMap.profitCenter')}</p>
          </button>
        </Panel>
      )}
      <Panel
        position="bottom-left"
        className={clsx(styles.colorsTip, {
          [styles.fullScreen]: isFullScreenActive,
        })}
      >
        <ColorsTip />
      </Panel>
      {isLoading ? <Loader isPageLoading fullLoaderClassName={styles.fullLoader} /> : null }
      <ReactFlow
        nodes={nodes}
        className="reactFlowBusiness"
        edges={updateEdgeStyles(edges)}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={edgeOptions}
        onNodesChange={!isAccessDisabled ? onNodesChange : () => {}}
        onEdgesChange={!isAccessDisabled ? onEdgesChange : () => {}}
        onMoveEnd={() => onMoveEnd(getZoom())}
        onConnect={onConnect}
        onInit={(rfInstance) => rfInstance.fitView()}
        minZoom={REACT_FLOW.MIN_ZOOM}
        onNodeDragStart={onDragStart}
        onNodeDragStop={onDragStop}
        onNodeDrag={onNodeDrag}
        connectOnClick={!isAccessDisabled}
        nodesConnectable={!isAccessDisabled}
        fitView
      />
      <DeactivationModal
        open={currentOpenedModal === BUSINESS_STRUCTURE_MODALS.CONFIRM_DISABLE}
        title={t(disablingItem?.isDisabled
          ? 'valueChainMap.enablingReasonTitle' : 'valueChainMap.disablingReasonTitle')}
        text={t(disablingItem?.isDisabled
          ? 'valueChainMap.enablingReasonDescription' : 'valueChainMap.disablingReasonDescription')}
        onReject={closeModal}
        onSubmit={onDisableConfirm}
        isLoading={isLoading}
      />
      <DeactivationInfoModal
        open={currentOpenedModal === BUSINESS_STRUCTURE_MODALS.DISABLED_INFO}
        title={t('valueChainMap.disabledBranchDetails')}
        deactivationInfo={disablingItem?.latestStatus}
        onClose={closeModal}
        isLoading={isLoading}
      />
      <ConfirmStructureChangeModal
        isOpen={currentOpenedModal === BUSINESS_STRUCTURE_MODALS.CONFIRM_STRUCTURE_CHANGE}
        isLoading={isLoading}
        onReject={onCancelStructureChange}
        onHandleConfirm={onConfirmStructureChange}
        draggingNode={draggingNode}
        targetNode={targetNode}
      />
    </>
  );
}

export default BusinessStructureView;
