import {
  AIR, ARCTIC_TRUCK, COMMENT_LOGICAL, COMMENT_SERVICE, CONNECTION_TYPE,
  DISTANCE, MONETARY_VALUES, MONETARY_VOLUMES, OWNERSHIP_PERCENTAGE, QUANTITY, RAIL,
  RIGID_TRUCK, SEA, TRUCK, TYPE_OF_SERVICE_PROVIDER, UNIT_CURRENCY, UNIT_CURRENCY_VALUES,
  UNIT_MONETARY_VOLUMES, VAN,
} from './formFields';

export const DATA_SETS_PROFIT_CENTRES_HEAD_CELLS = [
  {
    id: 'name',
    label: 'profitCentres.fieldsHeaders.profitCentreName',
    withSort: true,
    isFirstCell: true,
  },
  {
    id: 'uniqueIdentifier',
    label: 'profitCentres.fieldsHeaders.uniqueIdentifier',
    withSort: true,
  },
  {
    id: 'totalRevenueRolling12MPercents',
    label: 'profitCentres.fieldsHeaders.totalRevenue',
    withSort: true,
  },
  {
    id: 'totalRevenueRolling12MActual',
    label: 'profitCentres.fieldsHeaders.actualRevenue',
    withSort: true,
  },
  {
    id: 'totalGrossMarginRolling12MPercents',
    label: 'profitCentres.fieldsHeaders.totalGross',
    withSort: true,
  },
  {
    id: 'totalGrossMarginRolling12MActual',
    label: 'profitCentres.fieldsHeaders.actualGross',
    withSort: true,
  },
  {
    id: 'wipFinishedGoodsStock',
    label: 'profitCentres.fieldsHeaders.goodsStock',
    withSort: true,
  },
  {
    id: 'actions',
    label: 'profitCentres.fieldsHeaders.actions',
    withSort: false,
    isLastCell: true,
  },
];

export const DATA_SETS_OWN_OPERATIONS_HEAD_CELLS = [
  {
    id: 'name',
    label: 'ownOperations.fields.name',
    withSort: true,
    isFirstCell: true,
  },
  {
    id: 'uniqueIdentifier',
    label: 'ownOperations.fields.uniqueIdentifier',
    withSort: true,
  },
  {
    id: 'legalName',
    label: 'ownOperations.fields.legalEntity',
    withSort: true,
  },
  {
    id: 'profitCenterId',
    label: 'ownOperations.fields.profitCentreID',
    withSort: true,
  },
  {
    id: 'valueChainSection',
    label: 'ownOperations.fields.valueChain',
    withSort: true,
  },
  {
    id: 'ownership',
    label: 'ownOperations.fields.ownership',
    withSort: true,
  },
  {
    id: 'equityShareholding',
    label: 'ownOperations.fields.equityShareholding',
    withSort: true,
  },
  {
    id: 'financialControl',
    label: 'ownOperations.fields.financialControl',
    withSort: true,
  },
  {
    id: 'businessUnitType',
    label: 'ownOperations.fields.physicalOrLogical',
    withSort: true,
  },
  {
    id: 'assetCategory',
    label: 'ownOperations.fields.assetCategory',
    withSort: true,
  },
  {
    id: 'ownedLeased',
    label: 'ownOperations.fields.owned',
    withSort: true,
  },
  {
    id: 'numberOfEmployees',
    label: 'ownOperations.fields.employees',
    withSort: true,
  },
  {
    id: 'dateOpened',
    label: 'ownOperations.fields.dateOpened',
    withSort: true,
  },
  {
    id: 'dateClosed',
    label: 'ownOperations.fields.dateClosed',
    withSort: true,
  },
  {
    id: 'naceSection',
    label: 'ownOperations.fields.industry',
    withSort: true,
  },
  {
    id: 'naceCode',
    label: 'ownOperations.fields.sector',
    withSort: true,
  },
  {
    id: 'euTaxonomyCode',
    label: 'ownOperations.fields.taxonomy',
    withSort: true,
  },
  {
    id: 'startDate',
    label: 'suppliers.fields.startDate',
    withSort: true,
  },
  {
    id: 'endDate',
    label: 'suppliers.fields.endDate',
    withSort: true,
  },
  {
    id: 'netBookValue',
    label: 'ownOperations.fields.netBook',
    withSort: true,
  },
  {
    id: 'addressCountry',
    label: 'ownOperations.fields.country',
    withSort: true,
  }, {
    id: 'addressState',
    label: 'ownOperations.fields.state',
    withSort: true,
  },
  {
    id: 'addressPostCode',
    label: 'ownOperations.fields.post',
    withSort: true,
  },
  {
    id: 'addressCity',
    label: 'ownOperations.fields.town',
    withSort: true,
  },
  {
    id: 'addressStreet',
    label: 'ownOperations.fields.street',
    withSort: true,
  },
  {
    id: 'addressBuilding',
    label: 'ownOperations.fields.building',
    withSort: true,
  },
  {
    id: 'addressCoordinates',
    label: 'ownOperations.fields.coordinates',
    withSort: true,
  },
  {
    id: 'actions',
    label: 'ownOperations.fields.actions',
    withSort: false,
  },
];

export const DATA_SETS_SUPPLIERS_HEAD_CELLS = [
  {
    id: 'name',
    label: 'suppliers.fields.supplierName',
    withSort: true,
    isFirstCell: true,
  },
  {
    id: 'code',
    label: 'suppliers.fields.supplierCode',
    withSort: true,
  },
  {
    id: 'uniqueIdentifier',
    label: 'suppliers.fields.uniqueIdentifier',
    withSort: true,
  },
  {
    id: 'profitCenterId',
    label: 'customers.fields.profitCentreId',
    withSort: true,
  },
  {
    id: 'valueChainSection',
    label: 'suppliers.fields.valueChain',
    withSort: true,
  },
  {
    id: 'upstreamTitle',
    label: 'suppliers.fields.upstreamTier',
    withSort: true,
  },
  {
    id: 'ownership',
    label: 'suppliers.fields.ownership',
    withSort: true,
  },
  {
    id: 'equityShareholding',
    label: 'suppliers.fields.equityShareholding',
    withSort: true,
  },
  {
    id: 'financialControl',
    label: 'suppliers.fields.financialControl',
    withSort: true,
  },
  {
    id: 'businessUnitType',
    label: 'suppliers.fields.physicalOrLogical',
    withSort: true,
  },
  {
    id: 'dateOpened',
    label: 'suppliers.fields.dateOpened',
    withSort: true,
  },
  {
    id: 'dateClosed',
    label: 'suppliers.fields.dateClosed',
    withSort: true,
  },
  {
    id: 'naceSection',
    label: 'suppliers.fields.industry',
    withSort: true,
  },
  {
    id: 'naceCode',
    label: 'suppliers.fields.sector',
    withSort: true,
  },
  {
    id: 'euTaxonomyCode',
    label: 'suppliers.fields.taxonomy',
    withSort: true,
  },
  {
    id: 'startDate',
    label: 'suppliers.fields.startDate',
    withSort: true,
  },
  {
    id: 'endDate',
    label: 'suppliers.fields.endDate',
    withSort: true,
  },
  {
    id: 'spend',
    label: 'suppliers.fields.spend',
    withSort: true,
  },
  {
    id: 'keySupplierTier1Total',
    label: 'suppliers.fields.keySuppliers',
    withSort: true,
  },
  {
    id: 'keySupplierTier1OfTotal',
    label: 'suppliers.fields.keySuppliersInPercentage',
    withSort: true,
  },
  {
    id: 'addressCountry',
    label: 'suppliers.fields.country',
    withSort: true,
  }, {
    id: 'addressState',
    label: 'suppliers.fields.state',
    withSort: true,
  },
  {
    id: 'addressPostCode',
    label: 'suppliers.fields.post',
    withSort: true,
  },
  {
    id: 'addressCity',
    label: 'suppliers.fields.town',
    withSort: true,
  },
  {
    id: 'addressStreet',
    label: 'suppliers.fields.street',
    withSort: true,
  },
  {
    id: 'addressBuilding',
    label: 'suppliers.fields.building',
    withSort: true,
  },
  {
    id: 'addressCoordinates',
    label: 'suppliers.fields.coordinates',
    withSort: true,
  },
  {
    id: 'actions',
    label: 'suppliers.fields.actions',
    withSort: false,
  },
];

export const DATA_SETS_CUSTOMERS_HEAD_CELLS = [
  {
    id: 'name',
    label: 'customers.fields.customerName',
    withSort: true,
    isFirstCell: true,
  },
  {
    id: 'code',
    label: 'customers.fields.customerCode',
    withSort: true,
  },
  {
    id: 'uniqueIdentifier',
    label: 'customers.fields.uniqueIdentifier',
    withSort: true,
  },
  {
    id: 'profitCenterId',
    label: 'customers.fields.profitCentreId',
    withSort: true,
  },
  {
    id: 'valueChainSection',
    label: 'customers.fields.valueChain',
    withSort: true,
  },
  {
    id: 'ownership',
    label: 'customers.fields.ownership',
    withSort: true,
  },
  {
    id: 'equityShareholding',
    label: 'customers.fields.equityShareholding',
    withSort: true,
  },
  {
    id: 'financialControl',
    label: 'customers.fields.financialControl',
    withSort: true,
  },
  {
    id: 'physicalOrLogical',
    label: 'customers.fields.physicalOrLogical',
    withSort: true,
  },
  {
    id: 'dateOpened',
    label: 'customers.fields.dateOpened',
    withSort: true,
  },
  {
    id: 'dateClosed',
    label: 'customers.fields.dateClosed',
    withSort: true,
  },
  {
    id: 'priorityRating',
    label: 'customers.fields.priorityRating',
    withSort: true,
  },
  {
    id: 'naceSection',
    label: 'customers.fields.industry',
    withSort: true,
  },
  {
    id: 'naceCode',
    label: 'customers.fields.sector',
    withSort: true,
  },
  {
    id: 'startDate',
    label: 'customers.fields.startDate',
    withSort: true,
  },
  {
    id: 'endDate',
    label: 'customers.fields.endDate',
    withSort: true,
  },
  {
    id: 'expectedGrowthNext12M',
    label: 'customers.fields.expectedGrowth',
    withSort: true,
  },
  {
    id: 'totalRevenueRolling12MPercents',
    label: 'customers.fields.totalRevenue',
    withSort: true,
  },
  {
    id: 'totalRevenueRolling12MActual',
    label: 'customers.fields.actualRevenue',
    withSort: true,
  },
  {
    id: 'addressCountry',
    label: 'customers.fields.country',
    withSort: true,
  },
  {
    id: 'addressState',
    label: 'customers.fields.state',
    withSort: true,
  },
  {
    id: 'addressPostCode',
    label: 'customers.fields.post',
    withSort: true,
  },
  {
    id: 'addressCity',
    label: 'customers.fields.town',
    withSort: true,
  },
  {
    id: 'addressStreet',
    label: 'customers.fields.street',
    withSort: true,
  },
  {
    id: 'addressBuilding',
    label: 'customers.fields.building',
    withSort: true,
  },
  {
    id: 'addressCoordinates',
    label: 'customers.fields.coordinates',
    withSort: true,
  },
  {
    id: 'actions',
    label: 'customers.fields.actions',
    withSort: false,
    isLastCell: true,
  },
];

export const DATA_SETS_PRODUCTS_HEAD_CELLS = [
  {
    id: 'name',
    label: 'products.fields.productName',
    withSort: true,
    isFirstCell: true,
  },
  {
    id: 'code',
    label: 'products.fields.productCode',
    withSort: true,
  },
  {
    id: 'uniqueIdentifier',
    label: 'products.fields.uniqueIdentifier',
    withSort: true,
  },
  {
    id: 'profitCenterId',
    label: 'products.fields.profitCentreId',
    withSort: true,
  },
  {
    id: 'suppliers',
    label: 'products.fields.supplier',
    withSort: true,
  },
  {
    id: 'upstreamTitle',
    label: 'products.fields.upstreamTier',
    withSort: true,
  },
  {
    id: 'dateOpened',
    label: 'products.fields.dateFrom',
    withSort: true,
  },
  {
    id: 'dateClosed',
    label: 'products.fields.dateTo',
    withSort: true,
  },
  {
    id: 'volumeQty',
    label: 'products.fields.volume',
    withSort: true,
  },
  {
    id: 'volumeQtyUnit',
    label: 'products.fields.volumeUnit',
    withSort: true,
  },
  {
    id: 'equityControl',
    label: 'products.fields.equityControl',
    withSort: true,
  },
  {
    id: 'expectedGrowthDecline',
    label: 'products.fields.expectedGrowth',
    withSort: true,
  },
  {
    id: 'relianceOnFreeNaturalResources',
    label: 'products.fields.relianceNatural',
    withSort: true,
  },
  {
    id: 'relianceOnFreeSocialResources',
    label: 'products.fields.relianceSocial',
    withSort: true,
  },
  {
    id: 'criticalityRating',
    label: 'products.fields.rating',
    withSort: true,
  },
  {
    id: 'circularityFromDownstreamWaste',
    label: 'products.fields.circularityPercentage',
    withSort: true,
  },
  {
    id: 'startDate',
    label: 'products.fields.startDate',
    withSort: true,
  },
  {
    id: 'endDate',
    label: 'products.fields.endDate',
    withSort: true,
  },
  {
    id: 'keySupplierTier1Total',
    label: 'products.fields.suppliersTier1',
    withSort: true,
  },
  {
    id: 'keySupplierTier1OfTotal',
    label: 'products.fields.suppliersTier1InPercentage',
    withSort: true,
  },
  {
    id: 'actions',
    label: 'products.fields.actions',
    withSort: false,
    isLastCell: true,
  },
];

export const DATA_SET_PAGINATION_COUNT = 50;
export const SORT_DIRECTION_INDICATOR = '-';

export const DATA_SETS_ENUM_TYPES = {
  currency: 'currency',
  ownership: 'ownership',
  valueChainSection: 'valueChainSection',
  businessUnitType: 'businessUnitType',
  assetCategory: 'assetCategory',
  ownedLeased: 'ownedLeased',
  upstreamTitle: 'upstreamTitle',
  volumeQtyUnit: 'volumeQtyUnit',
  relianceOnFreeNaturalResources: 'relianceOnFreeNaturalResources',
  relianceOnFreeSocialResources: 'relianceOnFreeSocialResources',
  criticalityRating: 'criticalityRating',
  priorityRating: 'priorityRating',
} as const;

export const UID_NAME_TUPLES = {
  profitCenter: 'profit-center',
  ownOperation: 'own-operation',
  supplier: 'supplier',
  customer: 'customer',
  product: 'product',
} as const;

export const DATA_SET_MAX_MENU_HEIGHT = 164;

export const BUSINESS_UNIT_TYPE_REQUIRED_VALUE = 'Physical';
export const BUSINESS_UNIT_TYPE_LOGICAL = 'Logical';
export const BUSINESS_UNIT_TYPE_LOGICAL_VALUE = {
  label: BUSINESS_UNIT_TYPE_LOGICAL,
  value: BUSINESS_UNIT_TYPE_LOGICAL,
};
export const UPSTREAM_TITLE_REQUIRED_VALUE = 'Tier 1';
export const TABLES_REQUIRED_VALUE = 'Tables';

export const VALUE_CHAIN_SECTION_DEFAULT_VALUES = {
  supplier: 'Upstream',
  customers: 'Downstream',
  ownOperations: 'Own Operations',
};

export const SEARCH_PARAMS = {
  search: 'search',
  offset: 'offset',
  isArchived: 'isArchived',
};

export const DATA_SETS_MAP_DEFAULT_VALUES = {
  Main: {
    map: [],
  },
};

export const DATA_SETS_MAP_PRODUCT_SUPPLIERS = {
  dryRun: false,
  Main: {
    source: '',
    map: [],
  },
  'Product Suppliers': {
    source: '',
    map: [],
  },
};

export const PRODUCT_SUPPLIERS_SOURCE = 'Product Suppliers';

export const CONNECTION_TYPE_OPTIONS = [
  {
    label: 'Associate/Partner',
    value: 'associatePartner',
  },
  {
    label: 'Joint venture',
    value: 'jointVenture',
  },
  {
    label: 'Other related entity type',
    value: 'other',
  },
];

export const TYPE_OF_SERVICE_PROVIDER_OPTIONS = [
  {
    label: 'Professional',
    value: 'professional',
  },
  {
    label: 'Technical',
    value: 'technical',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
  {
    label: 'Adviser',
    value: 'adviser',
  },
  {
    label: 'Consultant',
    value: 'consultant',
  },
  {
    label: 'Legal',
    value: 'legal',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const TYPE_CONNECTION_FORM = {
  logical: false,
  supplier: false,
  service: false,
} as const;

export const TYPE_CONNECTION_FORM_OPTIONS = {
  logical: 'logical',
  supplier: 'supplier',
  service: 'service',
} as const;

export const CONNECTION_VC_LOGIC_FORM_FIELDS = [
  CONNECTION_TYPE, OWNERSHIP_PERCENTAGE, COMMENT_LOGICAL,
];

export const CONNECTION_VC_SUPPLIER_FORM_FIELDS = [
  AIR, ARCTIC_TRUCK, DISTANCE, MONETARY_VOLUMES, QUANTITY, RAIL,
  RIGID_TRUCK, SEA, TRUCK, UNIT_CURRENCY, UNIT_MONETARY_VOLUMES, VAN,
];

export const CONNECTION_VC_SERVICE_FORM_FIELDS = [
  COMMENT_SERVICE, MONETARY_VALUES, TYPE_OF_SERVICE_PROVIDER, UNIT_CURRENCY_VALUES,
];

export const ALL_CONNECTION_VC_FORM_FIELDS = [
  ...CONNECTION_VC_LOGIC_FORM_FIELDS,
  ...CONNECTION_VC_SUPPLIER_FORM_FIELDS,
  ...CONNECTION_VC_SERVICE_FORM_FIELDS,
];

export const JOINT_VENTURE = 'Joint venture';
export const SUPPLIER_OF_GOODS = 'supplierOfGoods';

export const CONNECTION_ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
} as const;

export const NAME_FILTERS = {
  OWN_OPERATION: 'ownOperations',
  CONNECTION: 'connections',
  PROFIT_CENTER: 'profitCenters',
  PRODUCT: 'products',
  CUSTOMER: 'customers',
  SUPPLIER: 'suppliers',
  GEO_PATH: 'geoPaths',
  ORG_PATH: 'orgPaths',
};

export const NAME_INPUTS_DEFAULT = {
  SEARCH: 'search',
};

export const FILTERS_SCHEMA_TYPES = [
  {
    value: 'connections',
    type: 'connections',
  },
  {
    value: 'profitCenters',
    type: 'pcUniqueIdentifiers',
  },
  {
    value: 'products',
    type: 'productUniqueIdentifiers',
  },
  {
    value: 'customers',
    type: 'customerUniqueIdentifiers',
  },
  {
    value: 'suppliers',
    type: 'supplierUniqueIdentifiers',
  },
  {
    value: 'ownOperations',
    type: 'orgPaths',
  },
  {
    value: 'ownOperations',
    type: 'geoPaths',
  },
];

export const ID_VALUE = 'id';
