import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from 'components/Button/Button';
import EmptyList from 'components/EmptyList/EmptyList';
import Loader from 'components/Loader/Loader';
import Panel from 'components/Panel/Panel';
import RenameModal from 'components/RenameModal/RenameModal';
import Table from 'components/Table/Table';
import TableBody from 'components/Table/TableBody';

import { USER_PILLAR_MODULES, USER_PILLARS } from '../../../../../constants/interfaces';
import usePillarModulePermission from '../../../../../hooks/usePillarModulePermission';
import ValueChainMapTableHead from '../ValueChainMapHead/ValueChainMapTableHead';
import ValueChainMapTableRow from '../ValueChainMapRow/ValueChainMapTableRow';

import type IValueChainMapTable from '../../interfaces/IValueChainTable';

import styles from './scss/ValueChainMapTable.module.scss';

function ValueChainMapTable({
  control,
  onSortChange,
  companies,
  onReadMoreClients,
  hasNext,
  isWithSearch,
  isPageLoading,
  onChangeStatus,
  onRenameValueChain,
  onDeleteValueChain,
  onEditValueChain,
  onArchiveValueChain,
  onDeArchiveValueChain,
  onDublicateValueChain,
  onCreateNewValueChain,
  onReviewValueChain,
}: IValueChainMapTable) {
  const { t } = useTranslation();
  const [isDublicateModalOpen, setIsDublicateModalOpen] = useState<boolean>(false);

  const tableRef = useRef<HTMLDivElement>(null);

  const { isActionsVisible, isSignOffAccess } = usePillarModulePermission({
    pillarModuleKey: USER_PILLAR_MODULES[USER_PILLARS.valueChainAssessment].valueChainMapManagement,
  });

  const onHandleClick = (name: string) => {
    onCreateNewValueChain(name);
    setIsDublicateModalOpen(false);
  };

  const emptyListText = isWithSearch ? (
    <Trans
      t={t}
      i18nKey="common.noResultsWithFilters"
      components={{
        br: <br />,
      }}
    />
  ) : t('valueChainMap.thereAreNoValueChanMaps');

  return (
    <Panel className={styles.root}>
      <Loader isPageLoading={isPageLoading} />

      {companies.length ? (
        <InfiniteScroll
          className={styles.infiniteScroll}
          next={onReadMoreClients}
          dataLength={companies.length}
          loader={false}
          hasMore={hasNext}
        >
          <div ref={tableRef}>
            <Table className={styles.table}>
              <ValueChainMapTableHead
                onSortChange={onSortChange}
                control={control}
                onChangeStatus={onChangeStatus}
                onRenameValueChain={onRenameValueChain}
                onDeleteValueChain={onDeleteValueChain}
                isWithSearch={isWithSearch}
                isActionsVisible={isActionsVisible || isSignOffAccess}
                onArchiveValueChain={onArchiveValueChain}
                onDeArchiveValueChain={onDeArchiveValueChain}
                onDublicateValueChain={onDublicateValueChain}
                onCreateNewValueChain={onCreateNewValueChain}
              />
              <TableBody>
                {companies.map((valueChainItem) => (
                  <ValueChainMapTableRow
                    tableRef={tableRef}
                    valueChainItem={valueChainItem}
                    key={`value_chain_${valueChainItem.id}`}
                    onChangeStatus={onChangeStatus}
                    onRenameValueChain={onRenameValueChain}
                    onDeleteValueChain={onDeleteValueChain}
                    onEditValueChain={onEditValueChain}
                    onArchiveValueChain={onArchiveValueChain}
                    onDeArchiveValueChain={onDeArchiveValueChain}
                    onDublicateValueChain={onDublicateValueChain}
                    onReviewValueChain={onReviewValueChain}
                    singleElement={companies.length === 1}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </InfiniteScroll>
      ) : !isPageLoading && (
        <EmptyList text={emptyListText}>
          {!isWithSearch && isActionsVisible ? (
            <Button
              title={t('valueChainMap.createNewValueChainMap')}
              onClick={() => setIsDublicateModalOpen(true)}
            />
          ) : null}
        </EmptyList>
      )}
      <RenameModal
        onSubmit={onHandleClick}
        onReject={() => setIsDublicateModalOpen(false)}
        open={isDublicateModalOpen}
        name=""
      />
    </Panel>
  );
}

export default ValueChainMapTable;
