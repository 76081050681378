import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import clsx from 'clsx';
import Button from 'components/Button/Button';
import { NAME_FILTERS } from 'constants/dataSets';
import { BUTTON_VARIANT } from 'constants/interfaces';
import {
  checkTypeFilter, getCountersTabsFilters, getSelectedFilters, onGenerateConnections,
  resetAllConnectionsToFalse,
  resetAllItemsToFalse,
  resetAllNestedItemsToFalse,
} from 'utils/filtersVCHelpers';
import formatCamelCase from 'utils/formatText';

import FilterListDefault from './components/FilterListDefault';
import FilterListTabs from './components/FilterListTabs';

import type {
  IBoardFilter, IBoardFilterItemObj, IFilterItemChildren, IFilterItemDeepChildren, IFilterListTabsItem,
} from 'store/slices/valueChainMap/interfaces/IBoardFilter';
import type { IBoardFilterItem } from 'store/slices/valueChainMap/interfaces/IBoardFilterItem';
import type IFiltersPanel from './interfaces/IFiltersPanel';

import styles from './sass/FiltersPanel.module.scss';

function FiltersPanel({
  open, onClose, filters, filtersData, setFiltersData,
  onApplyFilters,
}: IFiltersPanel) {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const [shouldApplyFilters, setShouldApplyFilters] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (filtersData && selectedFilter) {
      const filterData = filtersData[selectedFilter];
      if (Array.isArray(filterData)) {
        const newFilters = filterData.map((item) => {
          if (item.uniqueIdentifier === name) {
            return {
              ...item,
              checked,
            };
          }
          return item;
        });
        setFiltersData({
          ...filtersData,
          [selectedFilter]: newFilters,
        });
        setHasChanges(true);
      }
    }
  };

  const updateAllChildrenStatus = (
    child: IFilterItemChildren | IFilterItemDeepChildren,
    checked: boolean,
  ): IFilterItemChildren | IFilterItemDeepChildren => ({
    ...child,
    checked,
    children: child.children?.map((c) => updateAllChildrenStatus(c, checked)) || [],
  });

  const areAllChildrenChecked = (
    children: (IFilterItemChildren | IFilterItemDeepChildren)[],
  ): boolean => children.every((child) => child.checked);

  const areSomeChildrenChecked = (
    children: (IFilterItemChildren | IFilterItemDeepChildren)[],
  ): boolean => children.some((child) => child.checked);

  const updateItemAndChildren = (
    item: IFilterItemChildren | IFilterItemDeepChildren,
    targetName: string,
    newCheckedStatus: boolean,
  ): IFilterItemChildren | IFilterItemDeepChildren => {
    if (item.name === targetName) {
      return {
        ...item,
        checked: newCheckedStatus,
        children: item.children?.map((child) => updateAllChildrenStatus(child, newCheckedStatus)) || [],
      };
    }
    if (!item.children?.length) {
      return item;
    }
    const updatedChildren = item.children.map((child) => updateItemAndChildren(child, targetName, newCheckedStatus));
    return {
      ...item,
      checked: areSomeChildrenChecked(updatedChildren) || areAllChildrenChecked(updatedChildren),
      children: updatedChildren,
    };
  };

  const onChangeParent = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const { name, checked } = e.target;
    if (!filtersData || !selectedFilter) {
      return;
    }
    const currentFilters = filtersData[selectedFilter] as IBoardFilterItemObj;
    const items = currentFilters[type];
    const newItems = items.map((item) => updateItemAndChildren(item, name, checked));
    setFiltersData({
      ...filtersData,
      [selectedFilter]: {
        ...currentFilters,
        [type]: newItems,
      },
    });
    setHasChanges(true);
  };

  const onResetAllItemsToFalse = (
    items: IFilterListTabsItem[],
  ): IFilterListTabsItem[] => items.map((item) => ({
    ...item,
    checked: false,
    children: item.children ? onResetAllItemsToFalse(item.children) : [],
  }));

  const resetConnectionFilters = (
    filterData: NonNullable<typeof filters>,
  ): IBoardFilterItem[] | null => {
    const connects = onGenerateConnections(filterData);
    const connections = connects[NAME_FILTERS.CONNECTION];
    if (!Array.isArray(connections)) return null;

    return connections.map((item: IBoardFilterItem) => ({
      ...item,
      checked: false,
    }));
  };

  const resetArrayFilters = (
    items: IBoardFilterItem[],
  ): IBoardFilterItem[] => items.map((item) => ({ ...item, checked: false }));

  const resetObjectFilters = (filterObj: IBoardFilterItemObj): IBoardFilterItemObj => ({
    ...filterObj,
    orgPaths: filterObj.orgPaths ? onResetAllItemsToFalse(filterObj.orgPaths) : [],
    geoPaths: filterObj.geoPaths ? onResetAllItemsToFalse(filterObj.geoPaths) : [],
  });

  const resetNonConnectionFilters = (
    currentFilter: IBoardFilter[string],
  ): IBoardFilter[string] | null => {
    if (!currentFilter) return null;

    if (Array.isArray(currentFilter)) {
      return resetArrayFilters(currentFilter);
    }

    if (typeof currentFilter !== 'object') return null;

    return resetObjectFilters(currentFilter as IBoardFilterItemObj);
  };

  const onResetAllCurrent = () => {
    if (!filtersData || !selectedFilter || !filters) return;

    const updatedFilters = selectedFilter === NAME_FILTERS.CONNECTION
      ? resetConnectionFilters(filters)
      : resetNonConnectionFilters(filtersData[selectedFilter]);

    if (!updatedFilters) return;

    setFiltersData({
      ...filtersData,
      [selectedFilter]: updatedFilters,
    });
    setShouldApplyFilters(true);
    setHasChanges(false);
  };

  const onResetAll = () => {
    if (filtersData && filters) {
      const newFiltersData = { ...filtersData };
      Object.keys(newFiltersData).forEach((filterKey) => {
        const currentFilter = newFiltersData[filterKey];
        if (filterKey === NAME_FILTERS.CONNECTION) {
          if (Array.isArray(currentFilter)) {
            newFiltersData[filterKey] = resetAllConnectionsToFalse(currentFilter);
          }
        } else if (Array.isArray(currentFilter)) {
          newFiltersData[filterKey] = resetAllItemsToFalse(
            currentFilter as IFilterListTabsItem[],
          );
        } else if (typeof currentFilter === 'object' && currentFilter !== null) {
          newFiltersData[filterKey] = resetAllNestedItemsToFalse(
            currentFilter as IBoardFilterItemObj,
          );
        }
      });
      setFiltersData(newFiltersData);
      setShouldApplyFilters(true);
      setHasChanges(false);
    }
  };

  const onResetAllCurrentParent = () => {
    if (filtersData && selectedFilter && filters) {
      const currentFilters = { ...filtersData };
      currentFilters[selectedFilter] = filters[selectedFilter];
      setFiltersData(currentFilters);
      setHasChanges(false);
      setShouldApplyFilters(true);
    }
  };

  const onBackStepFilter = () => {
    if (hasChanges && filters) {
      const filtersDataObj = onGenerateConnections(filters);
      setFiltersData(filtersDataObj);
    }
    setSelectedFilter(undefined);
    setHasChanges(false);
  };

  const onSelectedFilterCategory = (filter: string) => {
    setSelectedFilter(filter);
    setHasChanges(false);
  };

  const onApplyFilter = () => {
    if (selectedFilter) {
      setSelectedFilter(undefined);
    }
    onApplyFilters();
  };

  const searchFilters = (filtersArray: IBoardFilterItem[], text: string) => {
    if (text) {
      return filtersArray.filter((item) => item?.name.toLowerCase().includes(text.toLowerCase()));
    }
    return filtersArray;
  };

  useEffect(() => {
    if (filters) {
      const filtersDataObj = onGenerateConnections(filters);
      setFiltersData(filtersDataObj);
    }
  }, [filters, setFiltersData]);

  useEffect(() => {
    if (shouldApplyFilters) {
      onApplyFilters();
      setShouldApplyFilters(false);
    }
  }, [filtersData, shouldApplyFilters, onApplyFilters]);

  return (
    <div className={clsx(styles.container, { [styles.open]: open })}>
      {!selectedFilter && (
        <div className={styles.content}>
          <div className={styles.headerFilters}>
            <span className={styles.headerText}>{t('common.filter')}</span>
            <Button
              title={t('common.resetAll')}
              variant={BUTTON_VARIANT.ghost}
              onClick={onResetAll}
            />
          </div>
          <div className={styles.filters}>
            {filtersData && !Array.isArray(filtersData) ? Object.keys(filtersData).map((filter) => (
              <div key={filter} className={styles.buttonWrapper}>
                <Button
                  title={formatCamelCase(filter)}
                  variant={BUTTON_VARIANT.ghost}
                  onClick={() => onSelectedFilterCategory(filter)}
                  endIcon={<ChevronIcon />}
                  className={styles.filterButton}
                />
                {(getSelectedFilters(filtersData[filter] as IBoardFilterItem[]) > 0
                || getCountersTabsFilters(filtersData[filter] as IBoardFilterItemObj).sum) ? (
                  <div className={styles.circleCounter}>
                    {filter !== NAME_FILTERS.OWN_OPERATION
                      ? getSelectedFilters(filtersData[filter] as IBoardFilterItem[])
                      : getCountersTabsFilters(filtersData[filter] as IBoardFilterItemObj).sum}
                  </div>
                  ) : null}
              </div>
            )) : null}
          </div>
        </div>
      )}

      {selectedFilter && !checkTypeFilter(selectedFilter) ? (
        <FilterListDefault
          selectedFilter={selectedFilter}
          onBackStepFilter={onBackStepFilter}
          onResetAllCurrent={onResetAllCurrent}
          search={search}
          setSearch={setSearch}
          filtersData={filtersData as Record<string, IBoardFilterItem[]>}
          onChange={onChange}
          searchFilters={searchFilters}
        />
      ) : null }

      {selectedFilter && filtersData && checkTypeFilter(selectedFilter) ? (
        <FilterListTabs
          selectedFilter={selectedFilter}
          onBackStepFilter={onBackStepFilter}
          onResetAllCurrent={onResetAllCurrentParent}
          filters={filtersData[selectedFilter]}
          onChange={onChangeParent}
          counterLeft={getCountersTabsFilters(filtersData[selectedFilter] as IBoardFilterItemObj).orgPaths}
          counterRight={getCountersTabsFilters(filtersData[selectedFilter] as IBoardFilterItemObj).geoPaths}
        />
      ) : null }

      <div className={styles.buttons}>
        {!selectedFilter ? (
          <Button
            title={t('common.close')}
            variant={BUTTON_VARIANT.cancel}
            onClick={onClose}
          />
        ) : (
          <Button
            title={t('common.hideSelected')}
            variant={BUTTON_VARIANT.primary}
            onClick={onApplyFilter}
            disabled={!hasChanges}
          />
        )}
      </div>
    </div>
  );
}

export default FiltersPanel;
