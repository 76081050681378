import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'assets/icons/toast-error.svg';

import type { IErrorMessages } from 'store/slices/dataSets/interfaces/IErrorItem';
import type IErrorItem from '../interfaces/IErrorItem';

import styles from '../scss/ErrorItem.module.scss';

function ErrorItem({
  error,
}: IErrorItem) {
  const { t } = useTranslation();
  const { rowNumber, items, errorMessages } = error || {};

  return (
    <div className={styles.root}>
      <div className={styles.errorItemList}>
        <div className={styles.errorItemListTitle}>
          <span>{t('dataSetsManagement.importErrorsModal.validationErrors')}</span>
        </div>
        <div className={styles.rowWrapper}>
          <span>
            <b>{t('dataSetsManagement.importErrorsModal.errorRow')}</b>
            {' '}
            {rowNumber}
          </span>
        </div>
        {items.map((item: string, index: number) => (
          <div
            key={[item, index].join('_')}
            className={styles.errorItemListContent}
          >
            <div className={styles.errorLeftWrapper}>
              <span>
                <b>{t('dataSetsManagement.importErrorsModal.errorColumn')}</b>
                {' '}
                {item}
              </span>
            </div>
            <div className={styles.errorRightWrapper}>
              <ErrorIcon />
              <div className={styles.errorList}>
                {Object.values(errorMessages[index] || {}).flat().map((errorMessage: IErrorMessages) => (
                  <span key={errorMessage.error}>
                    {errorMessage.error}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ErrorItem;
