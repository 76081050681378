import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Backdrop from 'components/Modal/Backdrop';
import {
  CONNECTION_ACTION,
  CONNECTION_VC_LOGIC_FORM_FIELDS, CONNECTION_VC_SERVICE_FORM_FIELDS,
  CONNECTION_VC_SUPPLIER_FORM_FIELDS, JOINT_VENTURE, TYPE_CONNECTION_FORM, TYPE_CONNECTION_FORM_OPTIONS,
} from 'constants/dataSets';
import { CONNECTION_TYPE } from 'constants/formFields';
import { BUTTON_VARIANT } from 'constants/interfaces';

import LogicalAccordion from './components/LogicalAccordion';
import LogicalForm from './components/LogicalForm';
import ServiceForm from './components/ServiceForm';
import SupplierForm from './components/SupplierForm';
import useConnectionVCModal from './hooks/useConnectionVCModal';

import type { TConnectionVCSchema } from 'utils/validators';
import type IConnectionVCModal from './interfaces/IConnectionVCModal';
import type IExpandedState from './interfaces/IExpandedState';

import styles from './scss/ConnectionVCModal.module.scss';

function ConnectionVCModal({
  isOpen, onClose, enumData, onAddServiceConnection, selectedEdge,
}: IConnectionVCModal) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<IExpandedState>(TYPE_CONNECTION_FORM);

  const {
    control,
    watch,
    onResetSelectedFields,
    handleSubmit,
    reset,
  } = useConnectionVCModal({ selectedEdge, enumData });

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const currentExpanded = {
      logical: false,
      supplier: false,
      service: false,
    };
    const currentConnectionType = selectedEdge.map((edge) => edge?.data?.connectionType);
    if (currentConnectionType) {
      currentConnectionType.forEach((type) => {
        if (type) {
          if (typeof type === 'string' && type.includes(TYPE_CONNECTION_FORM_OPTIONS.supplier)) {
            currentExpanded.supplier = true;
          } else {
            currentExpanded[type as keyof IExpandedState] = true;
          }
        }
      });
    }
    setIsExpanded(currentExpanded);
  }, [selectedEdge]);

  const toggleAccordion = (type: keyof typeof TYPE_CONNECTION_FORM_OPTIONS) => {
    setIsExpanded((prev: IExpandedState) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const onSubmitSaveConnection = handleSubmit((values) => {
    const action = selectedEdge.length > 0 ? CONNECTION_ACTION.UPDATE : CONNECTION_ACTION.CREATE;
    onAddServiceConnection(values, isExpanded, action);
    reset();
    onClose();
  });

  const onCloseModal = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (isExpanded.logical === false) {
      onResetSelectedFields(CONNECTION_VC_LOGIC_FORM_FIELDS as (keyof TConnectionVCSchema)[]);
    }
    if (isExpanded.supplier === false) {
      onResetSelectedFields(CONNECTION_VC_SUPPLIER_FORM_FIELDS as (keyof TConnectionVCSchema)[]);
    }
    if (isExpanded.service === false) {
      onResetSelectedFields(CONNECTION_VC_SERVICE_FORM_FIELDS as (keyof TConnectionVCSchema)[]);
    }
  }, [isExpanded, onResetSelectedFields]);

  return isOpen ? createPortal(
    <Backdrop>
      <div className={styles.modalOverlay}>
        <div
          className={styles.modalContent}
        >
          <h2 id="modal-title" className={styles.modalTitle}>
            {t('valueChainMap.connection')}
          </h2>
          <div className={styles.modalBody}>
            <LogicalAccordion
              title={t('valueChainMap.logical')}
              isExpanded={isExpanded.logical}
              type={TYPE_CONNECTION_FORM_OPTIONS.logical}
              toggleAccordion={toggleAccordion}
            >
              <LogicalForm
                control={control}
                showField={watch(CONNECTION_TYPE)?.label === JOINT_VENTURE}
              />
            </LogicalAccordion>
            <LogicalAccordion
              title={t('valueChainMap.supplierOfGoods')}
              isExpanded={isExpanded.supplier}
              type={TYPE_CONNECTION_FORM_OPTIONS.supplier}
              toggleAccordion={toggleAccordion}
            >
              <SupplierForm
                control={control}
                enumData={enumData}
              />
            </LogicalAccordion>
            <LogicalAccordion
              title={t('valueChainMap.service')}
              isExpanded={isExpanded.service}
              type={TYPE_CONNECTION_FORM_OPTIONS.service}
              toggleAccordion={toggleAccordion}
            >
              <ServiceForm
                control={control}
                enumData={enumData}
              />
            </LogicalAccordion>
          </div>
          <div className={styles.modalFooter}>
            <Button
              title={t('common.cancel')}
              variant={BUTTON_VARIANT.cancel}
              onClick={onCloseModal}
            />
            <Button
              title={t('common.save')}
              variant={BUTTON_VARIANT.primary}
              onClick={onSubmitSaveConnection}
            />
          </div>
        </div>
      </div>
    </Backdrop>,
    document.body,
  ) : null;
}

export default ConnectionVCModal;
