import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import fscreen from 'fscreen';

import { useAppDispatch, useAppSelector } from '../store/hooks/useApp';
import selectFullScreenActive from '../store/slices/general/selectors';
import { setGeneralState } from '../store/slices/general/slice';

function useFullScreenHandle() {
  const active = useAppSelector(selectFullScreenActive);

  const dispatch = useAppDispatch();
  const node = useRef<HTMLDivElement | null>(null);

  const handleFullScreenChange = useCallback(() => {
    const isActive = fscreen.fullscreenElement === document.body;
    dispatch(setGeneralState({ isFullScreenActive: isActive }));
  }, [dispatch]);

  useEffect(() => {
    fscreen.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => fscreen.removeEventListener('fullscreenchange', handleFullScreenChange);
  }, [handleFullScreenChange]);

  const requestFullscreen = () => new Promise<void>((resolve) => {
    fscreen.requestFullscreen(document.body);
    resolve();
  });

  const exitFullscreen = () => new Promise<void>((resolve) => {
    fscreen.exitFullscreen();
    resolve();
  });

  const enter = useCallback(() => {
    if (fscreen.fullscreenElement) {
      return exitFullscreen().then(() => requestFullscreen());
    }
    return requestFullscreen();
  }, []);

  const exit = useCallback(() => {
    if (fscreen.fullscreenElement !== document.body) return Promise.resolve();
    return exitFullscreen();
  }, []);

  return useMemo(
    () => ({
      active,
      enter,
      exit,
      node,
    }),
    [active, enter, exit, node],
  );
}

export default useFullScreenHandle;
