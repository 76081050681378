import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { ReactComponent as DisabledIcon } from 'assets/icons/disabled.svg';
import { ReactComponent as EnabledIcon } from 'assets/icons/enabled.svg';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import { DATA_SET_MAX_MENU_HEIGHT } from 'constants/dataSets';
import { MAPPING_FIELDS_PREFIX } from 'constants/general';

import type { IMultipleItemProps } from '../interfaces/IMultipleItemProps';

import styles from '../scss/MappingMultipleModal.module.scss';

function MultipleItem({
  source,
  index,
  item,
  control,
  watch,
  getOptions,
  sheetSources,
  wrapperRef,
}: IMultipleItemProps) {
  const { t } = useTranslation();

  const selectedTarget = watch(`${MAPPING_FIELDS_PREFIX.source}${item.replace(/'/g, '')}`);
  const selectedSource = watch(
    `${MAPPING_FIELDS_PREFIX.source}${item.replace(/'/g, '')}_${index}`,
  );

  return (
    <div className={styles.mappingItem} key={[source.target, index].join('_')}>
      <div className={styles.status}>{selectedSource ? <EnabledIcon /> : <DisabledIcon />}</div>
      <Controller
        control={control}
        name={`${MAPPING_FIELDS_PREFIX.target}${source.target.replace(/'/g, '')}_${index}`}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <TextField
            className={styles.field}
            name={name}
            value={value}
            error={error?.message}
            onChange={(event) => onChange(event.target.value)}
          />
        )}
      />
      <div className={styles.arrow}>
        <ChevronIcon />
      </div>
      <Controller
        control={control}
        name={`${MAPPING_FIELDS_PREFIX.source}${item.replace(/'/g, '')}_${index}`}
        render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
          <Select
            className={styles.field}
            options={getOptions(sheetSources, selectedTarget)}
            placeholder={t('dataSetsManagement.mappingModal.selectPlaceholder')}
            value={error?.message && !isDirty ? '' : value}
            onChange={onChange}
            menuPosition="absolute"
            required
            placeholderClassName={styles.placeholder}
            maxMenuHeight={DATA_SET_MAX_MENU_HEIGHT}
            modalRef={wrapperRef}
            classNameMenu={styles.menuMultiple}
          />
        )}
      />
    </div>
  );
}

export default MultipleItem;
