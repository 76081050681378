import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashBox.svg';
import Button from 'components/Button/Button';

import type ILogicalAccordionProps from '../interfaces/ILogicalAccordionProps';

import styles from '../scss/LogicalAccordion.module.scss';

function LogicalAccordion({
  title,
  children,
  isExpanded,
  type,
  toggleAccordion,
}: ILogicalAccordionProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [children]);

  return (
    <div className={styles.accordion}>
      <div
        className={`${styles.header} ${isExpanded ? styles.expanded : ''}`}
        aria-expanded={isExpanded}
        aria-controls="accordion-content"
      >
        <span className={styles.title}>{title}</span>
        <Button
          title={isExpanded ? t('valueChainMap.removeConnection') : t('valueChainMap.addConnection')}
          onClick={() => toggleAccordion(type)}
          className={styles.buttonConnection}
          startIcon={isExpanded ? <TrashIcon /> : <PlusIcon />}
        />
      </div>
      <div
        id="accordion-content"
        ref={contentRef}
        className={styles.content}
        style={{
          height: isExpanded ? `${contentHeight}px` : '0',
        }}
        aria-hidden={!isExpanded}
      >
        <div className={styles.innerContent}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default LogicalAccordion;
