import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProfitCenterIcon } from 'assets/icons/profitCenter.svg';

import { formatRevenueAndPercentage } from '../../../../../../utils/helpers';
import CustomNodeItem from '../../BusinessStructure/components/CustomNodeItem';

import type { IProfitCentreNode } from '../interfaces/IProfitCentreNode';

import styles from '../sass/CustomUnitNode.module.scss';

function ProfitCentreNode({
  name, netBookValue, totalRevenueRolling12MActual, totalRevenueRolling12MPercents,
  totalGrossMarginRolling12MPercents, totalGrossMarginRolling12MActual, wipFinishedGoodsStock,
  uniqueIdentifier,
}: IProfitCentreNode) {
  const { t } = useTranslation();

  const customNodeItems = [
    {
      title: t('valueChainMap.profitCenterId'),
      value: uniqueIdentifier || '-',
    },
    {
      title: t('valueChainMap.bookValue'),
      value: formatRevenueAndPercentage({
        actual: netBookValue?.amount,
        currency: netBookValue?.currency,
      }),
    },
    {
      title: t('valueChainMap.actualRevenue'),
      value: formatRevenueAndPercentage({
        actual: totalRevenueRolling12MActual?.amount,
        currency: totalRevenueRolling12MActual?.currency,
        percentage: totalRevenueRolling12MPercents?.toString(),
      }),
    },
    {
      title: t('valueChainMap.actualGrossMargin'),
      value: formatRevenueAndPercentage({
        actual: totalGrossMarginRolling12MActual?.amount,
        currency: totalGrossMarginRolling12MActual?.currency,
        percentage: totalGrossMarginRolling12MPercents?.toString(),
      }),
    },
    {
      title: t('valueChainMap.rawMaterialWipFinishedGoodsStock'),
      value: formatRevenueAndPercentage({
        actual: wipFinishedGoodsStock?.amount,
        currency: wipFinishedGoodsStock?.currency,
      }),
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <ProfitCenterIcon />
        <p className={styles.title}>{name}</p>
      </div>
      <div className={styles.content}>
        {customNodeItems.map(({ title, value }) => (
          <CustomNodeItem
            key={`customNodeItems_${title}`}
            title={title}
            value={value}
          />
        ))}
      </div>
    </>
  );
}

export default ProfitCentreNode;
