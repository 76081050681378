import { useTranslation } from 'react-i18next';
import { DROPDOWN_DIRECTION } from 'constants/general';

import type IMoreActionsDropdown from 'pages/CompanyAdminPages/ValueChainMap/interfaces/IMoreActionsDropdown';

import styles from '../scss/ValueChainMapTableActions.module.scss';

function MoreActionsDropdown({
  canRename,
  isArchived,
  setRenameModalOpen,
  dropdownDirection,
  setArchiveModalOpen,
  deArchiveHandler,
  dublicateHandler,
}: IMoreActionsDropdown) {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.moreActionsDropdown} ${
        dropdownDirection === DROPDOWN_DIRECTION.up ? styles.up : styles.down
      }`}
    >
      {!isArchived && canRename ? (
        <div
          className={styles.moreActionsListItem}
          onClick={() => {
            setRenameModalOpen(true);
          }}
          aria-hidden="true"
        >
          <span>{t('valueChainMap.rename')}</span>
        </div>
      ) : null}
      <div className={styles.moreActionsListItem} onClick={dublicateHandler} aria-hidden="true">
        <span>{t('valueChainMap.dublicate')}</span>
      </div>
      {
        isArchived ? (
          <div className={styles.moreActionsListItem} onClick={deArchiveHandler} aria-hidden="true">
            <span>{t('valueChainMap.restore')}</span>
          </div>
        ) : (
          <div
            className={styles.moreActionsListItem}
            onClick={() => {
              setArchiveModalOpen(true);
            }}
            aria-hidden="true"
          >
            <span>{t('valueChainMap.archive')}</span>
          </div>
        )
      }
    </div>
  );
}

export default MoreActionsDropdown;
