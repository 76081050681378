import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import { ReactComponent as HourGlassIcon } from 'assets/icons/hourglass.svg';
import IconWithText from 'components/IconWithText/IconWithText';
import { BUSINESS_UNIT_TYPES } from 'constants/interfaces';

import type { IUnitStatuses } from '../interfaces/IUnitStatuses';

import styles from '../sass/CustomUnitNode.module.scss';

function UnitStatuses({ type, estimated, businessUnitType }: IUnitStatuses) {
  const { t } = useTranslation();
  return (
    businessUnitType === BUSINESS_UNIT_TYPES.LOGICAL || estimated ? (
      <div className={styles.statuses}>
        {businessUnitType === BUSINESS_UNIT_TYPES.LOGICAL ? (
          <IconWithText
            icon={<ExpandIcon width={20} height={20} />}
            text={t('valueChainMap.logical')}
            containerStyle={styles[type]}
          />
        ) : null}
        {estimated ? (
          <IconWithText
            icon={<HourGlassIcon width={20} height={20} />}
            text={t('valueChainMap.estimated')}
            containerStyle={styles.estimated}
          />
        ) : null}
      </div>
    ) : null
  );
}

export default UnitStatuses;
