import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Button from 'components/Button/Button';
import RenameModal from 'components/RenameModal/RenameModal';
import Title from 'components/Title/Title';

import { USER_PILLAR_MODULES, USER_PILLARS } from '../../../../../constants/interfaces';
import usePillarModulePermission from '../../../../../hooks/usePillarModulePermission';

import type IValueChainMapHeading from '../../interfaces/IValueChainMapHeading';

import styles from './scss/ValueChainMapTableHeading.module.scss';

function ValueChainMapHeading({ isEmpty, onCreateNewValueChain, disableCreateMap }: IValueChainMapHeading) {
  const { t } = useTranslation();
  const [isCreateNewValueChainModalOpen, setIsCreateNewValueChainModalOpen] = useState<boolean>(false);

  const { isActionsVisible } = usePillarModulePermission({
    pillarModuleKey: USER_PILLAR_MODULES[USER_PILLARS.valueChainAssessment].valueChainMapManagement,
  });

  const onHandleClick = () => {
    setIsCreateNewValueChainModalOpen(true);
  };

  const onCreateNewValueChainHandler = (name: string) => {
    setIsCreateNewValueChainModalOpen(false);
    onCreateNewValueChain(name);
  };

  return (
    <div className={styles.root}>
      <Title className={styles.title} text={t('valueChainMap.pageTitle')} />
      {
        !isEmpty && isActionsVisible ? (
          <Button
            onClick={onHandleClick}
            className={styles.addButton}
            startIcon={<PlusIcon />}
            title={t('valueChainMap.addNewValueChainMap')}
            disabled={disableCreateMap}
          />
        ) : null
      }
      <RenameModal
        onSubmit={onCreateNewValueChainHandler}
        onReject={() => setIsCreateNewValueChainModalOpen(false)}
        open={isCreateNewValueChainModalOpen}
        name=""
      />
    </div>
  );
}

export default ValueChainMapHeading;
