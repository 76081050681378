import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'components/Button/Button';
import Separator from 'components/Separator/Separator';
import { BUTTON_VARIANT } from 'constants/interfaces';
import { selfDataSets } from 'store/slices/dataSets/selectors';

import Loader from '../Loader/Loader';
import Backdrop from '../Modal/Backdrop';
import ImportMultipleErrorsItem from './components/ImportMultipleErrorsItem';

import type IImportErrorsModal from './interfaces/IImportErrorsModal';

import styles from './scss/ImportMultipleErrorsModal.module.scss';

function ImportMultipleErrorsModal({
  isImportErrorsModalVisible,
  setIsImportErrorsModalVisible,
  isLoading,
}: IImportErrorsModal) {
  const { t } = useTranslation();
  const { mappingColumns } = useSelector(selfDataSets);
  const { sheetErrors } = mappingColumns || {};

  useEffect(() => {
    if (isImportErrorsModalVisible) {
      document.body.style.overflow = 'hidden';
    } else if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }, [isImportErrorsModalVisible]);

  const errorsKeys = sheetErrors && Object.keys(sheetErrors).length > 0 ? Object.keys(sheetErrors) : [];

  return isImportErrorsModalVisible ? createPortal(
    <Backdrop>
      <Loader isPageLoading={isLoading} />
      <div className={styles.root}>
        <h2 className={styles.title}>{t('dataSetsManagement.importErrorsModal.title')}</h2>
        <div className={styles.content}>
          <Separator />
          <div className={styles.wrapper}>
            {errorsKeys.map((key) => (
              <ImportMultipleErrorsItem
                key={key}
                sheetKey={key}
                sheetErrors={sheetErrors || {}}
              />
            ))}
          </div>
        </div>
        <Separator />
        <div className={styles.footer}>
          <div className={styles.buttonWrapper}>
            <Button
              title={t('common.close')}
              variant={BUTTON_VARIANT.cancel}
              onClick={() => { setIsImportErrorsModalVisible(false); }}
            />
          </div>
        </div>
      </div>
    </Backdrop>,
    document.body,
  ) : null;
}

export default ImportMultipleErrorsModal;
