import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type IDataSetsStore from './interfaces/IDataSetsStore';

export const initialState: IDataSetsStore = {
  mappingColumns: undefined,
  selectedProfitCenter: undefined,
};

export const dataSetsSlice = createSlice({
  name: 'dataSets',
  initialState,
  reducers: {
    setDataSetsState: (state, { payload }:
    PayloadAction<Partial<IDataSetsStore>>) => ({ ...state, ...payload }),
    resetState: () => initialState,
  },
  extraReducers: () => {},
});

export const {
  resetState, setDataSetsState,
} = dataSetsSlice.actions;

export default dataSetsSlice.reducer;
