import { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES } from 'constants/dataSets';
import {
  CODE, CRITICALITY_FROM_DOWNSTREAM_WASTE, CRITICALITY_RATING,
  DATE_CLOSED,
  DATE_OPENED,
  END_DATE,
  EQUITY_CONTROL,
  EXPECTED_GROWTH_DECLINE, KEY_SUPPLIER_TIER_1_TOTAL, KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY,
  KEY_SUPPLIER_TIER_10_OF_TOTAL, NAME,
  PROFIT_CENTER_ID,
  RELIANCE_ON_FREE_NATURAL_RESOURCES, RELIANCE_ON_FREE_SOCIAL_RESOURCES,
  START_DATE, SUPPLIER,
  UNIQUE_IDENTIFIER,
  UPSTREAM_TITLE,
  VOLUME_QTY,
  VOLUME_QTY_UNIT,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT } from 'constants/general';
import dayjs from 'dayjs';

import createEnumOptions from '../createEnumOptions';
import createUidNameTuplesOptions from '../createUidNameTuplesOptions';
import getFormValue from '../getFormValue';

import type { IGetProductDefaultValues } from './interfaces/IGetProductDefaultValues';

function getProductDefaultValues({ values, uidNameTuples, enumData }: IGetProductDefaultValues) {
  const {
    name, code, profitCenterId, criticalityRating, dateClosed, dateOpened,
    equityControl, supplierIds, uniqueIdentifier, volumeQty,
    volumeQtyUnit, expectedGrowthDecline, circularityFromDownstreamWaste,
    relianceOnFreeNaturalResources, startDate, endDate, upstreamTitle,
    relianceOnFreeSocialResources,
    keySupplierTier1OfTotal, keySupplierTier1Total,
  } = values || {};

  const defaultOption = { value: '', label: '' };
  const profitCenterOptions = createUidNameTuplesOptions({
    uidNameTuples,
    values: profitCenterId || [],
    tuplesKey: UID_NAME_TUPLES.profitCenter,
  });

  const supplierOptions = createUidNameTuplesOptions({
    uidNameTuples,
    values: supplierIds?.length ? supplierIds : [],
    tuplesKey: UID_NAME_TUPLES.supplier,
  });

  const relianceOnFreeNaturalOptions = createEnumOptions({
    enumData,
    values: relianceOnFreeNaturalResources || [],
    enumKey: DATA_SETS_ENUM_TYPES.relianceOnFreeNaturalResources,
  });

  const relianceOnFreeSocialOptions = createEnumOptions({
    enumData,
    values: relianceOnFreeSocialResources || [],
    enumKey: DATA_SETS_ENUM_TYPES.relianceOnFreeSocialResources,
  });

  return {
    [NAME]: name || '',
    [CODE]: code || '',
    [UNIQUE_IDENTIFIER]: uniqueIdentifier || '',
    [UPSTREAM_TITLE]: upstreamTitle ? {
      value: upstreamTitle, label: upstreamTitle,
    } : defaultOption,
    [PROFIT_CENTER_ID]: profitCenterOptions?.length > 0 ? profitCenterOptions : [],
    [SUPPLIER]: supplierOptions.length > 0 ? supplierOptions : [defaultOption],
    [START_DATE]: startDate ? dayjs(startDate).format(DATA_SETS_DATE_FORMAT) : '',
    [END_DATE]: endDate ? dayjs(endDate).format(DATA_SETS_DATE_FORMAT) : '',
    [DATE_OPENED]: dateOpened ? dayjs(dateOpened).format(DATA_SETS_DATE_FORMAT) : '',
    [DATE_CLOSED]: dateClosed ? dayjs(dateClosed).format(DATA_SETS_DATE_FORMAT) : '',
    [VOLUME_QTY]: volumeQty ? String(volumeQty) : '',
    [VOLUME_QTY_UNIT]: volumeQtyUnit ? {
      value: volumeQtyUnit, label: volumeQtyUnit,
    } : defaultOption,
    [EQUITY_CONTROL]: getFormValue({ value: equityControl }),
    [EXPECTED_GROWTH_DECLINE]: getFormValue({ value: expectedGrowthDecline }),
    [RELIANCE_ON_FREE_NATURAL_RESOURCES]: relianceOnFreeNaturalOptions?.length > 0 ? relianceOnFreeNaturalOptions : [],
    [RELIANCE_ON_FREE_SOCIAL_RESOURCES]: relianceOnFreeSocialOptions?.length > 0 ? relianceOnFreeSocialOptions : [],
    [CRITICALITY_RATING]: criticalityRating ? {
      value: criticalityRating, label: criticalityRating,
    } : defaultOption,
    [CRITICALITY_FROM_DOWNSTREAM_WASTE]: getFormValue({ value: circularityFromDownstreamWaste }),
    [KEY_SUPPLIER_TIER_1_TOTAL]: getFormValue({ value: keySupplierTier1Total?.amount }),
    [KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY]: keySupplierTier1Total?.currency ? {
      value: keySupplierTier1Total.currency, label: keySupplierTier1Total.currency,
    } : defaultOption,
    [KEY_SUPPLIER_TIER_10_OF_TOTAL]: getFormValue({ value: keySupplierTier1OfTotal }),
  };
}

export default getProductDefaultValues;
