import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConnectionTypeIcon } from 'assets/icons/connection-type.svg';

import { VALUE_CHAIN_CONNECTION_TYPES } from '../../../../../../constants/interfaces';

import styles from '../sass/ColorsTip.module.scss';

function ColorsTip() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p>{t('valueChainMap.connectionTypes')}</p>
      <ul className={styles.list}>
        {Object.entries(VALUE_CHAIN_CONNECTION_TYPES).map(([key, value]) => (
          <li key={`profitCenters_${key}`}>
            <ConnectionTypeIcon className={styles[value.replace(/\s+/g, '')]} />
            <p className={styles.text}>{t(`valueChainMap.${value.replace(/\s+/g, '')}`)}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ColorsTip;
