import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from 'components/Button/Button';
import CheckboxParent from 'components/CheckboxParent/CheckboxParent';
import { BUTTON_VARIANT } from 'constants/interfaces';
import { VALUE_TABS_OWNER } from 'constants/valueChain';

import HeaderFilterPanel from './HeaderFilterPanel';

import type { IFilterListTabsItem } from 'store/slices/valueChainMap/interfaces/IBoardFilter';
import type IFilterListTabs from '../interfaces/IFilterListTabs';

import styles from '../sass/FiltersPanel.module.scss';

function FilterListTabs({
  selectedFilter,
  onBackStepFilter,
  onResetAllCurrent,
  filters,
  onChange,
  counterLeft,
  counterRight,
}: IFilterListTabs) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>(VALUE_TABS_OWNER.ORGANIZATIONAL);
  const [selectedTabFilters, setSelectedTabFilters] = useState<boolean | IFilterListTabsItem[]>();

  useEffect(() => {
    if (filters && !Array.isArray(filters)) {
      const array = Object.keys(filters).map((key) => filters[key]);
      if (selectedTab === VALUE_TABS_OWNER.ORGANIZATIONAL) {
        setSelectedTabFilters(array[1]);
      }

      if (selectedTab === VALUE_TABS_OWNER.GEOGRAPHICAL) {
        setSelectedTabFilters(array[0]);
      }
    }
  }, [filters, selectedTab]);

  return (
    <div className={styles.content}>
      <HeaderFilterPanel
        selectedFilter={selectedFilter}
        onBackStepFilter={onBackStepFilter}
        onResetAllCurrent={onResetAllCurrent}
        ghostButtonTitle={t('common.reset')}
      />
      <div className={styles.contentWrapper}>
        <div className={styles.tabsWrapper}>
          <div className={clsx(styles.tab, { [styles.active]: selectedTab === VALUE_TABS_OWNER.ORGANIZATIONAL })}>
            <Button
              title={t('valueChainMap.organizational')}
              variant={BUTTON_VARIANT.ghost}
              onClick={() => setSelectedTab(VALUE_TABS_OWNER.ORGANIZATIONAL)}
            />
            {counterLeft > 0 ? (
              <div className={styles.circleCounter}>
                {counterLeft}
              </div>
            ) : null}
          </div>
          <div className={clsx(styles.tab, { [styles.active]: selectedTab === VALUE_TABS_OWNER.GEOGRAPHICAL })}>
            <Button
              title={t('valueChainMap.geographical')}
              variant={BUTTON_VARIANT.ghost}
              onClick={() => setSelectedTab(VALUE_TABS_OWNER.GEOGRAPHICAL)}
            />
            {counterRight > 0 ? (
              <div className={styles.circleCounter}>
                {counterRight}
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.tabContent}>
          {selectedTabFilters && Array.isArray(selectedTabFilters)
            ? selectedTabFilters?.map((filter) => (
              <CheckboxParent
                key={filter.name}
                name={filter.name}
                checked={filter.checked}
                onChange={onChange}
                label={filter.name}
                type={selectedTab === VALUE_TABS_OWNER.GEOGRAPHICAL
                  ? VALUE_TABS_OWNER.GEO_PATHS : VALUE_TABS_OWNER.ORG_PATHS}
                items={filter.children}
                hideChevron={filter?.children.length <= 0}
              />

            )) : null}
        </div>
      </div>
    </div>
  );
}

export default FilterListTabs;
