import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DATA_SETS_MAP_DEFAULT_VALUES, DATA_SETS_MAP_PRODUCT_SUPPLIERS } from 'constants/dataSets';
import { DATA_SET_UPLOAD_FILE } from 'constants/formFields';
import { PRESIGNED_FILE_TYPES } from 'constants/interfaces';
import { useAppDispatch } from 'store/hooks/useApp';
import { setDataSetsState } from 'store/slices/dataSets/slice';
import { useGetPresignedPostMutation } from 'store/slices/files/apis/filesApi';
import { uploadFileToS3 } from 'utils/helpers';

import useDataSetsUploadForm from './useDataSetsUploadForm';

import type { TPresignedFileTypes } from 'constants/interfaces';
import type {
  TUseProfitCenterImportRecordsMutation,
} from 'pages/UserPages/ProfitCentres/interfaces/TUseProfitCenterImportRecordsMutation';
import type IUseDownloadExample from './interfaces/IUseDownloadExample';

function useDownloadExample<T extends TUseProfitCenterImportRecordsMutation>({ importRecords }:
IUseDownloadExample<T>) {
  const [isMappingModalVisible, setIsMappingModalVisible] = useState(false);
  const [isImportErrorsModalVisible, setIsImportErrorsModalVisible] = useState(false);
  const { control: uploadControl, watch, reset } = useDataSetsUploadForm();
  const dataSetFile = watch(DATA_SET_UPLOAD_FILE);
  const dispatch = useAppDispatch();
  const [getPresignedPost, { isLoading }] = useGetPresignedPostMutation();
  const { pathname } = useLocation();

  const uploadFile = useCallback((file: File | string, fileType: TPresignedFileTypes) => {
    if (typeof file === 'object') {
      return getPresignedPost({ fileType })
        .unwrap()
        .then((presignedData) => uploadFileToS3(file, presignedData.data)
          .then(() => presignedData.data.fields.Url))
        .catch(() => '');
    }
    return Promise.resolve(file);
  }, [getPresignedPost]);

  useEffect(() => {
    if (dataSetFile && !isLoading) {
      const currentOption = pathname.includes('products')
        ? DATA_SETS_MAP_PRODUCT_SUPPLIERS : DATA_SETS_MAP_DEFAULT_VALUES;
      uploadFile(dataSetFile, PRESIGNED_FILE_TYPES.dataSet).then((url) => importRecords({
        url,
        ...currentOption,
      }).unwrap().then((response) => {
        dispatch(setDataSetsState({ mappingColumns: response.data }));
        setIsMappingModalVisible(true);
      })).catch(() => {});
      reset({
        [DATA_SET_UPLOAD_FILE]: '',
      });
    }
  }, [dataSetFile, uploadFile, reset, isLoading, importRecords,
    dispatch, setIsMappingModalVisible, pathname]);

  const onCloseMappingModal = () => {
    setIsMappingModalVisible(false);
  };

  return {
    uploadControl,
    isMappingModalVisible,
    onCloseMappingModal,
    isImportErrorsModalVisible,
    setIsImportErrorsModalVisible,
  };
}

export default useDownloadExample;
