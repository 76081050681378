import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import {
  useEnumQuery,
  useGetNaceCodeTreeQuery,
  useOwnOperationsRecordQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectEnums } from 'store/slices/dataSets/selectors';
import getOwnOperationsDefaultValues from 'utils/formHelpers/getOwnOperationsDefaultValues';
import { createOwnOperationsSchema } from 'utils/validators';

import type { TCreateOperationsSchema } from 'utils/validators';
import type { ICreateOwnOperationsForm } from '../interfaces/ICreateOwnOperationsForm';

const useCreateOwnOperationsForm = ({ recordId, isPageLoading }: ICreateOwnOperationsForm) => {
  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { data } = useOwnOperationsRecordQuery(fetchQuery);

  const { data: naceCodeTreeData } = useGetNaceCodeTreeQuery();

  const { data: enumData } = useEnumQuery({
    types: [DATA_SETS_ENUM_TYPES.currency, DATA_SETS_ENUM_TYPES.ownership, DATA_SETS_ENUM_TYPES.valueChainSection,
      DATA_SETS_ENUM_TYPES.businessUnitType, DATA_SETS_ENUM_TYPES.assetCategory, DATA_SETS_ENUM_TYPES.ownedLeased],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectEnums(result.data) }),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isLoading },
    reset,
    setError,
    getValues,
    watch,
    setValue,
    resetField,
  } = useForm<TCreateOperationsSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(createOwnOperationsSchema),
    defaultValues: getOwnOperationsDefaultValues(
      {
        values: data?.data,
        naceCodeTreeData,
      },
    ),
  });

  useEffect(() => {
    if (data?.data && !isPageLoading) {
      reset(getOwnOperationsDefaultValues(
        {
          values: data?.data,
          naceCodeTreeData,
          enumData,
        },
      ));
    }
  }, [reset, data, isPageLoading, naceCodeTreeData, enumData]);

  const fieldsName = Object.keys(getValues());

  return {
    control,
    handleSubmit,
    isDirty,
    reset,
    isLoading,
    setError,
    fieldsName,
    watch,
    setValue,
    resetField,
  };
};

export default useCreateOwnOperationsForm;
