import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DatePicker from 'components/DatePicker/DatePicker';
import FieldWithCurrency from 'components/FieldWithCurrency/FieldWithCurrency';
import Loader from 'components/Loader/Loader';
import NumberField from 'components/NumberField/NumberField';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import { DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import {
  END_DATE,
  FINISHED_GOOD_STOCK_AMOUNT,
  FINISHED_GOOD_STOCK_AMOUNT_CURRENCY,
  GROSS_MARGIN_ROLLING_AMOUNT,
  GROSS_MARGIN_ROLLING_CURRENCY,
  GROSS_MARGIN_ROLLING_PERCENTS,
  PROFIT_CENTRE_NAME,
  PROFIT_CENTRE_UNIQUE_IDENTIFIER,
  REVENUE_ROLLING_AMOUNT,
  REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
  START_DATE,
} from 'constants/formFields';
import { MENU_POSITION } from 'constants/general';
import { NUMBER_FORMATS } from 'constants/interfaces';

import StepCounter from './StepCounter';

import type IFieldError from '../../../../../../store/types/IFieldError';
import type IProfitCenterForm from '../../interfaces/IProfitCenterForm';

import styles from '../sass/ProfitCentreModal.module.scss';
import stylesCurrency from 'components/FieldWithCurrency/scss/FieldWithCurrency.module.scss';

export default function ProfitCenterForm({
  control,
  enumData,
  recordId,
  startDate,
  onStartDateChange,
  isLoading,
  modalRef,
}: IProfitCenterForm) {
  const { t } = useTranslation();
  const placeholder = recordId ? t('common.notProvided') : '';

  return (
    <div>
      <StepCounter />
      <div className={styles.OwnOperationModalFormWrapper}>
        <Loader isPageLoading={isLoading} isFullLoader={false} />

        <DataSetsPanel title={t('profitCentres.createProfitCentre.generalPanel')}>
          <Controller
            name={PROFIT_CENTRE_NAME}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                name={name}
                error={error?.message}
                label={t('profitCentres.createProfitCentre.fields.name')}
                placeholder={placeholder}
                onChange={(event) => onChange(event.target.value)}
                required
              />
            )}
          />
          <Controller
            name={PROFIT_CENTRE_UNIQUE_IDENTIFIER}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                label={t('profitCentres.createProfitCentre.fields.uniqueIdentifier')}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                error={error?.message}
                placeholder={placeholder}
                disabled={!!recordId}
                required
              />
            )}
          />
        </DataSetsPanel>

        <DataSetsPanel title={t('profitCentres.createProfitCentre.financialsPanel')}>
          <Controller
            control={control}
            name={START_DATE}
            render={({
              field: {
                value,
                name,
                onChange,
              },
              fieldState: { error },
            }) => (
              <DatePicker
                error={error?.message}
                label={t('suppliers.fields.startDate')}
                onChange={(date) => {
                  onStartDateChange(date);
                  onChange(date);
                }}
                value={value}
                maxDate={new Date()}
                placeholder={placeholder}
                name={name}
              />
            )}
          />
          <Controller
            name={END_DATE}
            control={control}
            render={({
              field: {
                name,
                value,
                onChange,
              },
              fieldState: { error },
            }) => (
              <DatePicker
                placeholder={placeholder}
                label={t('suppliers.fields.endDate')}
                onChange={onChange}
                error={error?.message}
                value={value}
                name={name}
                minDate={startDate}
              />
            )}
          />
          <Controller
            name={REVENUE_ROLLING_PERCENTS}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <NumberField
                placeholder={placeholder}
                value={value}
                label={t('profitCentres.createProfitCentre.fields.totalRevenue')}
                name={name}
                onChange={(values) => onChange(values.value)}
                error={error?.message}
                type={NUMBER_FORMATS.percent}
              />
            )}
          />
          <Controller
            control={control}
            name={REVENUE_ROLLING_AMOUNT}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <FieldWithCurrency name={name} label={t('profitCentres.createProfitCentre.fields.actualRevenue')}>
                <div className={stylesCurrency.fieldWithCurrency}>
                  <NumberField
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onChange={(values) => onChange(values.value)}
                    error={error?.message}
                  />
                  <Controller
                    name={REVENUE_ROLLING_CURRENCY}
                    control={control}
                    render={({
                      field: { value: valueCurrency, onChange: onChangeCurrency },
                      fieldState: { error: errorCurrency },
                    }) => (
                      <Select
                        className={stylesCurrency.select}
                        options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                        placeholder={t('common.currency')}
                        value={valueCurrency?.value ? valueCurrency : undefined}
                        onChange={onChangeCurrency}
                        error={(errorCurrency as IFieldError)?.value?.message}
                        modalRef={modalRef}
                        menuPosition={MENU_POSITION.ABSOLUTE}
                      />
                    )}
                  />
                </div>
              </FieldWithCurrency>
            )}
          />
          <Controller
            name={GROSS_MARGIN_ROLLING_PERCENTS}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <NumberField
                placeholder={placeholder}
                value={value}
                name={name}
                error={error?.message}
                label={t('profitCentres.createProfitCentre.fields.totalGross')}
                onChange={(values) => onChange(values.value)}
                type={NUMBER_FORMATS.percent}
              />
            )}
          />
          <Controller
            name={GROSS_MARGIN_ROLLING_AMOUNT}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <FieldWithCurrency name={name} label={t('profitCentres.createProfitCentre.fields.actualGross')}>
                <div className={stylesCurrency.fieldWithCurrency}>
                  <NumberField
                    value={value}
                    name={name}
                    onChange={(values) => onChange(values.value)}
                    error={error?.message}
                    placeholder={placeholder}
                  />
                  <Controller
                    name={GROSS_MARGIN_ROLLING_CURRENCY}
                    control={control}
                    render={({
                      field: { value: valueCurrency, onChange: onChangeCurrency },
                      fieldState: { error: errorCurrency },
                    }) => (
                      <Select
                        className={stylesCurrency.select}
                        options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                        value={valueCurrency?.value ? valueCurrency : undefined}
                        placeholder={t('common.currency')}
                        onChange={onChangeCurrency}
                        menuPortalClassName={styles.menuPortal}
                        error={(errorCurrency as IFieldError)?.value?.message}
                        modalRef={modalRef}
                        menuPosition={MENU_POSITION.ABSOLUTE}
                      />
                    )}
                  />
                </div>
              </FieldWithCurrency>
            )}
          />
          <Controller
            name={FINISHED_GOOD_STOCK_AMOUNT}
            control={control}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <FieldWithCurrency name={name} label={t('profitCentres.createProfitCentre.fields.goodsStock')}>
                <div className={stylesCurrency.fieldWithCurrency}>
                  <NumberField
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    onChange={(values) => onChange(values.value)}
                    error={error?.message}
                  />
                  <Controller
                    control={control}
                    name={FINISHED_GOOD_STOCK_AMOUNT_CURRENCY}
                    render={({
                      field: { value: valueCurrency, onChange: onChangeCurrency },
                      fieldState: { error: errorCurrency },
                    }) => (
                      <Select
                        options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                        placeholder={t('common.currency')}
                        onChange={onChangeCurrency}
                        value={valueCurrency?.value ? valueCurrency : undefined}
                        className={stylesCurrency.select}
                        error={(errorCurrency as IFieldError)?.value?.message}
                        modalRef={modalRef}
                        menuPosition={MENU_POSITION.ABSOLUTE}
                      />
                    )}
                  />
                </div>
              </FieldWithCurrency>
            )}
          />
        </DataSetsPanel>

      </div>
    </div>
  );
}
