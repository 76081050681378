import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Checkbox from 'components/Checkbox/Checkbox';
import TextField from 'components/TextField/TextField';
import { NAME_FILTERS, NAME_INPUTS_DEFAULT } from 'constants/dataSets';

import HeaderFilterPanel from './HeaderFilterPanel';

import type IFilterListDefault from '../interfaces/IFilterListDefault';

import styles from '../sass/FiltersPanel.module.scss';

function FilterListDefault({
  selectedFilter,
  onBackStepFilter,
  onResetAllCurrent,
  search,
  setSearch,
  filtersData,
  onChange,
  searchFilters,
}: IFilterListDefault) {
  const { t } = useTranslation();
  return (
    <div className={styles.content}>
      <HeaderFilterPanel
        selectedFilter={selectedFilter}
        onBackStepFilter={onBackStepFilter}
        onResetAllCurrent={onResetAllCurrent}
        ghostButtonTitle={t('common.reset')}
      />
      <div className={styles.searchInputWrapper}>
        {NAME_FILTERS.CONNECTION !== selectedFilter && (
        <TextField
          value={search}
          name={NAME_INPUTS_DEFAULT.SEARCH}
          icon={<SearchIcon />}
          placeholder={t('valueChainMap.search')}
          onChange={({ target }) => setSearch(target.value)}
        />
        )}
      </div>
      <div className={styles.filters}>
        {filtersData && Array.isArray(filtersData[selectedFilter])
          ? searchFilters(filtersData[selectedFilter], search).map((filter) => (
            <div className={styles.filterWrapper} key={filter.uniqueIdentifier}>
              <Checkbox
                name={filter?.uniqueIdentifier ?? ''}
                checked={filter?.checked}
                value={String(filter?.checked)}
                onChange={onChange}
                label={(<span>{filter?.name}</span>)}
              />
            </div>
          )) : null}
      </div>
    </div>
  );
}

export default FilterListDefault;
