import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CONNECTION_TYPE_OPTIONS,
  TYPE_CONNECTION_FORM_OPTIONS,
  TYPE_OF_SERVICE_PROVIDER_OPTIONS,
} from 'constants/dataSets';
import {
  AIR,
  ARCTIC_TRUCK,
  COMMENT_LOGICAL,
  COMMENT_SERVICE,
  CONNECTION_TYPE,
  DISTANCE,
  ID_FROM,
  ID_LOGICAL,
  ID_SERVICE,
  ID_SUPPLIER,
  ID_TO_LOGICAL,
  ID_TO_SERVICE,
  ID_TO_SUPPLIER,
  MONETARY_VALUES,
  MONETARY_VOLUMES,
  OWNERSHIP_PERCENTAGE,
  QUANTITY,
  RAIL,
  RIGID_TRUCK,
  SEA,
  TRUCK,
  TYPE_OF_SERVICE_PROVIDER,
  UNIT_CURRENCY,
  UNIT_CURRENCY_VALUES,
  UNIT_MONETARY_VOLUMES,
  VAN,
} from 'constants/formFields';
import { connectionVCSchema, type TConnectionVCSchema } from 'utils/validators';

import type { Edge } from '@xyflow/react';
import type { TEnumData } from 'store/slices/dataSets/interfaces/TEnumData';
import type { IValueChainGoodsConnection } from 'store/slices/valueChainMap/interfaces/IValuChainGoodsConnection';
import type { IValueChainLogicalConnection } from 'store/slices/valueChainMap/interfaces/IValueChainLogicalConnection';
import type { IValueChainServiceConnection } from 'store/slices/valueChainMap/interfaces/IValueChainServiceConnection';

const useConnectionVCModal = ({ selectedEdge, enumData }: { selectedEdge: Edge[], enumData : TEnumData }) => {
  const logicalItem = selectedEdge.find(
    (edge) => edge?.data?.connectionType && typeof edge.data.connectionType === 'string'
      && edge.data.connectionType.includes(TYPE_CONNECTION_FORM_OPTIONS.logical),
  );
  const supplierItem = selectedEdge.find(
    (edge) => edge?.data?.connectionType && typeof edge.data.connectionType === 'string'
      && edge.data.connectionType.includes(TYPE_CONNECTION_FORM_OPTIONS.supplier),
  );
  const serviceItem = selectedEdge.find(
    (edge) => edge?.data?.connectionType && typeof edge.data.connectionType === 'string'
      && edge.data.connectionType.includes(TYPE_CONNECTION_FORM_OPTIONS.service),
  );

  const {
    handleSubmit,
    control,
    formState: { isDirty, isLoading, errors },
    getValues,
    setError,
    setValue,
    watch,
    reset,
  } = useForm<TConnectionVCSchema>({
    resolver: yupResolver(connectionVCSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      [CONNECTION_TYPE]: undefined,
      [COMMENT_LOGICAL]: '',
      [OWNERSHIP_PERCENTAGE]: '',
      [AIR]: false,
      [SEA]: false,
      [RAIL]: false,
      [VAN]: false,
      [TRUCK]: false,
      [RIGID_TRUCK]: false,
      [ARCTIC_TRUCK]: false,
      [DISTANCE]: '',
      [MONETARY_VOLUMES]: '',
      [UNIT_MONETARY_VOLUMES]: undefined,
      [QUANTITY]: '',
      [UNIT_CURRENCY]: undefined,
      [TYPE_OF_SERVICE_PROVIDER]: undefined,
      [COMMENT_SERVICE]: '',
      [MONETARY_VALUES]: '',
      [UNIT_CURRENCY_VALUES]: undefined,
      [ID_LOGICAL]: 0,
      [ID_SUPPLIER]: 0,
      [ID_SERVICE]: 0,
      [ID_TO_LOGICAL]: 0,
      [ID_TO_SUPPLIER]: 0,
      [ID_TO_SERVICE]: 0,
      [ID_FROM]: 0,
    },
  });

  useEffect(() => {
    if (selectedEdge.length > 0) {
      setValue(ID_FROM, Number(selectedEdge[0].source.split('_')[1]));
    }
  }, [selectedEdge, setValue]);

  useEffect(() => {
    if (logicalItem?.data?.connection) {
      const logicalConnection = logicalItem.data.connection as IValueChainLogicalConnection;
      setValue(COMMENT_LOGICAL, logicalConnection.comment ?? '');
      setValue(
        CONNECTION_TYPE,
        CONNECTION_TYPE_OPTIONS.find((option) => option.value === logicalConnection.connectionType)
         || null,
      );
      setValue(OWNERSHIP_PERCENTAGE, logicalConnection?.ownership ? `${logicalConnection?.ownership}` : '');
      setValue(ID_LOGICAL, logicalConnection.id);
      setValue(ID_TO_LOGICAL, logicalConnection.toId);
    }
  }, [logicalItem, setValue]);

  useEffect(() => {
    if (supplierItem?.data?.connection) {
      const supplierConnection = supplierItem.data.connection as IValueChainGoodsConnection;
      setValue(AIR, supplierConnection.transportMethods?.includes(AIR));
      setValue(SEA, supplierConnection.transportMethods?.includes(SEA));
      setValue(RAIL, supplierConnection.transportMethods?.includes(RAIL));
      setValue(VAN, supplierConnection.transportMethods?.includes(VAN));
      setValue(TRUCK, supplierConnection.transportMethods?.includes(TRUCK));
      setValue(RIGID_TRUCK, supplierConnection.transportMethods?.includes(RIGID_TRUCK));
      setValue(ARCTIC_TRUCK, supplierConnection.transportMethods?.includes(ARCTIC_TRUCK));
      setValue(DISTANCE, supplierConnection.distance ?? '');
      setValue(MONETARY_VOLUMES, supplierConnection?.quantity ? `${supplierConnection?.quantity}` : '');
      setValue(
        UNIT_MONETARY_VOLUMES,
        enumData?.currency?.find((
          option,
        ) => option?.value === supplierConnection.currency)
      || {},
      );
      setValue(QUANTITY, supplierConnection.monetaryValue ? `${supplierConnection.monetaryValue}` : '');
      setValue(
        UNIT_CURRENCY,
        enumData?.volumeQtyUnit?.find((
          option,
        ) => option?.value === supplierConnection.quantityUnit)
      || {},
      );
      setValue(ID_SUPPLIER, supplierConnection.id);
      setValue(ID_TO_SUPPLIER, supplierConnection.toId);
    }
  }, [supplierItem, setValue, enumData]);

  useEffect(() => {
    if (serviceItem?.data?.connection) {
      const serviceConnection = serviceItem.data.connection as IValueChainServiceConnection;
      setValue(COMMENT_SERVICE, serviceConnection.comment || '');
      setValue(
        TYPE_OF_SERVICE_PROVIDER,
        TYPE_OF_SERVICE_PROVIDER_OPTIONS.find((option) => option.value === serviceConnection.serviceProviderType)
         || null,
      );
      setValue(MONETARY_VALUES, serviceConnection?.monetaryValue ? `${serviceConnection?.monetaryValue}` : '');
      setValue(
        UNIT_CURRENCY_VALUES,
        enumData?.currency?.find((
          option,
        ) => option?.value === serviceConnection.monetaryCurrency)
      || {},
      );
      setValue(ID_SERVICE, serviceConnection.id);
      setValue(ID_TO_SERVICE, serviceConnection.toId);
    }
  }, [serviceItem, setValue, enumData]);

  const onResetSelectedFields = useCallback((fields: Array<keyof TConnectionVCSchema>) => {
    fields.forEach((field) => {
      const currentValue = getValues(field);
      let defaultValue;

      if (typeof currentValue === 'boolean') {
        defaultValue = false;
      } else if (typeof currentValue === 'string') {
        defaultValue = '';
      } else if (typeof currentValue === 'object') {
        defaultValue = undefined;
      } else {
        defaultValue = undefined;
      }

      setValue(field, defaultValue);
    });
  }, [getValues, setValue]);

  return {
    control,
    handleSubmit,
    isDirty,
    isLoading,
    getValues,
    setError,
    setValue,
    watch,
    reset,
    errors,
    onResetSelectedFields,
  };
};

export default useConnectionVCModal;
