import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Backdrop from 'components/Modal/Backdrop';
import { BUTTON_VARIANT } from 'constants/interfaces';

import type { IDeleteRestrictedModal } from './interfaces/IDeleteRestrictedModal';

import styles from './scss/DeleteRestrictedModal.module.scss';

function DeleteRestrictedModal({ isOpen, onClose }: IDeleteRestrictedModal) {
  const { t } = useTranslation();

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  return isOpen ? createPortal(
    <Backdrop>
      <div
        className={styles.modalOverlay}
      >
        <div
          className={styles.modalContent}
        >
          <h2 id="modal-title" className={styles.modalTitle}>
            {t('common.deletionRestricted')}
          </h2>
          <div className={styles.modalBody}>
            <p>{t('common.deletionRestrictedDescription')}</p>
            <p>{t('common.deletionRestrictedDescriptionBottom')}</p>
          </div>

          <div className={styles.modalFooter}>
            <Button
              title={t('common.cancel')}
              variant={BUTTON_VARIANT.cancel}
              onClick={onClose}
            />
          </div>

        </div>
      </div>
    </Backdrop>,
    document.body,
  ) : null;
}

export default DeleteRestrictedModal;
