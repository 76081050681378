import {
  CODE,
  CRITICALITY_FROM_DOWNSTREAM_WASTE,
  CRITICALITY_RATING,
  DATE_CLOSED,
  DATE_OPENED, END_DATE,
  EQUITY_CONTROL,
  EXPECTED_GROWTH_DECLINE,
  KEY_SUPPLIER_TIER_1_TOTAL,
  KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY,
  KEY_SUPPLIER_TIER_10_OF_TOTAL,
  NAME,
  PROFIT_CENTER_ID,
  RELIANCE_ON_FREE_NATURAL_RESOURCES,
  RELIANCE_ON_FREE_SOCIAL_RESOURCES,
  START_DATE, SUPPLIER,
  SUPPLIER_ID, UNIQUE_IDENTIFIER, UPSTREAM_TITLE,
  VOLUME_QTY, VOLUME_QTY_UNIT,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT, DATE_FORMAT_ISO } from 'constants/general';
import dayjs from 'dayjs';

import type { IGetRequestDataForProduct } from './interfaces/IGetRequestDataForProduct';

function getRequestDataForProduct({ values, recordId }: IGetRequestDataForProduct) {
  return {
    [NAME]: values?.[NAME] || null,
    [CODE]: values?.[CODE] || null,
    [UPSTREAM_TITLE]: values?.[UPSTREAM_TITLE]?.value || null,
    [PROFIT_CENTER_ID]: values?.[PROFIT_CENTER_ID]?.map((item) => item.value) || null,
    [SUPPLIER_ID]: values?.[SUPPLIER]?.length ? values?.[SUPPLIER].map(({ value }) => value) : null,
    [START_DATE]: values?.[START_DATE] ? dayjs(values?.[START_DATE], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [END_DATE]: values?.[END_DATE] ? dayjs(values?.[END_DATE], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [DATE_OPENED]: values?.[DATE_OPENED] ? dayjs(values?.[DATE_OPENED], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [DATE_CLOSED]: values?.[DATE_CLOSED] ? dayjs(values?.[DATE_CLOSED], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [VOLUME_QTY]: values?.[VOLUME_QTY] ? Number(values?.[VOLUME_QTY]) : null,
    [VOLUME_QTY_UNIT]: values?.[VOLUME_QTY_UNIT]?.value || null,
    [EQUITY_CONTROL]: values?.[EQUITY_CONTROL] ? Number(values?.[EQUITY_CONTROL]) : null,
    [CRITICALITY_FROM_DOWNSTREAM_WASTE]: values?.[CRITICALITY_FROM_DOWNSTREAM_WASTE]
      ? Number(values?.[CRITICALITY_FROM_DOWNSTREAM_WASTE]) : null,
    [EXPECTED_GROWTH_DECLINE]: values?.[EXPECTED_GROWTH_DECLINE] ? Number(values?.[EXPECTED_GROWTH_DECLINE]) : null,
    [RELIANCE_ON_FREE_NATURAL_RESOURCES]:
        (values?.[RELIANCE_ON_FREE_NATURAL_RESOURCES]?.length && values?.[RELIANCE_ON_FREE_NATURAL_RESOURCES]?.map(
          (item) => item.value,
        )) || null,
    [RELIANCE_ON_FREE_SOCIAL_RESOURCES]:
        (values?.[RELIANCE_ON_FREE_SOCIAL_RESOURCES]?.length && values?.[RELIANCE_ON_FREE_SOCIAL_RESOURCES]?.map(
          (item) => item.value,
        )) || null,
    [KEY_SUPPLIER_TIER_1_TOTAL]: values?.[KEY_SUPPLIER_TIER_1_TOTAL] ? {
      amount: Number(values?.[KEY_SUPPLIER_TIER_1_TOTAL]),
      currency: values?.[KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY]?.value || '',
    } : null,
    [KEY_SUPPLIER_TIER_10_OF_TOTAL]: values[KEY_SUPPLIER_TIER_10_OF_TOTAL]
      ? Number(values[KEY_SUPPLIER_TIER_10_OF_TOTAL]) : null,
    [CRITICALITY_RATING]: values?.[CRITICALITY_RATING]?.value || null,
    ...(!recordId && { [UNIQUE_IDENTIFIER]: values?.[UNIQUE_IDENTIFIER] }),
    ...(recordId && { id: Number(recordId) }),
  };
}

export default getRequestDataForProduct;
