import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SwimlaneIcon } from 'assets/icons/box-select.svg';
import { ReactComponent as OwnOperationIcon } from 'assets/icons/factory.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as InputProductIcon } from 'assets/icons/product.svg';
import { ReactComponent as ProfitCenterIcon } from 'assets/icons/profitCenter.svg';
import { ReactComponent as CustomerIcon } from 'assets/icons/user-check.svg';
import { ReactComponent as SupplierIcon } from 'assets/icons/warehouse.svg';
import clsx from 'clsx';

import { VALUE_CHAIN_PANEL_NODES_TYPES } from '../../../../../../constants/interfaces';
import { useDnD } from './DndContext';

import type { TValueChainPanelNodesType } from '../../../../../../constants/interfaces';
import type { IRightPanel } from '../../interfaces/IRightPanel';

import styles from '../sass/RightPanel.module.scss';

function RightPanel({ isAccessDisabled, onClickFilters, selectedFilter }: IRightPanel) {
  const { t } = useTranslation();
  const [, setPanelType] = useDnD();

  const onDragStart = (event: React.DragEvent<HTMLLIElement>, nodeType: TValueChainPanelNodesType) => {
    if (setPanelType) {
      setPanelType(nodeType);
    }
    const { dataTransfer } = event;
    dataTransfer.effectAllowed = 'move';
  };
  let ACTIONS = [{
    id: VALUE_CHAIN_PANEL_NODES_TYPES.FILTER,
    label: t('valueChainMap.filter'),
    onPress: onClickFilters,
    icon: <FilterIcon />,
  }, {
    id: VALUE_CHAIN_PANEL_NODES_TYPES.SWIMLANE,
    label: t('valueChainMap.swimlane'),
    onPress: () => {},
    onDragStart: (event: React.DragEvent<HTMLLIElement>) => onDragStart(event, VALUE_CHAIN_PANEL_NODES_TYPES.SWIMLANE),
    icon: <SwimlaneIcon />,
  }, {
    id: VALUE_CHAIN_PANEL_NODES_TYPES.OWN_OPERATION,
    label: t('valueChainMap.ownOperation'),
    onPress: () => {},
    onDragStart: (event: React.DragEvent<HTMLLIElement>) => {
      onDragStart(event, VALUE_CHAIN_PANEL_NODES_TYPES.OWN_OPERATION);
    },
    icon: <OwnOperationIcon width={24} height={24} />,
  }, {
    id: VALUE_CHAIN_PANEL_NODES_TYPES.PROFIT_CENTER,
    label: t('valueChainMap.profitCenter'),
    onPress: () => {},
    onDragStart: (event: React.DragEvent<HTMLLIElement>) => {
      onDragStart(event, VALUE_CHAIN_PANEL_NODES_TYPES.PROFIT_CENTER);
    },
    icon: <ProfitCenterIcon width={24} height={24} />,
  }, {
    id: VALUE_CHAIN_PANEL_NODES_TYPES.INPUT_PRODUCT,
    label: t('valueChainMap.inputProduct'),
    onPress: () => {},
    onDragStart: (event: React.DragEvent<HTMLLIElement>) => {
      onDragStart(event, VALUE_CHAIN_PANEL_NODES_TYPES.INPUT_PRODUCT);
    },
    icon: <InputProductIcon />,
  }, {
    id: VALUE_CHAIN_PANEL_NODES_TYPES.SUPPLIER,
    label: t('valueChainMap.supplier'),
    onPress: () => {},
    onDragStart: (event: React.DragEvent<HTMLLIElement>) => {
      onDragStart(event, VALUE_CHAIN_PANEL_NODES_TYPES.SUPPLIER);
    },
    icon: <SupplierIcon />,
  }, {
    id: VALUE_CHAIN_PANEL_NODES_TYPES.CUSTOMER,
    label: t('valueChainMap.customer'),
    onPress: () => {},
    onDragStart: (event: React.DragEvent<HTMLLIElement>) => {
      onDragStart(event, VALUE_CHAIN_PANEL_NODES_TYPES.CUSTOMER);
    },
    icon: <CustomerIcon />,
  }];

  if (isAccessDisabled) {
    ACTIONS = ACTIONS.filter((action) => action.id === VALUE_CHAIN_PANEL_NODES_TYPES.FILTER);
  }

  return (
    <ul className={clsx(styles.container, {
      [styles.aloneElement]: isAccessDisabled,
    })}
    >
      {ACTIONS.map(({
        id, icon, label, onPress,
      }) => (
        <li
          key={`actions_${id}`}
          onDragStart={(event) => onDragStart(event, id)}
          onDragEnd={() => setPanelType('')}
          draggable={id !== VALUE_CHAIN_PANEL_NODES_TYPES.FILTER}
        >
          <button
            key={id}
            onClick={onPress}
            type="button"
            className={styles.actionWrapper}
          >
            {icon}
            <p>{label}</p>
          </button>
          {selectedFilter ? <div className={styles.redDot} /> : null}
        </li>
      ))}
    </ul>
  );
}

export default RightPanel;
