import { useEffect } from 'react';
import { clsx } from 'clsx';

import type { IFullScreen } from '../../hooks/interfaces/IFullScreen';

import styles from './sass/FullScreen.module.scss';

function FullScreen({
  handle,
  onChange,
  children,
  className,
}: IFullScreen) {
  const fullScreenClasses = ['fullscreen', className, handle.active ? 'fullscreen-enabled' : ''].filter(Boolean);

  useEffect(() => {
    if (onChange) {
      onChange(handle.active, handle);
    }
  }, [handle, handle.active, onChange]);

  return (
    <div className={clsx(fullScreenClasses.join(' '), {
      [styles.active]: handle.active,
    })}
    >
      {children}
    </div>
  );
}

export default FullScreen;
