export default {
  pageTitle: 'Value Chain Map',
  addNewValueChainMap: 'Add a new value chain map',
  createNewValueChainMap: 'Create new value chain map',
  searchByName: 'Search by name',
  filterByStatus: 'Filter by status',
  thereAreNoValueChanMaps: 'There are no Value Chain Maps yet',
  name: 'Name',
  createdDate: 'Created',
  lastModifiedAt: 'Last modified',
  status: 'Status',
  action: 'Actions',
  current: 'Current',
  inProgress: 'In progress',
  underReview: 'Under review',
  readyForReview: 'Ready for review',
  requestApproval: 'Request approval',
  requiresRework: 'Requires re-work',
  archived: 'Archived',
  edit: 'Edit',
  delete: 'Delete',
  copyOf: 'Copy of',
  rename: 'Rename',
  dublicate: 'Duplicate',
  archive: 'Archive',
  restore: 'Restore',
  processing: 'Processing...',
  actualRevenue: 'actual revenue (rolling 12 months): ',
  actualGrossMargin: 'actual gross margin (rolling 12 months): ',
  wipFinishedGoodsStock: 'WIP & Finished Goods Stock:',
  profitCentres: 'Profit Centres: ',
  Subsidiary: 'Subsidiary',
  JV: 'JV',
  AssociateSubsidiary: 'Associate Subsidiary',
  BusinessUnit: 'Business unit',
  Division: 'Division',
  businessStructure: 'Business Structure',
  valueChain: 'Value Chain',
  saved: 'Saved',
  showLog: 'Show change log',
  clickToRename: 'Click to rename',
  profitCenter: 'Profit Centre',
  profitCenterId: 'Profit Centre ID:',
  netBookValue: 'Allocated Net Book Value:',
  disablingReasonTitle: 'Specify reason for disabling',
  enablingReasonTitle: 'Specify reason for enabling',
  disablingReasonDescription: 'Please provide a reason for disabling the selected branch.',
  enablingReasonDescription: 'Please provide a reason for enabling the selected branch.',
  disabledBranchDetails: 'Disabled branch details',
  addProfitCentreToTheStructure: 'Add Profit Centre to the Business Structure',
  createNewProfitCentre: 'Create new profit centre',
  createNewOwnOperation: 'Create new own operation',
  addNewProfitCenter: 'Add new profit centre',
  addNewOwnOperation: 'Add new own operation',
  orSelectFromExistingProfitCentres:
    'OR Select from existing profit centres (unlinked or linked to physical own operation)',
  orSelectFromExistingOwnOperations: 'OR Select from existing own operations (logical)',
  addOperationToProfitCenter: 'Link Profit Centre to Own Operation',
  noResults: 'No results matching the defined criteria',
  connectionTypes: 'Connection Types',
  logical: 'Logical',
  financial: 'Financial',
  supplierOfGoods: 'Supplier of Goods',
  service: 'Service',
  swimlane: 'Swimlane',
  filter: 'Filter',
  ownOperation: 'Own Operation',
  inputProduct: 'Input Product',
  supplier: 'Supplier',
  customer: 'Customer',
  estimated: 'Estimated',
  industryNace: 'Industry (NACE):',
  country: 'Country of Operation / Incorporation:',
  bookValue: 'Net Book Value:',
  assetCategory: 'Asset Category:',
  keySupplierTier1Total: 'Key Suppliers Tier 1 Total cost of sales spend (rolling 12 months):',
  relianceOnFreeNaturalResources: 'Reliance on \'free\' natural resources:',
  criticalityRating: 'Criticality Rating:',
  rawMaterialWipFinishedGoodsStock: 'Raw materials/WIP/Finished Goods Stock:',
  approved: 'Approved',
  connection: 'Connection',
  addConnection: 'Add Connection',
  removeConnection: 'Remove connection',
  connectionType: 'Connection Type',
  comment: 'Comment',
  ownershipPercentage: 'Ownership %',
  air: 'Air',
  sea: 'Sea',
  rail: 'Rail',
  van: 'Van',
  truck: 'Truck',
  rigidTruck: 'Rigid truck',
  arcticTruck: 'Arctic truck',
  distance: 'Distance',
  monetaryVolumes: 'Monetary volumes',
  unitMonetaryVolumes: 'Unit Monetary Volumes',
  quantity: 'Quantity',
  monetaryValues: 'Monetary values',
  typeOfServiceProvider: 'Type of Service Provider',
  unitCurrency: 'Unit Currency',
  minCharactersLength: 'Min Characters Length',
  maxCharactersLength: 'Max Characters Length',
  transportMethod: 'Transport Method',
  currency: 'Currency',
  unit: 'Unit',
  approve: 'Approve',
  returnForReWork: 'Return for re-work',
  reWorkReason: 'Please add a comment why this VC map requires re-work.',
  search: 'Search',
  organizational: 'Organizational',
  geographical: 'Geographical',
};
