import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import Button from '../../../../components/Button/Button';
import IconButton from '../../../../components/IconButton/IconButton';
import Select from '../../../../components/Select/Select';
import TextField from '../../../../components/TextField/TextField';
import { UID_NAME_TUPLES } from '../../../../constants/dataSets';
import { SUPPLIER } from '../../../../constants/formFields';

import type IFieldError from '../../../../store/types/IFieldError';
import type ISupplierField from '../interfaces/ISupplierField';

import styles from '../sass/SupplierField.module.scss';

function SupplierField({ control, uidNameTuples }: ISupplierField) {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: SUPPLIER,
  });

  const handleAddSupplier = () => {
    append({ label: '', value: '' });
  };

  return (
    <div className={styles.listWrapper}>
      {fields.map((field, index) => (
        <div key={field.id} className={styles.listItem}>
          <Controller
            control={control}
            name={`${SUPPLIER}.${index}`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <Select
                  label={t('products.fields.supplier')}
                  placeholder={t('products.fields.supplier')}
                  options={uidNameTuples?.[UID_NAME_TUPLES.supplier]}
                  value={value || undefined}
                  onChange={onChange}
                  error={(error as IFieldError)?.value?.message}
                  required={index === 0}
                  className={styles.select}
                  isSearchable
                />
                <div className={styles.selectId}>
                  <TextField
                    name="supplierId"
                    value={value.value}
                    label={t('products.fields.supplierId')}
                    error={error?.message}
                    onChange={(event) => onChange(event.target.value)}
                    afterAddon={(fields.length > 1 && index > 0) ? (
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => remove(index)}
                        className={styles.deleteButton}
                      />
                    ) : null}
                    disabled
                    required
                  />
                </div>
              </>
            )}
          />
        </div>
      ))}
      <Button
        title={t('products.fields.addSupplier')}
        onClick={handleAddSupplier}
        variant="ghost"
        startIcon={<PlusIcon />}
      />
    </div>
  );
}

export default SupplierField;
