import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetProfitCenterDataQuery } from 'store/slices/valueChainMap/apis/valueChainMapApi';
import getProfitCentreDefaultValues from 'utils/formHelpers/getProfitCentreDefaultValues';
import { createProfitCentreSchema } from 'utils/validators';

import type { TCreateProfitCentreSchema } from 'utils/validators';
import type ICreateProfitCentreForm from '../interfaces/ICreateProfitCentreForm';

const useCreateProfitCentreForm = ({ recordId }: ICreateProfitCentreForm) => {
  const { data, isFetching } = useGetProfitCenterDataQuery(
    recordId ? { chainId: `${recordId}` } : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty, isLoading },
    reset,
    setError,
    getValues,
    watch,
    setValue,
  } = useForm<TCreateProfitCentreSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(createProfitCentreSchema),
    values: getProfitCentreDefaultValues({ values: data?.data }),
  });

  const fieldsName = Object.keys(getValues());

  const handleReset = () => {
    reset(getProfitCentreDefaultValues({}));
  };

  useEffect(() => {
    if (data?.data && isFetching) {
      reset(getProfitCentreDefaultValues({ values: data?.data }));
    }
  }, [data, isFetching, reset]);

  return {
    control,
    handleSubmit,
    isDirty,
    reset: handleReset,
    isLoading,
    setError,
    fieldsName,
    watch,
    setValue,
  };
};

export default useCreateProfitCentreForm;
