import { VALUE_CHAIN_PANEL_NODES_TYPES } from '../constants/interfaces';
import { getTargetedBoard } from './reactFlowHelpers';

import type { IOnDragOverByType } from './interfaces/IOnDragOverByType';
import type { IOnDragOverSwimlane } from './interfaces/IOnDragOverSwimlane';

// Swimlane drag over handler: set opacity to 0.6 when the node is dragged over the target group node
const onDragOverSwimlane = ({
  nodes, screenToFlowPosition, event, setNodes,
}: IOnDragOverSwimlane) => {
  const dragPosition = screenToFlowPosition({
    x: event.clientX,
    y: event.clientY,
  });

  getTargetedBoard(dragPosition, nodes);

  setNodes((prevNodes) => prevNodes.map((prevNode) => (
    { ...prevNode, data: { ...prevNode.data, isDragTarget: false } }
  )));

  const targetedBoard = getTargetedBoard(dragPosition, nodes);
  if (targetedBoard) {
    setNodes((prevNodes) => prevNodes.map((prevNode) => (prevNode.id === targetedBoard.id
      ? { ...prevNode, data: { ...prevNode.data, isDragTarget: true } }
      : prevNode)));
  }
};

const onDragOverByType = ({
  event, panelType, nodes, setNodes, screenToFlowPosition,
}: IOnDragOverByType) => {
  event.preventDefault();
  const { dataTransfer } = event;
  dataTransfer.dropEffect = 'move';

  switch (panelType) {
    case VALUE_CHAIN_PANEL_NODES_TYPES.SWIMLANE:
      onDragOverSwimlane({
        event, nodes, setNodes, screenToFlowPosition,
      });
      // add new cases when it will be available on backend
      break;
    default:
  }
};

export default onDragOverByType;
