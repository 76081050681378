import i18n from '../i18n';
import { VALUE_CHAIN_STATUS } from './interfaces';

import type { TValueChainStatus } from './interfaces';

type TValueChainActionStatusItem = {
  text: string,
};

export const VALUE_CHAIN_PROCESSING_CHECK_INTERVAL = 10000;

export const VALUE_CHAIN_HEAD_CELLS = [
  {
    id: 'name',
    label: 'valueChainMap.name',
    withSort: true,
  },
  {
    id: 'createdAt',
    label: 'valueChainMap.createdDate',
    withSort: true,
  },
  {
    id: 'updatedAt',
    label: 'valueChainMap.lastModifiedAt',
    withSort: true,
  },
  {
    id: 'status',
    label: 'valueChainMap.status',
    withSort: false,
  },
  {
    id: 'actions',
    label: 'valueChainMap.action',
    withSort: false,
  },
];

export const VALUE_CHAIN_STATUSES: Record<TValueChainStatus, TValueChainActionStatusItem> = {
  [VALUE_CHAIN_STATUS.current]: {
    text: i18n.t('valueChainMap.current'),
  },
  [VALUE_CHAIN_STATUS.inProgress]: {
    text: i18n.t('valueChainMap.inProgress'),
  },
  [VALUE_CHAIN_STATUS.readyForReview]: {
    text: i18n.t('valueChainMap.readyForReview'),
  },
  [VALUE_CHAIN_STATUS.underReview]: {
    text: i18n.t('valueChainMap.underReview'),
  },
  [VALUE_CHAIN_STATUS.requestApproval]: {
    text: i18n.t('valueChainMap.requestApproval'),
  },
  [VALUE_CHAIN_STATUS.approved]: {
    text: i18n.t('valueChainMap.approved'),
  },
  [VALUE_CHAIN_STATUS.requiresRework]: {
    text: i18n.t('valueChainMap.requiresRework'),
  },
  [VALUE_CHAIN_STATUS.archived]: {
    text: i18n.t('valueChainMap.archived'),
  },
  [VALUE_CHAIN_STATUS.processing]: {
    text: i18n.t('valueChainMap.processing'),
  },
};

export const STEPS_PROFIT_CENTER_MODAL = {
  SELECT_PROFIT_CENTER: 'selectProfitCenter',
  CREATE_PROFIT_CENTER: 'createProfitCenter',
  SELECT_OWN_OPERATION: 'selectOwnOperation',
  CREATE_OWN_OPERATION: 'createOwnOperation',
} as const;

export const VALUE_TABS_OWNER = {
  ORGANIZATIONAL: 'organizational',
  GEOGRAPHICAL: 'geographical',
  ORG_PATHS: 'orgPaths',
  GEO_PATHS: 'geoPaths',
};
