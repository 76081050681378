import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SupplierIcon } from 'assets/icons/warehouse.svg';

import { formatRevenueAndPercentage } from '../../../../../../utils/helpers';
import CustomNodeItem from '../../BusinessStructure/components/CustomNodeItem';

import type { ISupplierNode } from '../interfaces/ISupplierNode';

import styles from '../sass/CustomUnitNode.module.scss';

function SupplierNode({
  name, profitCenterId, keySupplierTier1Total, naceSection, addressCountry, keySupplierTier1OfTotal,
}: ISupplierNode) {
  const { t } = useTranslation();

  const customNodeItems = [
    {
      title: t('valueChainMap.profitCenterId'),
      value: profitCenterId?.join(', ') || '-',
    },
    {
      title: t('valueChainMap.industryNace'),
      value: naceSection || '-',
      isCapitalize: true,
    },
    {
      title: t('valueChainMap.country'),
      value: addressCountry || '-',
      isCapitalize: true,
    },
    {
      title: t('valueChainMap.keySupplierTier1Total'),
      value: formatRevenueAndPercentage({
        actual: keySupplierTier1Total?.amount,
        currency: keySupplierTier1Total?.currency,
        percentage: keySupplierTier1OfTotal?.toString(),
      }),
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <SupplierIcon />
        <p className={styles.title}>{name}</p>
      </div>
      <div className={styles.content}>
        {customNodeItems.map(({ title, value, isCapitalize }) => (
          <CustomNodeItem
            key={`customNodeItem_${title}`}
            title={title}
            value={value}
            isCapitalize={isCapitalize}
          />
        ))}
      </div>
    </>
  );
}

export default SupplierNode;
