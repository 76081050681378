import { useEffect, useRef, useState } from 'react';

import CheckboxItem from './components/CheckboxItem';

import type ICheckboxParent from './interfaces/ICheckboxParent';
import type IChildHeights from './interfaces/IChildHeights';
import type IIsExpandedChildren from './interfaces/IIsExpandedChildren';

import styles from './sass/CheckboxParent.module.scss';

function CheckboxParent({
  name, checked, onChange, label, type, items, hideChevron,
}: ICheckboxParent) {
  const contentRef = useRef<HTMLDivElement>(null);
  const childrenRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const observersRef = useRef<{ [key: string]: ResizeObserver }>({});
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [childHeights, setChildHeights] = useState<IChildHeights>({});
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isExpandedChildren, setIsExpandedChildren] = useState<IIsExpandedChildren | undefined>(undefined);

  useEffect(() => () => {
    Object.values(observersRef.current).forEach((observer) => observer.disconnect());
    return undefined;
  }, []);

  const toggleAccordion = () => {
    setIsExpanded((prev) => !prev);
  };

  const toggleAccordionChildren = (nameFilter: string) => {
    setIsExpandedChildren((prev) => ({
      ...(prev || {}),
      [nameFilter]: !prev?.[nameFilter],
    }));
  };

  const recalculateContentHeight = () => {
    if (contentRef.current) {
      const totalHeight = Array.from(contentRef.current.querySelectorAll('*'))
        .reduce((acc, el) => Math.max(acc, el.getBoundingClientRect().bottom), 0)
        - contentRef.current.getBoundingClientRect().top;
      setContentHeight(Math.max(contentRef.current.scrollHeight, totalHeight));
    }
  };

  const showSquareForChildren = (item: any) => {
    if (!item.children || item.children.length === 0) {
      return false;
    }
    if (item.children.length === 1) {
      return false;
    }
    const checkedCount = item.children.filter((child: any) => child.checked).length;
    return checkedCount > 0 && checkedCount < item.children.length;
  };

  const showSquare = () => {
    if (!items || items.length === 0) {
      return false;
    }
    const checkedCount = items.filter((item) => item.checked).length;
    return checkedCount > 0 && checkedCount < items.length;
  };

  useEffect(() => {
    if (contentRef.current && isExpanded) {
      const observer = new ResizeObserver(recalculateContentHeight);
      observer.observe(contentRef.current);
      return () => observer.disconnect();
    }
    return undefined;
  }, [isExpanded]);

  useEffect(() => {
    if (contentRef.current) {
      if (isExpanded) {
        setContentHeight(contentRef.current.scrollHeight);
        setTimeout(recalculateContentHeight, 50);
      } else {
        setContentHeight(0);
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    if (isExpandedChildren && items) {
      Object.values(observersRef.current).forEach((observer) => observer.disconnect());
      observersRef.current = {};

      const newHeights: IChildHeights = {};

      items.forEach((item) => {
        const childRef = childrenRefs.current[item.name];
        if (childRef && isExpandedChildren[item.name]) {
          newHeights[item.name] = childRef.scrollHeight;

          const observer = new ResizeObserver(() => {
            setChildHeights((prev) => ({
              ...prev,
              [item.name]: childRef.scrollHeight,
            }));
            setTimeout(recalculateContentHeight, 50);
          });
          observer.observe(childRef);
          observersRef.current[item.name] = observer;
        } else if (childRef) {
          newHeights[item.name] = 0;
        }
      });
      setChildHeights((prev) => ({ ...prev, ...newHeights }));
      if (isExpanded) {
        setTimeout(recalculateContentHeight, 100);
      }
    }
  }, [isExpandedChildren, isExpanded, items]);

  useEffect(() => {
    if (items && items.length > 0) {
      const initialExpandedState = items.reduce((acc, item) => ({
        ...acc,
        [item.name]: false,
      }), {});
      setIsExpandedChildren(initialExpandedState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <CheckboxItem
        name={name}
        checked={checked}
        onChange={onChange}
        label={label}
        type={type}
        showSquare={showSquare()}
        isExpanded={isExpanded}
        toggleAccordion={toggleAccordion}
        hideChevron={hideChevron}
      />
      <div className={styles.accordion}>
        <div
          id="accordion-content"
          ref={contentRef}
          className={styles.content}
          style={{
            height: isExpanded ? `${contentHeight}px` : '0',
            transition: 'height 0.3s ease-in-out',
          }}
          aria-hidden={!isExpanded}
        >
          <div className={styles.innerContent}>
            <div className={styles.contentWrapper}>
              {items && isExpandedChildren && items?.length > 0 ? items.map((item) => (
                <div
                  key={item.name}
                >
                  <CheckboxItem
                    name={item.name}
                    checked={item.checked}
                    onChange={onChange}
                    label={item.name}
                    type={type}
                    isExpanded={isExpandedChildren[item.name]}
                    toggleAccordion={() => {
                      toggleAccordionChildren(item.name);
                    }}
                    hideChevron={item?.children.length <= 0}
                    showSquare={showSquareForChildren(item)}
                    icon={item?.assetCategory ? item?.assetCategory[0] : undefined}
                  />
                  <div
                    ref={(el) => { childrenRefs.current[item.name] = el; }}
                    className={styles.content}
                    style={{
                      height: isExpandedChildren[item.name] ? `${childHeights[item.name] || 0}px` : '0',
                      opacity: isExpandedChildren[item.name] ? 1 : 0,
                      transition: 'height 0.4s ease-in-out, opacity 0.4s ease-in-out',
                    }}
                    aria-hidden={!isExpandedChildren[item.name]}
                  >
                    <div className={styles.innerContent}>
                      <div className={styles.contentWrapper}>
                        {item?.children && item?.children.length > 0 ? item?.children.map((child) => (
                          <CheckboxItem
                            key={child.name}
                            name={child.name}
                            checked={child.checked}
                            onChange={onChange}
                            label={child.name}
                            type={type}
                            hideChevron={child?.children.length <= 0}
                          />
                        )) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckboxParent;
