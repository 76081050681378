import { useCallback } from 'react';
import { REACT_FLOW } from 'constants/general';

import type { Edge, Node } from '@xyflow/react';
import type { IProfitCenter } from 'store/slices/valueChainMap/interfaces/IProfitCenter';
import type { IProfitCenterRequestItem } from 'store/slices/valueChainMap/interfaces/IProfitCenterRequestItem';
import type {
  IGetValueChainMapBusinessModelResponse,
} from '../store/slices/valueChainMap/interfaces/IGetValueChainMapBusinessModelResponse';

const useCreateNodesAndEdgesBusinessStructure = (
  onActiveStatusCheck: (item: IProfitCenter) => void,
  onDisabledInfoPress: (item: IProfitCenter) => void,
  isAccessDisabled: boolean,
) => {
  const createNodesAndEdges = useCallback((dataT: IGetValueChainMapBusinessModelResponse):
  { nodes: Node[], edges: Edge[] } => {
    const nodesT: Node[] = [];
    const edgesT: Edge[] = [];

    const pushNewNode = (profitCenter: IProfitCenter, xPosition: number, yPosition: number) => {
      nodesT.push({
        id: `${profitCenter.id}`,
        type: 'custom',
        data: {
          ...profitCenter,
          onActiveStatusCheck,
          onDisabledInfoPress,
          isAccessDisabled,
        },
        position: { x: xPosition, y: yPosition },
      });
    };

    const pushNewEdge = (sourceId: string, targetId: string) => {
      edgesT.push({
        id: `e-${sourceId}-${targetId}`,
        source: sourceId,
        target: targetId,
      });
    };

    const processChildEdges = (
      profitCenter: IProfitCenter,
      children: IProfitCenterRequestItem[],
      isParentGroup: boolean,
      lastParentOfGroup?: IProfitCenter,
    ) => {
      children.forEach((child) => {
        child.parent.forEach((childParent) => {
          if (child.parent.length > 1) {
            const firstParent = child.parent[0];
            if (childParent.id === firstParent.id) {
              pushNewEdge(`${profitCenter.id}`, `${firstParent.id}`);
            }
          } else if (isParentGroup) {
            if (lastParentOfGroup?.id === profitCenter.id) {
              pushNewEdge(`${lastParentOfGroup.id}`, `${childParent.id}`);
            }
          } else {
            pushNewEdge(`${profitCenter.id}`, `${childParent.id}`);
          }
        });
      });
    };

    const traverseTree = (items: IProfitCenterRequestItem[], currentLevel: number): number[] => {
      const childPositions: number[] = [];

      items.forEach((item) => {
        const children = item.children || [];
        const childrenXPositions = traverseTree(children, currentLevel + 1);

        item.parent.forEach((profitCenter, index) => {
          const isParentGroup = item.parent.length > 1;
          const lastParentOfGroup = isParentGroup ? item.parent[item.parent.length - 1] : undefined;

          const spacing = isParentGroup ? 1 : REACT_FLOW.DEFAULT_SPACE_BETWEEN_NODES;
          const xPosition = childrenXPositions.length > 0
            ? (Math.min(...childrenXPositions) + Math.max(...childrenXPositions)) / 2
            : nodesT.length * spacing;

          const yPosition = currentLevel * REACT_FLOW.DEFAULT_SPACE_BETWEEN_NODES
            + index * REACT_FLOW.PROFIT_CENTER_HEADER_HEIGHT;

          pushNewNode(profitCenter, xPosition, yPosition);
          childPositions.push(xPosition);

          processChildEdges(profitCenter, children, isParentGroup, lastParentOfGroup);
        });
      });

      return childPositions;
    };

    traverseTree(dataT.data, 1);
    return { nodes: nodesT, edges: edgesT };
  }, [onActiveStatusCheck, onDisabledInfoPress, isAccessDisabled]);

  return { createNodesAndEdges };
};

export default useCreateNodesAndEdgesBusinessStructure;
