import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES } from 'constants/dataSets';
import {
  useEnumQuery,
  useProductRecordQuery, useUidNameTuplesQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectEnums, selectUidNameTuples } from 'store/slices/dataSets/selectors';
import getProductDefaultValues from 'utils/formHelpers/getProductDefaultValues';
import { createProductSchema } from 'utils/validators';

import type { TCreateProductSchema } from 'utils/validators';
import type { ICreateProductForm } from '../interfaces/ICreateProductForm';

const useCreateProductForm = ({ recordId, isPageLoading }: ICreateProductForm) => {
  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { data } = useProductRecordQuery(fetchQuery);

  const { data: uidNameTuples } = useUidNameTuplesQuery({
    types: [UID_NAME_TUPLES.profitCenter, UID_NAME_TUPLES.supplier],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectUidNameTuples(result.data) }),
  });

  const { data: enumData } = useEnumQuery({
    types: [DATA_SETS_ENUM_TYPES.currency, DATA_SETS_ENUM_TYPES.volumeQtyUnit,
      DATA_SETS_ENUM_TYPES.relianceOnFreeNaturalResources, DATA_SETS_ENUM_TYPES.relianceOnFreeSocialResources,
      DATA_SETS_ENUM_TYPES.criticalityRating],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectEnums(result.data) }),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isLoading },
    reset,
    setError,
    getValues,
    watch,
    setValue,
  } = useForm<TCreateProductSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(createProductSchema),
    values: isPageLoading ? undefined : getProductDefaultValues({ values: data?.data, uidNameTuples, enumData }),
  });

  const fieldsName = Object.keys(getValues());

  return {
    control,
    handleSubmit,
    isDirty,
    reset,
    isLoading,
    setError,
    fieldsName,
    watch,
    setValue,
  };
};

export default useCreateProductForm;
