import { toast } from 'react-toastify';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ERROR_CODES } from 'constants/statusCodes';
import i18n from 'i18n';
import { authApi } from 'store/slices/auth/apis/auth';

import { adminUsersApi } from '../adminUsers/apis/adminUsersApi';
import { companiesApi } from '../companies/apis/companiesApi';
import { companyAdminUsersApi } from '../companyAdminUsers/apis/companyAdminUsersApi';
import { dataSetsApi } from '../dataSets/apis/dataSetsApi';
import { userApi } from '../user/apis/user';
import { valueChainMapApi } from '../valueChainMap/apis/valueChainMapApi';

import type { PayloadAction } from '@reduxjs/toolkit';
import type ICustomPayloadData from 'store/types/ICustomPayloadAction';
import type { IGeneral } from './interfaces/IGeneral';

export const initialState: IGeneral = {
  isFullScreenActive: false,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setGeneralState: (state, action: PayloadAction<Partial<IGeneral>>) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          authApi.endpoints.login.matchRejected,
        ),
        (_, action) => {
          const { message }: ICustomPayloadData = action?.payload?.data || {};

          const isDeactivated = message?.some((messageArray) => messageArray.some(
            (errorMessage) => errorMessage.errorCode === ERROR_CODES.deactivated,
          ));
          if (!isDeactivated) {
            toast.error(i18n.t('login.invalidCredentials'));
          }
        },
      ).addMatcher(
        isAnyOf(
          authApi.endpoints.newPassword.matchRejected,
        ),
        (_, action) => {
          const { errorCode }: ICustomPayloadData = action?.payload?.data || {};
          if (errorCode === ERROR_CODES.unauthorized) {
            toast.error(i18n.t('errors.linkWithTokenExpired'));
          }
        },
      ).addMatcher(
        isAnyOf(
          authApi.endpoints.startSyncWithPhone.matchFulfilled,
          authApi.endpoints.sendSms.matchFulfilled,
        ),
        () => {
          toast.success(i18n.t('errors.codeHasBeenSent'));
        },
      )
      .addMatcher(
        isAnyOf(
          companyAdminUsersApi.endpoints.activateCompanyAdminUser.matchFulfilled,
          adminUsersApi.endpoints.activateAdminUser.matchFulfilled,
        ),
        () => {
          toast.success(i18n.t('notifications.userActivated'));
        },
      )
      .addMatcher(
        isAnyOf(
          companyAdminUsersApi.endpoints.deactivateCompanyAdminUser.matchFulfilled,
          adminUsersApi.endpoints.deactivateAdminUser.matchFulfilled,
        ),
        () => {
          toast.success(i18n.t('notifications.userDeactivated'));
        },
      )
      .addMatcher(
        isAnyOf(
          companyAdminUsersApi.endpoints.deleteCompanyAdminUser.matchFulfilled,
          adminUsersApi.endpoints.deleteAdminUser.matchFulfilled,
        ),
        () => {
          toast.success(i18n.t('notifications.userDeleted'));
        },
      )
      .addMatcher(
        isAnyOf(
          companyAdminUsersApi.endpoints.updateUserDetails.matchFulfilled,
          companyAdminUsersApi.endpoints.updateCompanyUiConfiguration.matchFulfilled,
          userApi.endpoints.updateUserMe.matchFulfilled,
          companiesApi.endpoints.updateCompanyInfo.matchFulfilled,
          companiesApi.endpoints.updateCompanyLanguages.matchFulfilled,
          companiesApi.endpoints.updateCompanyAdmins.matchFulfilled,
          companiesApi.endpoints.updateUiConfiguration.matchFulfilled,
          companiesApi.endpoints.updateCompanyPermissions.matchFulfilled,
          companiesApi.endpoints.updateCompanySecurity.matchFulfilled,
        ),
        () => {
          toast.success(i18n.t('notifications.informationUpdated'));
        },
      )
      .addMatcher(
        isAnyOf(
          companyAdminUsersApi.endpoints.createUserDetails.matchFulfilled,
        ),
        () => {
          toast.success(i18n.t('notifications.userCreatedInDraftStatus'));
        },
      )
      .addMatcher(
        isAnyOf(
          authApi.endpoints.endSyncWithPhone.matchRejected,
          authApi.endpoints.verifyViaPhone.matchRejected,
          authApi.endpoints.newPassword.matchRejected,
          authApi.endpoints.startSyncWithPhone.matchRejected,
          authApi.endpoints.changePassword.matchRejected,
          authApi.endpoints.forgotPassword.matchRejected,
          authApi.endpoints.loginAdminAsUser.matchRejected,
          companyAdminUsersApi.endpoints.updateUserDetails.matchRejected,
          companyAdminUsersApi.endpoints.createUserDetails.matchRejected,
          companyAdminUsersApi.endpoints.updateUserPermissions.matchRejected,
          userApi.endpoints.updateUserMe.matchRejected,
          userApi.endpoints.userMe.matchRejected,
          companiesApi.endpoints.getCompaniesList.matchRejected,
          companiesApi.endpoints.updateCompanyInfo.matchRejected,
          companiesApi.endpoints.updateCompanyLanguages.matchRejected,
          companiesApi.endpoints.updateCompanyAdmins.matchRejected,
          companiesApi.endpoints.updateUiConfiguration.matchRejected,
          companiesApi.endpoints.updateCompanyPermissions.matchRejected,
          companiesApi.endpoints.updateCompanySecurity.matchRejected,
          dataSetsApi.endpoints.profitCenterImportRecords.matchRejected,
          valueChainMapApi.endpoints.updateBusinessStructureStatus.matchRejected,
          valueChainMapApi.endpoints.updateBusinessStructureOrgPath.matchRejected,
        ),
        (_, action) => {
          const { message, errorCode }: ICustomPayloadData = action?.payload?.data || {};
          if (typeof message === 'string') {
            toast.error(message);
          } else if (message) {
            toast.error(message?.[0]?.[0]?.error);
          } else if (errorCode === ERROR_CODES.internalServerError) {
            toast.error(i18n.t('notifications.somethingWentWrong'));
          }
        },
      )
      .addMatcher(
        isAnyOf(
          dataSetsApi.endpoints.profitCenterArchive.matchFulfilled,
          dataSetsApi.endpoints.ownOperationArchive.matchFulfilled,
          dataSetsApi.endpoints.supplierArchive.matchFulfilled,
          dataSetsApi.endpoints.customerArchive.matchFulfilled,
          dataSetsApi.endpoints.productArchive.matchFulfilled,
        ),
        () => {
          toast.success(i18n.t('notifications.recordHasBeenArchived'));
        },
      )
      .addMatcher(
        isAnyOf(
          dataSetsApi.endpoints.profitCenterDeArchive.matchFulfilled,
          dataSetsApi.endpoints.ownOperationDeArchive.matchFulfilled,
          dataSetsApi.endpoints.supplierDeArchive.matchFulfilled,
          dataSetsApi.endpoints.customerDeArchive.matchFulfilled,
          dataSetsApi.endpoints.productDeArchive.matchFulfilled,
        ),
        () => {
          toast.success(i18n.t('notifications.recordHasBeenDeArchived'));
        },
      );
  },
});

export const { setGeneralState } = generalSlice.actions;

export default generalSlice.reducer;
