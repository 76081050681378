import { VALUE_CHAIN_MAP } from '../constants/general';
import {
  BUSINESS_UNIT_TYPES,
  VALUE_CHAIN_MAP_CUSTOM_NODES,
  VALUE_CHAIN_UNIT_TYPES,
} from '../constants/interfaces';

import type { Node } from '@xyflow/react';
import type { TBusinessUnitType, TValueChainUnitType } from '../constants/interfaces';

export const getUnitHeight = (
  type: TValueChainUnitType,
  businessUnitType?: TBusinessUnitType,
  estimated?: boolean,
): number => {
  let result = 0;
  if (businessUnitType === BUSINESS_UNIT_TYPES.LOGICAL || estimated) {
    result = VALUE_CHAIN_MAP.LOGICAL_STATUS_HEIGHT;
  }

  switch (type) {
    case VALUE_CHAIN_UNIT_TYPES.PRODUCT:
      result += VALUE_CHAIN_MAP.UNIT_INPUT_PRODUCT_HEIGHT;
      break;
    case VALUE_CHAIN_UNIT_TYPES.OWN_OPERATION:
      result += VALUE_CHAIN_MAP.UNIT_OWN_OPERATION_HEIGHT;
      break;
    case VALUE_CHAIN_UNIT_TYPES.PROFIT_CENTER:
      result += VALUE_CHAIN_MAP.UNIT_PROFIT_CENTER_HEIGHT;
      break;
    case VALUE_CHAIN_UNIT_TYPES.CUSTOMER:
      result += VALUE_CHAIN_MAP.UNIT_CUSTOMER_HEIGHT;
      break;
    case VALUE_CHAIN_UNIT_TYPES.SUPPLIER:
    default:
      result += VALUE_CHAIN_MAP.UNIT_SUPPLIER_HEIGHT;
  }
  return result;
};

export const getTargetedBoard = (dragPosition: { x: number, y: number }, nodes: Node[]) => {
  const boards = nodes.filter((n) => n.type === VALUE_CHAIN_MAP_CUSTOM_NODES.BOARD);
  return boards.find((board) => (
    dragPosition.x > board.position.x
    && dragPosition.x < Number(board.measured?.width) + board.position.x
    && dragPosition.y > board.position.y
    && dragPosition.y < Number(board.measured?.height) + board.position.y
  ));
};

export const isTargetNodeMatching = (closeNode: Node, node: Node): boolean => (
// units should be as same type only Physical unit can be dropped into Logical unit
  closeNode.data.type === node.data.type
      && 'records' in closeNode.data
      && Array.isArray(closeNode.data.records)
      && closeNode.data.records[0].businessUnitType === BUSINESS_UNIT_TYPES.LOGICAL
      && 'records' in node.data
      && Array.isArray(node.data.records)
      && node.data.records[0].businessUnitType === BUSINESS_UNIT_TYPES.PHYSICAL
  // unit shouldn't sit already in this unit
  && closeNode.id !== node.parentId)

// if target is swimlane
    || (closeNode.type === VALUE_CHAIN_MAP_CUSTOM_NODES.SWIMLANE
      // unit shouldn't sit already in this swimlane
      && closeNode.id !== node.data.swimlaneId);
