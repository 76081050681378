import React from 'react';
import { clsx } from 'clsx';

import { TOOLTIP_POSITIONS } from '../../constants/interfaces';

import type ITooltip from './interfaces/ITooltip';

import styles from './scss/Tooltip.module.scss';

function Tooltip({
  hint, className, children, position, hintClassName, isDisabled,
}: ITooltip) {
  return (
    <div className={clsx(styles.root, className)}>
      {children}
      {hint ? (
        <p className={clsx(styles.hint, hintClassName, {
          [styles.tooltipBelow]: position === TOOLTIP_POSITIONS.BOTTOM,
          [styles.hideElement]: isDisabled,
        })}
        >
          {hint}
        </p>
      ) : null}
    </div>
  );
}

export default Tooltip;
