import { USER_PILLAR_ACCESS_TYPES, VALUE_CHAIN_STATUS } from 'constants/interfaces';

import usePillarModulePermission from './usePillarModulePermission';

import type { TValueChainStatus } from '../constants/interfaces';
import type { IUseVCMapPermission, IVCMapActions } from './interfaces/IUseVCMapPermission';

const DEFAULT_ACTIONS: IVCMapActions = {
  canView: true,
  canChangeStatus: false,
  canRename: false,
  canDelete: false,
  canEdit: false,
  canArchive: false,
  canDuplicate: false,
};

const EDITABLE_ACTIONS: IVCMapActions = {
  canView: true,
  canChangeStatus: true,
  canRename: true,
  canDelete: true,
  canEdit: true,
  canArchive: true,
  canDuplicate: true,
};

const STATUS_PERMISSIONS: Record<TValueChainStatus, IVCMapActions> = {
  [VALUE_CHAIN_STATUS.current]: EDITABLE_ACTIONS,
  [VALUE_CHAIN_STATUS.inProgress]: EDITABLE_ACTIONS,
  [VALUE_CHAIN_STATUS.readyForReview]: EDITABLE_ACTIONS,
  [VALUE_CHAIN_STATUS.underReview]: EDITABLE_ACTIONS,
  [VALUE_CHAIN_STATUS.requestApproval]: EDITABLE_ACTIONS,
  [VALUE_CHAIN_STATUS.processing]: EDITABLE_ACTIONS,
  [VALUE_CHAIN_STATUS.approved]: {
    ...DEFAULT_ACTIONS,
    canArchive: true,
    canDuplicate: true,
  },
  [VALUE_CHAIN_STATUS.requiresRework]: {
    ...DEFAULT_ACTIONS,
    canChangeStatus: true,
    canArchive: true,
    canDuplicate: true,
    allowedStatusTransitions: [VALUE_CHAIN_STATUS.inProgress],
  },
  [VALUE_CHAIN_STATUS.archived]: DEFAULT_ACTIONS,
};

const useVCMapPermission = ({
  pillarModuleKey,
  currentStatus,
}: IUseVCMapPermission) => {
  const basePermissions = usePillarModulePermission({ pillarModuleKey });

  const vcMapActions = basePermissions.access === USER_PILLAR_ACCESS_TYPES.viewEdit
    ? STATUS_PERMISSIONS[currentStatus] ?? DEFAULT_ACTIONS
    : DEFAULT_ACTIONS;

  return {
    ...basePermissions,
    vcMapActions,
  };
};

export default useVCMapPermission;
