import React from 'react';
import { Panel, ReactFlow, useReactFlow } from '@xyflow/react';
import { clsx } from 'clsx';
import ConnectionVCModal from 'components/ConnectionVCModal/ConnectionVCModal';
import DeleteRestrictedModal from 'components/DeleteRestrictedModal/DeleteRestrictedModal';
import FiltersPanel from 'components/FiltersPanel/FiltersPanel';
import DeleteModal from 'pages/CompanyAdminPages/ValueChainMap/components/DeleteModal/DeleteModal';

import Loader from '../../../../../components/Loader/Loader';
import RenameModal from '../../../../../components/RenameModal/RenameModal';
import { REACT_FLOW, VALUE_CHAIN_MAP, VALUE_CHAIN_MAP_CONNECTION_COLORS } from '../../../../../constants/general';
import {
  DELETE_MODAL_TITLES,
  VALUE_CHAIN_MAP_CUSTOM_NODES,
  VALUE_CHAIN_MAP_MODALS,
} from '../../../../../constants/interfaces';
import ColorsTip from './components/ColorsTip';
import CustomBoardNode from './components/CustomBoardNode';
import CustomEdge from './components/CustomEdge';
import CustomMarkerEnd from './components/CustomMarkerEnd';
import CustomSwimlaneNode from './components/CustomSwimlaneNode';
import CustomUnitNode from './components/CustomUnitNode';
import RightPanel from './components/RightPanel';

import type { IValueChainView } from './interfaces/IValueChainView';

import './sass/reactFlowStyles.scss';
import styles from './sass/ValueChainView.module.scss';

const nodeTypes = {
  [VALUE_CHAIN_MAP_CUSTOM_NODES.BOARD]: CustomBoardNode,
  [VALUE_CHAIN_MAP_CUSTOM_NODES.SWIMLANE]: CustomSwimlaneNode,
  [VALUE_CHAIN_MAP_CUSTOM_NODES.UNIT]: CustomUnitNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

function ValueChainView({
  onMoveEnd, onConnect, onNodesChange, onEdgesChange, isLoading, nodes, edges, updateEdgeStyles, onNodeDrag,
  selectedBoard, closeModal, onRenameSubmit, activeModal, onDrop, onDragOver, onAddSwimlane, isFullScreenActive,
  onNodeDragStop, onNodeDragStart, isAccessDisabled, isDeleteModalOpen, setDeleteModalOpen,
  onDeleteSwimlaneConfirm, enumData, onAddServiceConnection, onEdgeDoubleClick, onEdgeClick,
  selectedEdge, openFilters, onOpenFilters, filters, filtersData, setFiltersData, selectedFilter,
  onApplyFilters,
}: IValueChainView) {
  const { getZoom } = useReactFlow();

  return (
    <>
      <Panel
        className={clsx(styles.rightCenterPanel, {
          [styles.aloneElement]: isAccessDisabled,
        })}
        position="top-right"
      >
        <RightPanel
          isAccessDisabled={isAccessDisabled}
          onClickFilters={onOpenFilters}
          selectedFilter={selectedFilter}
        />
      </Panel>
      <Panel
        position="bottom-left"
        className={clsx(styles.colorsTip, {
          [styles.fullScreen]: isFullScreenActive,
        })}
      >
        <ColorsTip />
      </Panel>
      {isLoading ? <Loader isPageLoading fullLoaderClassName={styles.fullLoader} /> : null }
      <CustomMarkerEnd color={VALUE_CHAIN_MAP_CONNECTION_COLORS.LOGICAL} id="edge-marker-logical" />
      <CustomMarkerEnd color={VALUE_CHAIN_MAP_CONNECTION_COLORS.SUPPLIERS_OF_GOODS} id="edge-marker-supplierOfGoods" />
      <CustomMarkerEnd color={VALUE_CHAIN_MAP_CONNECTION_COLORS.FINANCIAL} id="edge-marker-financial" />
      <CustomMarkerEnd color={VALUE_CHAIN_MAP_CONNECTION_COLORS.SERVICE} id="edge-marker-service" />
      <ReactFlow
        className="reactFlow"
        nodes={nodes}
        edges={updateEdgeStyles(edges)}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onMoveEnd={() => onMoveEnd(getZoom())}
        onEdgeClick={onEdgeClick}
        onEdgeDoubleClick={onEdgeDoubleClick}
        onConnect={onConnect}
        minZoom={REACT_FLOW.MIN_ZOOM}
        onNodeDragStart={onNodeDragStart}
        onNodeDragStop={onNodeDragStop}
        onNodeDrag={onNodeDrag}
        preventScrolling
        defaultViewport={VALUE_CHAIN_MAP.DEFAULT_VIEWPORT}
        onDrop={onDrop}
        onDragOver={onDragOver}
        connectOnClick={!isAccessDisabled}
        nodesConnectable={!isAccessDisabled}
      />
      {selectedBoard ? (
        <RenameModal
          open={activeModal === VALUE_CHAIN_MAP_MODALS.RENAME}
          onSubmit={onRenameSubmit}
          onReject={closeModal}
          name={selectedBoard.name}
        />
      ) : null}
      <RenameModal
        onSubmit={onAddSwimlane}
        onReject={closeModal}
        open={activeModal === VALUE_CHAIN_MAP_MODALS.ADD_SWIMLANE}
        name=""
      />
      <DeleteModal
        open={isDeleteModalOpen}
        onReject={() => setDeleteModalOpen(false)}
        isLoading={false}
        name={selectedBoard?.name ? `${selectedBoard?.name}` : ''}
        title={DELETE_MODAL_TITLES.DELETE_SWIMLANE}
        onConfirm={onDeleteSwimlaneConfirm}
      />
      <DeleteRestrictedModal
        isOpen={activeModal === VALUE_CHAIN_MAP_MODALS.REFUSED_DELETE_SWIMLANE}
        onClose={closeModal}
      />
      <ConnectionVCModal
        isOpen={activeModal === VALUE_CHAIN_MAP_MODALS.VALUE_CONNECTION}
        onClose={closeModal}
        enumData={enumData}
        onAddServiceConnection={onAddServiceConnection}
        selectedEdge={selectedEdge}
      />
      <FiltersPanel
        filters={filters}
        open={openFilters}
        onClose={onOpenFilters}
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        onApplyFilters={onApplyFilters}
      />
    </>
  );
}

export default ValueChainView;
