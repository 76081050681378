import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select/Select';
import Textarea from 'components/Textarea/Textarea';
import TextField from 'components/TextField/TextField';
import { CONNECTION_TYPE_OPTIONS } from 'constants/dataSets';
import { COMMENT_LOGICAL, CONNECTION_TYPE, OWNERSHIP_PERCENTAGE } from 'constants/formFields';
import { DESCRIPTION_LENGTH } from 'constants/general';

import usePercentageFormat from '../hooks/usePercentageFormat';

import type IFieldError from 'store/types/IFieldError';
import type { ILogicalForm } from '../interfaces/ILogicalForm';

import styles from '../scss/LogicalForm.module.scss';

function LogicalForm({ control, showField }:ILogicalForm) {
  const { t } = useTranslation();
  const { handlePercentageChange } = usePercentageFormat();
  const logicalFormRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.container} ref={logicalFormRef}>
      <div className={styles.row}>
        <div className={styles.field}>
          <Controller
            control={control}
            name={CONNECTION_TYPE}
            render={({ field: { value, onChange } }) => (
              <Select
                className={styles.field}
                options={CONNECTION_TYPE_OPTIONS}
                placeholder={t('valueChainMap.connectionType')}
                value={value ?? ''}
                onChange={onChange}
                menuPosition="absolute"
                placeholderClassName={styles.placeholder}
                modalRef={logicalFormRef}
                classNameMenu={styles.menuMultiple}
                label={t('valueChainMap.connectionType')}
              />
            )}
          />
        </div>
        <div className={styles.field}>
          {showField ? (
            <Controller
              control={control}
              name={OWNERSHIP_PERCENTAGE}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  className={styles.field}
                  name={name}
                  value={value}
                  error={(error as IFieldError)?.value?.message}
                  onChange={(event) => handlePercentageChange(event.target.value, onChange)}
                  label={t('valueChainMap.ownershipPercentage')}
                />
              )}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name={COMMENT_LOGICAL}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <Textarea
              name={name}
              value={value}
              label={t('valueChainMap.comment')}
              placeholder={t('common.typeYourMessage')}
              onChange={(e) => onChange(e.target.value.slice(0, DESCRIPTION_LENGTH))}
              error={error?.message}
              charactersLimit={DESCRIPTION_LENGTH}
            />
          )}
        />
      </div>
    </div>
  );
}

export default LogicalForm;
