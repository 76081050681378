import { ReactComponent as Vehicles } from 'assets/icons/car.svg';
import { ReactComponent as DataCentre } from 'assets/icons/dataCentre.svg';
import { ReactComponent as Factory } from 'assets/icons/factory2.svg';
import { ReactComponent as IpIcon } from 'assets/icons/ip.svg';
import { ReactComponent as OfficeIcon } from 'assets/icons/office.svg';
import { ReactComponent as OtherBuilding } from 'assets/icons/otherBuilding.svg';
import { ReactComponent as OtherCapitalAssetIcon } from 'assets/icons/otherCapitalAsset.svg';
import { ReactComponent as Aircraft } from 'assets/icons/plane.svg';
import { ReactComponent as Ships } from 'assets/icons/ship.svg';
import { ReactComponent as Stock } from 'assets/icons/stock.svg';
import { ReactComponent as Warehouses } from 'assets/icons/warehouses.svg';

const getIcon = (nameIcon: string): JSX.Element | null => {
  switch (nameIcon) {
    case 'Office':
      return <OfficeIcon />;
    case 'OtherBuilding':
      return <OtherBuilding />;
    case 'DataCentre':
      return <DataCentre />;
    case 'Factory':
      return <Factory />;
    case 'Warehouse':
      return <Warehouses />;
    case 'Vehicles':
      return <Vehicles />;
    case 'Ships':
      return <Ships />;
    case 'Aircraft':
      return <Aircraft />;
    case 'Stock':
      return <Stock />;
    case 'IP':
      return <IpIcon />;
    case 'Other Capital Asset':
      return <OtherCapitalAssetIcon />;
    default:
      return null;
  }
};

export default getIcon;
