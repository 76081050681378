import { useCallback } from 'react';

import { VALUE_CHAIN_MAP } from '../constants/general';
import { CONNECTION_TYPES } from '../constants/interfaces';
import { getUnitHeight } from '../utils/reactFlowHelpers';

import type { Edge, Node } from '@xyflow/react';
import type { Dispatch, SetStateAction } from 'react';
import type { ID_VALUE } from '../constants/dataSets';
import type IProcessConnections from '../pages/CompanyAdminPages/CompanyMap/components/ValueChain/interfaces/IProcessConnections';
import type { IValueChainBoard } from '../store/slices/valueChainMap/interfaces/IValueChainBoard';
import type { IValueChainBoardColumn } from '../store/slices/valueChainMap/interfaces/IValueChainBoardColumn';
import type { IValueChainUnit } from '../store/slices/valueChainMap/interfaces/IValueChainUnit';
import type { IEdgeGroupMap } from './interfaces/IEdgeGroupMap';
import type { IPushNewBoardNode } from './interfaces/IPushNewBoardNode';
import type { IPushNewEdge } from './interfaces/IPushNewEdge';
import type { IPushNewSwimlaneNode } from './interfaces/IPushNewSwimlaneNode';
import type { IPushNewUnitNode } from './interfaces/IPushNewUnitNode';

const applyEdgeOffsets = (edges: Edge[]): Edge[] => {
  let resultEdges: Edge[] = [];

  if (edges && edges.length > 0) {
    // Group edges by source and target separately
    const sourceGroups: IEdgeGroupMap = {};
    const targetGroups: IEdgeGroupMap = {};

    edges.forEach((edge) => {
      if (edge.source) {
        if (!sourceGroups[edge.source]) {
          sourceGroups[edge.source] = [];
        }
        sourceGroups[edge.source].push(edge);
      }
      if (edge.target) {
        if (!targetGroups[edge.target]) {
          targetGroups[edge.target] = [];
        }
        targetGroups[edge.target].push(edge);
      }
    });

    // Apply offsets to edges based on shared sources and targets
    resultEdges = edges.map((edge) => {
      const calculateOffset = (index: number, totalEdges: number): number => {
        const offsetBase = VALUE_CHAIN_MAP.MULTIPLE_EDGE_OFFSET;
        const middleIndex = (totalEdges - 1) / 2;
        const relativeIndex = index - middleIndex;
        return relativeIndex * offsetBase;
      };

      let sourceOffset = 0;
      let targetOffset = 0;

      if (edge.source && sourceGroups[edge.source].length > 1) {
        const sourceGroup = sourceGroups[edge.source];
        const index = sourceGroup.findIndex((e) => e.id === edge.id);
        sourceOffset = calculateOffset(index, sourceGroup.length);
      }

      if (edge.target && targetGroups[edge.target].length > 1) {
        const targetGroup = targetGroups[edge.target];
        const index = targetGroup.findIndex((e) => e.id === edge.id);
        targetOffset = calculateOffset(index, targetGroup.length);
      }

      return {
        ...edge,
        data: {
          ...edge.data,
          sourceOffset,
          targetOffset,
        },
      };
    });
  }

  return resultEdges;
};

const useCreateNodesAndEdgesValueChain = (
  onEditSwimlane: (board: IValueChainBoardColumn) => void,
  onDeleteSwimlane: (board: IValueChainBoardColumn) => void,
  isAccessDisabled: boolean,
  setAccumulatedMaxBoardHeight: Dispatch<SetStateAction<number>>,
) => {
  const createNodesAndEdges = useCallback((dataT: IValueChainBoard[]): { nodes: Node[], edges: Edge[] } => {
    const nodesT: Node[] = [];
    const edgesT: Edge[] = [];

    const pushNewEdge = ({
      sourceId,
      connection,
      connectionType,
    }: IPushNewEdge) => {
      edgesT.push({
        type: 'custom',
        id: `e-${sourceId}-unit_${connection.toId}-type_${connectionType}`,
        source: sourceId,
        target: `unit_${connection.toId}`,
        data: {
          connection,
          connectionType,
        },
      });
    };

    const pushNewBoardNode = ({ board, xPosition, boardWidth }: IPushNewBoardNode) => {
      nodesT.push({
        id: `board_${board.id}`,
        type: 'board',
        draggable: false,
        focusable: false,
        selectable: false,
        data: {
          ...board,
          boardWidth,
        },
        position: { x: xPosition, y: 0 },
      });
    };

    const pushNewSwimlaneNode = ({ unit, xPosition, yPosition }: IPushNewSwimlaneNode) => {
      nodesT.push({
        id: `swimlane_${unit.id}`,
        type: 'swimlane',
        draggable: false,
        data: {
          ...unit,
          onEditSwimlane,
          onDeleteSwimlane,
          isAccessDisabled,
        },
        position: { x: xPosition, y: yPosition },
      });
    };

    const pushNewUnitNode = ({
      unit, xPosition, yPosition, childrenCount, parentId, swimlaneId,
    }: IPushNewUnitNode) => {
      nodesT.push({
        id: `unit_${unit.id}`,
        type: 'unit',
        data: {
          ...unit,
          childrenCount,
          swimlaneId: `swimlane_${swimlaneId}`,
        },
        position: { x: xPosition, y: yPosition },
        parentId,
      });
    };

    const processConnections = (
      sourceUnit: Pick<IValueChainUnit, typeof ID_VALUE>,
      connections: IProcessConnections,
    ) => {
      Object.entries(CONNECTION_TYPES)
        .forEach(([key, connectionType]) => {
          const unitConnections = connections[key as keyof IProcessConnections];
          if (unitConnections?.length) {
            unitConnections.forEach((connection) => {
              pushNewEdge({
                sourceId: `unit_${sourceUnit.id}`,
                connection,
                connectionType,
              });
            });
          }
        });
    };

    let accumulatedBoardXPosition = 0;

    // Iterate over each board to create nodes and edges
    dataT.forEach((board) => {
      let additionalSpace = 0;
      if (board.columns.length > 1) {
        additionalSpace = (board.columns.length - 1) * VALUE_CHAIN_MAP.SWIMLANE_GAP;
      }
      const boardWidth = VALUE_CHAIN_MAP.BOARD_PADDING * 2 + board.columns.length * VALUE_CHAIN_MAP.SWIMLANE_WIDTH
        + additionalSpace;

      pushNewBoardNode({
        board,
        xPosition: accumulatedBoardXPosition,
        boardWidth,
      });

      accumulatedBoardXPosition += boardWidth + VALUE_CHAIN_MAP.BOARD_GAP;

      let accumulatedSwimlaneXPosition = 0;

      // Iterate over each column in the board
      board.columns.forEach((column, columnIndex) => {
        pushNewSwimlaneNode({
          unit: column,
          xPosition: accumulatedSwimlaneXPosition + accumulatedBoardXPosition - boardWidth,
          yPosition: VALUE_CHAIN_MAP.TITLE_HEIGHT,
        });

        let accumulatedUnitParentYPosition = VALUE_CHAIN_MAP.TITLE_HEIGHT * 2;

        column.units.forEach((unit) => {
          // Process main unit connections
          processConnections(unit, {
            lConToUnits: unit.lConToUnits,
            sConToUnits: unit.sConToUnits,
            gsConToUnits: unit.gsConToUnits,
          });
          if (unit.children.length > 0) {
            unit.children.forEach((unitChild) => {
              processConnections(unitChild, {
                lConToUnits: unitChild.lConToUnits,
                sConToUnits: unitChild.sConToUnits,
                gsConToUnits: unitChild.gsConToUnits,
              });
            });
          }
          // Add unit nodes
          pushNewUnitNode({
            unit,
            xPosition: accumulatedSwimlaneXPosition + VALUE_CHAIN_MAP.UNIT_PADDING
              + accumulatedBoardXPosition - boardWidth,
            yPosition: accumulatedUnitParentYPosition,
            childrenCount: unit.children.length,
            swimlaneId: column.id,
          });

          accumulatedUnitParentYPosition += getUnitHeight(
            unit.type,
            unit.records[0]?.businessUnitType,
            unit.records[0]?.estimated,
          );

          setAccumulatedMaxBoardHeight((prevHeight) => (prevHeight < accumulatedUnitParentYPosition
            ? accumulatedUnitParentYPosition : prevHeight));
          unit.records.forEach(() => {
            // needs to add height for each record if there are more than one
          });

          let accumulatedUnitChildYPosition = getUnitHeight(
            unit.type,
            unit.records[0]?.businessUnitType,
            unit.records[0]?.estimated,
          );

          // Add child unit nodes
          unit.children.forEach((unitChild) => {
            pushNewUnitNode({
              unit: unitChild,
              xPosition: 0,
              yPosition: accumulatedUnitChildYPosition,
              childrenCount: unit.children.length,
              parentId: `unit_${unit.id}`,
            });

            const childUnitHeight = getUnitHeight(
              unitChild.type,
              unitChild.records[0]?.businessUnitType,
              unitChild.records[0]?.estimated,
            );
            accumulatedUnitChildYPosition += childUnitHeight;
            accumulatedUnitParentYPosition += childUnitHeight + VALUE_CHAIN_MAP.UNIT_PADDING;

            setAccumulatedMaxBoardHeight((prevHeight) => (prevHeight < accumulatedUnitParentYPosition
              ? accumulatedUnitParentYPosition : prevHeight));
          });
        });
        accumulatedSwimlaneXPosition += VALUE_CHAIN_MAP.SWIMLANE_WIDTH
          + (columnIndex !== board.columns.length - 1 ? VALUE_CHAIN_MAP.SWIMLANE_GAP : 0);
      });
    });

    return { nodes: nodesT, edges: applyEdgeOffsets(edgesT) };
  }, [onEditSwimlane, onDeleteSwimlane, setAccumulatedMaxBoardHeight, isAccessDisabled]);

  return { createNodesAndEdges };
};

export default useCreateNodesAndEdgesValueChain;
