import { useEffect, useRef, useState } from 'react';
import Select, {
  type MenuPlacement,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { clsx } from 'clsx';

import NoOptionsMessage from './NoOptionsMessage';

import type { ISelect } from './interfaces/ISelect';

import styles from './sass/Select.module.scss';

function StyledSelect({
  options,
  onChange,
  value,
  placeholder,
  required,
  label,
  error,
  name,
  isDisabled,
  className,
  placeholderClassName,
  menuPortalClassName,
  maxMenuHeight,
  modalRef,
  isSearchable = false,
  menuPosition = 'fixed',
  isMulti = false,
  isAsync = false,
  isClearable = false,
  loadOptions,
  hideErrorText = false,
  classNameMenu,
}: ISelect) {
  const selectRef = useRef<HTMLDivElement>(null);
  const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>('auto');
  const [open, setOpen] = useState(false);

  const SelectComponent = isAsync ? AsyncSelect : Select;

  useEffect(() => {
    if (selectRef.current && open && modalRef?.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const modalRect = modalRef.current.getBoundingClientRect();
      const dropdownHeight = (options?.length || 0) * 44;

      // Determine the position taking into account the scrolling
      const spaceBelow = modalRect.bottom - rect.bottom;
      const spaceAbove = rect.top - modalRect.top;

      const enoughSpaceBelow = spaceBelow >= (maxMenuHeight || dropdownHeight);
      const enoughSpaceAbove = spaceAbove >= (maxMenuHeight || dropdownHeight);

      if (enoughSpaceBelow) {
        setMenuPlacement('bottom');
      } else if (enoughSpaceAbove) {
        setMenuPlacement('top');
      } else {
        // If there is not enough space either at the top or bottom, choose the side with the most space.
        setMenuPlacement(spaceBelow > spaceAbove ? 'bottom' : 'top');
      }
    }
  }, [selectRef, maxMenuHeight, open, options, modalRef]);

  return (
    <div className={clsx(styles.root, className)} ref={selectRef}>
      {label ? (
        <p className={styles.label}>
          {required ? `${label}*` : label}
        </p>
      ) : null}
      <SelectComponent
        classNames={{
          control: () => clsx(styles.control, {
            [styles.controlError]: error,
          }),
          placeholder: () => clsx(styles.placeholder, placeholderClassName),
          indicatorSeparator: () => styles.separator,
          indicatorsContainer: () => styles.chevron,
          menuPortal: () => clsx(styles.menuPortal, menuPortalClassName),
          menu: () => clsx(styles.menu, classNameMenu),
          option: (state) => clsx(styles.option, {
            [styles.optionSelected]: state.isSelected,
          }),
          multiValue: () => styles.customMultiValue,
          singleValue: () => styles.customSingleValue,
        }}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        name={name}
        value={value}
        options={options}
        placeholder={placeholder}
        isSearchable={isSearchable}
        required={required}
        onChange={onChange}
        menuPosition={menuPosition}
        isDisabled={isDisabled}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        loadOptions={isAsync ? loadOptions : undefined}
        isMulti={isMulti}
        components={isAsync ? {
          NoOptionsMessage,
          DropdownIndicator: null,
        } : { }}
        isClearable={isClearable}
      />
      {error && !hideErrorText ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
}

export default StyledSelect;
