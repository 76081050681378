import { useTranslation } from 'react-i18next';
import DataSetsEmptyList from 'components/DataSetsEmptyList/DataSetsEmptyList';
import DataSetsItems from 'components/DataSetsItems/DataSetsItems';
import DataSetsListFilters from 'components/DataSetsListFilters/DataSetsListFilters';
import DeactivationInfoModal from 'components/DeactivationModals/DeactivationInfoModal';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import EmptyList from 'components/EmptyList/EmptyList';
import ImportMultipleErrorsModal from 'components/ImportMultipleErrorsModal/ImportMultipleErrorsModal';
import Loader from 'components/Loader/Loader';
import MappingMultipleModal from 'components/MappingMultipleModal/MappingMultipleModal';
import Pagination from 'components/Pagination/Pagination';
import Panel from 'components/Panel/Panel';
import {
  DATA_SET_PAGINATION_COUNT,
  DATA_SETS_PRODUCTS_HEAD_CELLS,
} from 'constants/dataSets';

import ProductsItem from './ProductsItem/ProductsItem';

import type { Control } from 'react-hook-form';
import type { TDataSetsListSchema } from 'utils/validators';
import type IProductsView from './interfaces/IProductsView';
import type { TUseProductImportRecordsMutation } from './interfaces/TUseProductImportRecordsMutation';

import styles from './sass/ProductsView.module.scss';

function ProductsView({
  isEmptyList, meta, uploadControl, isUploadFileVisible, isSupplierEmpty, isMappingModalVisible,
  onCloseMappingModal, setIsImportErrorsModalVisible, isImportErrorsModalVisible, onDownloadExample, importRecords,
  items, onSortChange, onSearchChange, onSelectAll, isLineMark, onArchive, setIsArchiveModalVisible,
  onArchiveConfirm, isArchiveModalVisible, isArchiveSelectedItemsVisible, isArchiveModalInfoVisible,
  setIsArchiveModalInfoVisible, latestArchivation, onArchiveModalInfoOpen, onMoveToArchive, onDeArchive, selectedItems,
  onPageChange, currentTotalItems, onEditRecord, isActionsVisible, onAddRecord, isProfitCenterEmpty,
  control, isWithSearch, isLoading, isLazyProductExampleLoading, onShowArchiveChange,
}: IProductsView) {
  const { t } = useTranslation();

  return (
    <>
      {isEmptyList && !isWithSearch && (!isLoading || isLazyProductExampleLoading) ? (
        <DataSetsEmptyList
          title={t('products.title')}
          subTitle={t('products.subTitle')}
          uploadControl={uploadControl}
          isUploadFileVisible={isUploadFileVisible}
          isSupplierEmpty={isSupplierEmpty}
          onDownloadExample={onDownloadExample}
          isProfitCenterEmpty={isProfitCenterEmpty}
          isLoading={isLazyProductExampleLoading}
        />
      )
        : (
          <div className={styles.root}>
            <Panel className={styles.panel}>
              <Loader isPageLoading={isLoading} />
              <div className={styles.content}>
                <DataSetsListFilters
                  control={control}
                  meta={meta}
                  onSearchChange={onSearchChange}
                  onArchive={onArchive}
                  isArchiveSelectedItemsVisible={isArchiveSelectedItemsVisible}
                  selectedItems={selectedItems}
                  currentTotalItems={currentTotalItems}
                  isActionsVisible={isActionsVisible}
                  title={t('products.title')}
                  subTitle={t('products.subTitle')}
                  onAddRecord={onAddRecord}
                  onShowArchiveChange={onShowArchiveChange}
                />
                {isEmptyList ? (<EmptyList />) : (
                  <>
                    <DataSetsItems<Control<TDataSetsListSchema>>
                      control={control}
                      onSortChange={onSortChange}
                      onSelectAll={onSelectAll}
                      isLineMark={isLineMark}
                      isActionsVisible={isActionsVisible}
                      headCells={DATA_SETS_PRODUCTS_HEAD_CELLS}
                    >
                      {items?.map((item) => (
                        <ProductsItem
                          key={item.id}
                          item={item}
                          control={control}
                          onArchiveModalInfoOpen={onArchiveModalInfoOpen}
                          onMoveToArchive={onMoveToArchive}
                          onDeArchive={onDeArchive}
                          onEditRecord={onEditRecord}
                          isActionsVisible={isActionsVisible}
                        />
                      ))}
                    </DataSetsItems>
                    <Pagination
                      count={meta?.count || 0}
                      offset={meta?.offset || 0}
                      limit={DATA_SET_PAGINATION_COUNT}
                      onPageChange={onPageChange}
                    />
                  </>
                )}
              </div>
            </Panel>
          </div>
        )}
      <MappingMultipleModal<TUseProductImportRecordsMutation>
        isMappingModalVisible={isMappingModalVisible}
        onCloseMappingModal={onCloseMappingModal}
        setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
        importRecords={importRecords}
        isLoading={isLoading}
      />
      <ImportMultipleErrorsModal
        isImportErrorsModalVisible={isImportErrorsModalVisible}
        setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
        isLoading={isLoading}
      />
      <DeactivationModal
        open={isArchiveModalVisible}
        title={t('profitCentres.archivingReasonTitle')}
        text={t('profitCentres.archivingReasonDescription')}
        onReject={() => { setIsArchiveModalVisible(false); }}
        onSubmit={onArchiveConfirm}
        isLoading={isLoading}
      />
      <DeactivationInfoModal
        open={isArchiveModalInfoVisible}
        title={t('profitCentres.archiveDetails')}
        deactivationInfo={latestArchivation}
        onClose={() => setIsArchiveModalInfoVisible(false)}
        isLoading={isLoading}
      />
    </>
  );
}

export default ProductsView;
