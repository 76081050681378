import React from 'react';

import type { ICustomMarkerEnd } from '../interfaces/ICustomMarkerEnd';

function CustomMarkerEnd({ color, id }: ICustomMarkerEnd) {
  return (
    <svg style={{ position: 'absolute', top: 0, left: 0 }}>
      <defs>
        <marker
          className="react-flow__arrowhead"
          id={id}
          markerWidth="14"
          markerHeight="14"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            stroke={color}
            fill={color}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            points="-5,-4 0,0 -5,4 -5,-4"
          />
        </marker>
      </defs>
    </svg>
  );
}

export default CustomMarkerEnd;
