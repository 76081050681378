import { useCallback } from 'react';

const usePercentageFormat = () => {
  const formatPercentage = useCallback((value: string, withoutSign: boolean = false) => {
    const numericValue = value.replace(/[^\d]/g, '');
    const number = parseInt(numericValue, 10);
    if (Number.isNaN(number)) return '';

    const formattedNumber = number.toLocaleString('en-US', {
      maximumFractionDigits: 0,
    });

    return withoutSign ? formattedNumber : `${formattedNumber}%`;
  }, []);

  const handlePercentageChange = useCallback((value: string, onChange: (value: string
  ) => void, withoutSign: boolean = false) => {
    const trimmedValue = value.trim();
    const formattedValue = formatPercentage(trimmedValue, withoutSign);
    onChange(formattedValue);
  }, [formatPercentage]);

  return {
    formatPercentage,
    handlePercentageChange,
  };
};

export default usePercentageFormat;
