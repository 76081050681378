import { useState } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

import SelectDefaultLanguageModal from '../../components/SelectDefaultLanguageModal/SelectDefaultLanguageModal';
import { AppRoutes } from '../../constants/AppRoutes';
import { useAppSelector } from '../../store/hooks/useApp';
import selectFullScreenActive from '../../store/slices/general/selectors';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

import type { ReactElement } from 'react';
import type IPage from './interfaces/IPage';

import styles from './sass/Page.module.scss';

function Page({ isLanguageSelectorModalOpen = false }: IPage): ReactElement {
  const location = useLocation();
  const { pathname } = location;

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const noPadding = !!matchPath({ path: AppRoutes.companyMap }, pathname);
  const isFullScreenActive = useAppSelector(selectFullScreenActive);

  return isFullScreenActive ? (
    <div className={`page ${styles.root}`}>
      <div className={styles.content}>
        <div className={clsx(styles.main, {
          [styles.noPadding]: noPadding,
        })}
        >
          <Outlet context={{ setIsSidebarOpen }} />
        </div>
      </div>
      <SelectDefaultLanguageModal open={isLanguageSelectorModalOpen} />
    </div>
  ) : (
    <div className={`page ${styles.root}`}>
      <Header />
      <div className={styles.content}>
        {isSidebarOpen ? <Sidebar /> : null}
        <div className={clsx(styles.main, {
          [styles.noPadding]: noPadding,
        })}
        >
          <Outlet context={{ setIsSidebarOpen }} />
        </div>
      </div>
      <SelectDefaultLanguageModal open={isLanguageSelectorModalOpen} />
    </div>
  );
}

export default Page;
