import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { PROFIT_CENTER_MODAL_STEPS } from 'constants/general';

import type IStepCounter from '../../interfaces/IStepCounter';

import styles from '../sass/ProfitCentreModal.module.scss';

function StepCounter({ ownOperation }: IStepCounter) {
  const { t } = useTranslation();

  return (
    <div className={styles.stepCounter}>
      <div className={`${styles.stepCounterItem} ${ownOperation ? styles.completed : styles.active}`}>
        <span className={styles.counter}>
          {ownOperation ? <CheckmarkIcon className={styles.icon} /> : PROFIT_CENTER_MODAL_STEPS.STEP_ONE}
        </span>
        <span>{t('valueChainMap.profitCenter')}</span>
      </div>
      <div className={`${styles.divider} ${ownOperation ? styles.completed : ''}`} />
      <div className={`${styles.stepCounterItem} ${ownOperation ? styles.active : ''}`}>
        <span className={styles.counter}>{PROFIT_CENTER_MODAL_STEPS.STEP_TWO}</span>
        <span>{t('valueChainMap.ownOperation')}</span>
      </div>
    </div>
  );
}

export default StepCounter;
