import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import {
  EU_TAXONOMY_CODE,
  NACE_CODE,
  NACE_SECTION,
} from 'constants/formFields';
import { MENU_POSITION } from 'constants/general';

import type IFieldError from 'store/types/IFieldError';
import type IDataSetPanelOwnOperationsName from '../../interfaces/IDataSetPanelOwnOperationsName';

export default function DataSetPanelOwnOperationsName({
  control,
  naceCodesData,
  onNaceSelectionChange,
  naceDivisions,
  placeholder,
}: IDataSetPanelOwnOperationsName) {
  const { t } = useTranslation();
  return (
    <DataSetsPanel title={t('ownOperations.fields.details')}>
      <Controller
        name={NACE_SECTION}
        control={control}
        render={({
          field: {
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <Select
            placeholder={t('ownOperations.fields.industry')}
            label={t('ownOperations.fields.industry')}
            value={value?.value ? value : undefined}
            options={naceCodesData}
            onChange={(newValue) => {
              onChange(newValue);
              onNaceSelectionChange();
            }}
            required
            error={(error as IFieldError)?.value?.message}
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={NACE_CODE}
        control={control}
        render={({
          field: {
            value,
            onChange,
          },
          fieldState: { error },
        }) => (
          <Select
            placeholder={t('ownOperations.fields.sector')}
            label={t('ownOperations.fields.sector')}
            value={value || null}
            options={naceDivisions}
            error={(error as IFieldError)?.value?.message}
            onChange={onChange}
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={EU_TAXONOMY_CODE}
        control={control}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <TextField
            value={value}
            name={name}
            error={error?.message}
            label={t('ownOperations.fields.taxonomy')}
            placeholder={placeholder}
            onChange={(event) => onChange(event.target.value)}
          />
        )}
      />
    </DataSetsPanel>
  );
}
