import { createSelector } from '@reduxjs/toolkit';
import { UID_NAME_TUPLES } from 'constants/dataSets';
import { MAPPING_SOURCES, MAPPING_TARGET } from 'constants/formFields';

import type IOption from 'components/MappingModal/interfaces/IOption';
import type { RootState } from 'store/types/TStore';
import type IEnumResponse from './interfaces/IEnumResponse';
import type { INaceCodeTreeResponse } from './interfaces/INaceCodeTreeResponse';
import type { INaceData } from './interfaces/INaceData';
import type { INaceExtractedValue } from './interfaces/INaceExtractedValue';

export const selfDataSets = (state: RootState) => state.dataSets;

export const selectProfitCenter = createSelector(
  [selfDataSets],
  ({ selectedProfitCenter }) => selectedProfitCenter,
);

export const selectMappingColumns = createSelector(
  [selfDataSets],
  ({ mappingColumns }) => {
    const target = mappingColumns?.sheetTargets?.Main?.reduce((acc: { [key: string]: string }, item, index) => {
      acc[`${MAPPING_TARGET}_${index}`] = item;
      return acc;
    }, {}) || {};
    const matched = mappingColumns?.map[0]?.map.filter((item) => item?.source)?.length;
    const info = { total: mappingColumns?.map[0]?.map.length, matched };
    const sources = mappingColumns?.sheetTargets?.Main?.reduce((acc: { [key: string]: IOption }, item, index) => {
      const isMatched = !!mappingColumns?.map[0]?.map.find((mapItem) => mapItem.source === item);
      const value = `${MAPPING_SOURCES}_${index}`;

      acc[`${MAPPING_SOURCES}_${index}`] = {
        value, name: value, label: item, isMatched,
      };
      return acc;
    }, {}) || {};

    return { target, sources, info };
  },
);

export const selectSourcesOptions = createSelector(
  [selfDataSets],
  ({ mappingColumns }) => {
    if (!mappingColumns) return [];
    const keys = Object.keys(mappingColumns.sheetSources);
    const sources = mappingColumns?.sheetSources[keys[0]].map(
      (item, index) => (({ value: `${MAPPING_SOURCES}_${index}`, label: item })),
    );
    return sources || [];
  },
);

export const selectEnums = createSelector(
  (data?:IEnumResponse) => data?.data,
  (data) => data?.reduce((acc, item) => {
    const transformedEnum = item?.enum?.map(({ value }) => ({
      label: value,
      value,
    }));
    return { ...acc, [item.name]: transformedEnum };
  }, {}),
);

export const selectUidNameTuples = createSelector(
  (data?:IEnumResponse) => data?.data,
  (data) => data?.reduce((acc, item) => {
    const isSupplier = item.name === UID_NAME_TUPLES.supplier;
    const transformedEnum = item?.enum?.map(({ label, value }) => ({
      label: isSupplier ? label : value,
      value,
    }));
    return { ...acc, [item.name]: transformedEnum };
  }, {}),
);

export const selectProfitCenters = createSelector(
  (data?: IOption[]) => data,
  (data) => data?.reduce<IOption[]>((acc, item) => (
    [...acc, { value: item.value, label: item.value }]
  ), []),
);

const extractLabelsAndValues = (data: INaceData, level: number = 0): INaceExtractedValue[] => {
  if (level > 2) return [];

  return Object.keys(data).map((key) => {
    const item = data[key];
    const result: INaceExtractedValue = {
      label: `${key} - ${item.label}`,
      value: key,
    };

    if (level < 1) {
      if (item.divisions) {
        result.divisions = extractLabelsAndValues(item.divisions, level + 1);
      } else if (item.groups) {
        result.divisions = extractLabelsAndValues(item.groups, level + 1);
      }
    }

    return result;
  });
};

export const selectNaceCodes = createSelector(
  (data?: INaceCodeTreeResponse) => data?.data,
  (data) => (data ? extractLabelsAndValues(data) : []),
);

export default selectMappingColumns;
