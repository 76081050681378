import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { COMPANY_MAP_TABS } from '../../../constants/interfaces';

import type IValueChainMapStore from './interfaces/IValueChainMapStore';

export const initialState: IValueChainMapStore = {
  valueChainMapQueryParams: {
    offset: 0,
    search: '',
    status: undefined,
    orderBy: '-updatedAt',
  },
  currentCompanyMapTab: COMPANY_MAP_TABS.BUSINESS_STRUCTURE,
};

export const valueChainMapSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setValueChainMapState: (
      state,
      { payload }: PayloadAction<Partial<IValueChainMapStore>>,
    ) => ({ ...state, ...payload }),
    resetState: () => initialState,
  },
  extraReducers: () => {},
});

export const {
  resetState, setValueChainMapState,
} = valueChainMapSlice.actions;

export default valueChainMapSlice.reducer;
