import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/Checkbox/Checkbox';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import {
  AIR, ARCTIC_TRUCK, DISTANCE, MONETARY_VOLUMES, QUANTITY, RAIL,
  RIGID_TRUCK, SEA, TRUCK, UNIT_CURRENCY, UNIT_MONETARY_VOLUMES, VAN,
} from 'constants/formFields';

import usePercentageFormat from '../hooks/usePercentageFormat';

import type IFieldError from 'store/types/IFieldError';
import type { TConnectionVCSchema } from 'utils/validators';
import type ISupplierForm from '../interfaces/ISupplierForm';

import styles from '../scss/SupplierForm.module.scss';

function SupplierForm({ control, enumData }: ISupplierForm) {
  const { t } = useTranslation();
  const { handlePercentageChange } = usePercentageFormat();
  const supplierFormRef = useRef<HTMLDivElement>(null);
  const transportOptions: { label: string, name: keyof TConnectionVCSchema }[] = [
    {
      label: t('valueChainMap.air'),
      name: AIR,
    },
    {
      label: t('valueChainMap.van'),
      name: VAN,
    },
    {
      label: t('valueChainMap.rigidTruck'),
      name: RIGID_TRUCK,
    },
    {
      label: t('valueChainMap.sea'),
      name: SEA,
    },
    {
      label: t('valueChainMap.truck'),
      name: TRUCK,
    },
    {
      label: t('valueChainMap.arcticTruck'),
      name: ARCTIC_TRUCK,
    },
    {
      label: t('valueChainMap.rail'),
      name: RAIL,
    },
  ];

  return (
    <div className={styles.container} ref={supplierFormRef}>
      <div className={styles.transportContainer}>
        <span className={styles.transportLabel}>
          {t('valueChainMap.transportMethod')}
        </span>
        <div className={styles.transportOptions}>
          {transportOptions.map((item) => (
            <div
              key={item.name}
              className={styles.checkboxWrapper}
            >
              <div className={styles.checkbox}>
                <Controller
                  control={control}
                  name={item.name}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      name={item.name}
                      checked={value as boolean}
                      value={String(value)}
                      onChange={onChange}
                      label={(<span>{item.label}</span>)}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.distanceWrapper}>
        <Controller
          control={control}
          name={DISTANCE}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              className={styles.field}
              name={name}
              value={value}
              error={error?.message}
              onChange={onChange}
              label={t('valueChainMap.distance')}
              maxLength={64}
            />
          )}
        />
      </div>
      <div className={styles.monetaryVolumesWrapper}>
        <div className={styles.leftWrapper}>
          <Controller
            control={control}
            name={MONETARY_VOLUMES}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                className={styles.fieldTextField}
                name={name}
                value={value}
                error={error?.message}
                onChange={(event) => handlePercentageChange(event.target.value, onChange, true)}
                label={t('valueChainMap.monetaryVolumes')}
              />
            )}
          />
          <Controller
            control={control}
            name={UNIT_MONETARY_VOLUMES}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                className={styles.fieldSelect}
                options={enumData.currency}
                placeholder={t('valueChainMap.currency')}
                value={value ?? ''}
                onChange={onChange}
                menuPosition="absolute"
                placeholderClassName={styles.placeholder}
                modalRef={supplierFormRef}
                maxMenuHeight={150}
                error={(error as IFieldError)?.value?.message}
                hideErrorText
              />
            )}
          />
        </div>
        <div className={styles.rightWrapper}>
          <Controller
            control={control}
            name={QUANTITY}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                className={styles.fieldTextField}
                name={name}
                value={value}
                error={error?.message}
                onChange={(event) => handlePercentageChange(event.target.value, onChange, true)}
                label={t('valueChainMap.quantity')}
              />
            )}
          />
          <Controller
            control={control}
            name={UNIT_CURRENCY}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                className={styles.fieldSelect}
                options={enumData.volumeQtyUnit}
                placeholder={t('valueChainMap.unit')}
                value={value ?? ''}
                onChange={onChange}
                menuPosition="absolute"
                placeholderClassName={styles.placeholder}
                modalRef={supplierFormRef}
                maxMenuHeight={150}
                hideErrorText
                error={(error as IFieldError)?.value?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default SupplierForm;
