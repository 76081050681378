import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import { DATA_SET_MAX_MENU_HEIGHT } from 'constants/dataSets';
import { MAPPING_FIELDS_PREFIX } from 'constants/general';

import type IMultipleHeaderItemProps from '../interfaces/IMultipleHeaderItemProps';

import styles from '../scss/MappingMultipleModal.module.scss';

function MultipleHeaderItem({
  index,
  item,
  control,
  sheetSources,
  wrapperRef,
}: IMultipleHeaderItemProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.headerForm}>
      <div className={styles.headerWrapper}>
        <div>{t('dataSetsManagement.mappingModal.targetSheet')}</div>
        <div className={styles.paddingLeft}>{t('dataSetsManagement.mappingModal.sourceSheet')}</div>
      </div>
      <div className={styles.containerHeader}>
        <div className={styles.numberContainer}>
          <span className={styles.number}>{index + 1}</span>
        </div>
        <Controller
          control={control}
          name={item}
          render={({ field: { name, value, onChange } }) => (
            <TextField
              className={styles.field}
              name={name}
              value={value as string}
              onChange={(event) => onChange(event.target.value)}
            />
          )}
        />
        <div className={styles.arrow}>
          <ChevronIcon />
        </div>
        <Controller
          control={control}
          name={`${MAPPING_FIELDS_PREFIX.source}${item}`}
          render={({ field: { value, onChange } }) => (
            <Select
              className={styles.field}
              options={Object.keys(sheetSources).map((itemKey) => ({
                value: itemKey,
                label: itemKey,
              }))}
              placeholder={t('dataSetsManagement.mappingModal.selectPlaceholder')}
              value={value === '' ? undefined : value}
              onChange={onChange}
              menuPosition="absolute"
              required
              placeholderClassName={styles.placeholder}
              maxMenuHeight={DATA_SET_MAX_MENU_HEIGHT}
              modalRef={wrapperRef}
              classNameMenu={styles.menuMultiple}
            />
          )}
        />
      </div>
    </div>
  );
}

export default MultipleHeaderItem;
