import {
  END_DATE,
  FINISHED_GOOD_STOCK_AMOUNT,
  FINISHED_GOOD_STOCK_AMOUNT_CURRENCY,
  GROSS_MARGIN_ROLLING_AMOUNT,
  GROSS_MARGIN_ROLLING_CURRENCY,
  GROSS_MARGIN_ROLLING_PERCENTS,
  PROFIT_CENTRE_NAME, PROFIT_CENTRE_UNIQUE_IDENTIFIER,
  REVENUE_ROLLING_AMOUNT,
  REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
  START_DATE,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT, DATE_FORMAT_ISO } from 'constants/general';
import dayjs from 'dayjs';

import type { IGetRequestDataForProfitCentre } from './interfaces/IGetRequestDataForProfitCentre';

function getRequestDataForProfitCentre({ values, recordId }: IGetRequestDataForProfitCentre) {
  return {
    [PROFIT_CENTRE_NAME]: values?.[PROFIT_CENTRE_NAME] || null,
    [REVENUE_ROLLING_PERCENTS]: values?.[REVENUE_ROLLING_PERCENTS]
      ? Number.parseFloat((values[REVENUE_ROLLING_PERCENTS])) : null,
    [GROSS_MARGIN_ROLLING_PERCENTS]: values?.[GROSS_MARGIN_ROLLING_PERCENTS]
      ? Number.parseFloat((values[GROSS_MARGIN_ROLLING_PERCENTS])) : null,
    [REVENUE_ROLLING_AMOUNT]: values?.[REVENUE_ROLLING_AMOUNT] ? {
      amount: Number(values?.[REVENUE_ROLLING_AMOUNT]),
      currency: values?.[REVENUE_ROLLING_CURRENCY].value,
    } : null,
    [GROSS_MARGIN_ROLLING_AMOUNT]: values?.[GROSS_MARGIN_ROLLING_AMOUNT] ? {
      amount: Number(values?.[GROSS_MARGIN_ROLLING_AMOUNT]),
      currency: values?.[GROSS_MARGIN_ROLLING_CURRENCY].value,
    } : null,
    [FINISHED_GOOD_STOCK_AMOUNT]: values?.[FINISHED_GOOD_STOCK_AMOUNT] ? {
      amount: Number(values?.[FINISHED_GOOD_STOCK_AMOUNT]),
      currency: values?.[FINISHED_GOOD_STOCK_AMOUNT_CURRENCY].value,
    } : null,
    [START_DATE]: values?.[START_DATE] ? dayjs(values?.[START_DATE], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [END_DATE]: values?.[END_DATE] ? dayjs(values?.[END_DATE], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    ...(!recordId && { [PROFIT_CENTRE_UNIQUE_IDENTIFIER]: values?.[PROFIT_CENTRE_UNIQUE_IDENTIFIER] }),
    ...(recordId && { id: Number(recordId) }),
  };
}

export default getRequestDataForProfitCentre;
