import { generatePath } from 'react-router-dom';
import { ReactComponent as CompaniesIcon } from 'assets/icons/companies.svg';
import { ReactComponent as FingerprintIcon } from 'assets/icons/fingerprint.svg';
import { ReactComponent as GlobIcon } from 'assets/icons/globe.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/helpCircle.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as MaterialityIcon } from 'assets/icons/materiality.svg';
import { ReactComponent as ModuleIcon } from 'assets/icons/module.svg';
import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg';
import { ReactComponent as Permissions } from 'assets/icons/permissions.svg';
import { ReactComponent as PlanetIcon } from 'assets/icons/planet.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as UserSettingsIcon } from 'assets/icons/user-settings.svg';
import { ReactComponent as ValueChainIcon } from 'assets/icons/valueChain.svg';
import { t } from 'i18next';

import { AppRoutes } from './AppRoutes';
import { FILTER_TABS, USER_PILLARS, USERS_ROLE } from './interfaces';

import type { ReactNode } from 'react';
import type IOwnOperationsFilterNav
  from '../pages/UserPages/OwnOperations/components/FiltersBar/interfaces/IOwnOperationsFilterNav';
import type { TUserPillars, TUsersRole } from './interfaces';
import type { ICreateNewUserNav } from './interfaces/ICreateNewUserNav';

export const clientsNav = (clientId: string) => [
  {
    key: 'companyInfo',
    name: t('clients.companyInfo'),
    icon: <InfoIcon />,
    to: generatePath(AppRoutes.companyInfo, { clientId }),
  },
  {
    key: 'multiLingual',
    name: t('clients.multiLingual'),
    icon: <PlanetIcon />,
    to: generatePath(AppRoutes.multiLingual, { clientId }),
  },
  {
    key: 'companyAdmin',
    name: t('clients.compAdmin'),
    icon: <UserSettingsIcon />,
    to: generatePath(AppRoutes.companyAdmin, { clientId }),
  },
  {
    key: 'uiConfiguration',
    name: t('clients.uiConfiguration'),
    icon: <PaletteIcon />,
    to: generatePath(AppRoutes.uiConfiguration, { clientId }),
  },
  {
    key: 'moduleManagement',
    name: t('clients.moduleManagement'),
    icon: <ModuleIcon />,
    to: generatePath(AppRoutes.moduleManagement, { clientId }),
  },
  {
    key: 'security',
    name: t('clients.security'),
    icon: <FingerprintIcon />,
    to: generatePath(AppRoutes.security, { clientId }),
  },
];

export const loginSecurityNav = () => [
  {
    key: 'password',
    name: t('profilePassword.title'),
    icon: <LockIcon />,
    to: AppRoutes.profilePassword,
  },
  {
    key: 'security',
    name: t('profileSecurity.title'),
    icon: <FingerprintIcon />,
    to: generatePath(AppRoutes.profileSecurity),
  },
];

export const createNewUserNav = ({ userId, isCreateFlow }: ICreateNewUserNav) => [
  {
    key: 'password',
    name: t('createNewUser.menu.userInfo'),
    icon: <LockIcon />,
    to: userId && !isCreateFlow ? generatePath(AppRoutes.editUserInfo, { userId }) : AppRoutes.createNewUser,
  },
  {
    key: 'security',
    name: t('createNewUser.menu.permissions'),
    icon: <Permissions />,
    to: userId && !isCreateFlow ? generatePath(AppRoutes.editUserPermissions, { userId })
      : generatePath(AppRoutes.createUserPermissions, { userId }),
  },
];

export const clientsInfoNav = (clientId: string) => [
  {
    key: 'clientProfile',
    name: t('common.companyProfile'),
    to: generatePath(AppRoutes.clientProfile, { clientId }),
  },
  {
    key: 'clientUsers',
    name: t('common.users'),
    to: generatePath(AppRoutes.clientUsers, { clientId }),
  },
];

const baseNav = [
  {
    key: USER_PILLARS.valueChainAssessment,
    icon: <ValueChainIcon width={30} height={30} />,
    to: '/',
  },
  {
    key: USER_PILLARS.materialityAssessment,
    icon: <MaterialityIcon width={30} height={30} />,
    to: '/',
  },
  {
    key: USER_PILLARS.settings,
    icon: <SettingsIcon width={30} height={30} />,
    to: AppRoutes.launchpadSettings,
  },
];

export const sidebarNav: Record<TUsersRole, {
  key: TUserPillars,
  to: string,
  icon: ReactNode,
}[]> = {
  [USERS_ROLE.admin]: [
    {
      key: 'clients',
      icon: <CompaniesIcon width={30} height={30} />,
      to: AppRoutes.clients,
    },
    {
      key: 'help',
      icon: <HelpIcon width={30} height={30} />,
      to: '/',
    },
    {
      key: 'globe',
      icon: <GlobIcon width={30} height={30} />,
      to: AppRoutes.baseMultilingual,
    },
  ],
  [USERS_ROLE.companyAdmin]: baseNav,
  [USERS_ROLE.administrator]: baseNav,
  [USERS_ROLE.facilitator]: baseNav,
  [USERS_ROLE.contributor]: baseNav,
  [USERS_ROLE.auditor]: baseNav,
  [USERS_ROLE.signatory]: baseNav,
};

export const dataSetsNav = () => [
  {
    key: 'ownOperations',
    name: t('dataSetsManagement.menu.ownOperations'),
    to: AppRoutes.ownOperations,
  },
  {
    key: 'suppliers',
    name: t('dataSetsManagement.menu.suppliers'),
    to: AppRoutes.suppliers,
  },
  {
    key: 'customers',
    name: t('dataSetsManagement.menu.customers'),
    to: AppRoutes.customers,
  },
  {
    key: 'products',
    name: t('dataSetsManagement.menu.products'),
    to: AppRoutes.products,
  },
  {
    key: 'profitCentres',
    name: t('dataSetsManagement.menu.profitCentres'),
    to: AppRoutes.profitCentres,
  },
];

export const ownOperationsFilterNav = (): IOwnOperationsFilterNav[] => [
  {
    key: FILTER_TABS.organizational,
    name: t('ownOperations.filter.menu.organizational'),
  },
  {
    key: FILTER_TABS.geographical,
    name: t('ownOperations.filter.menu.geographical'),
  },
];
