import { clsx } from 'clsx';

import { VALUE_CHAIN_UNIT_TYPES } from '../../../../../../constants/interfaces';
import CustomerNode from './CustomerNode';
import CustomUnitContainer from './CustomUnitContainer';
import InputProductNode from './InputProductNode';
import OwnOperationNode from './OwnOperationNode';
import ProfitCentreNode from './ProfitCentreNode';
import SupplierNode from './SupplierNode';

import type { IValueChainRecord } from '../../../../../../store/slices/valueChainMap/interfaces/IValueChainRecord';
import type { ICustomUnitNode } from '../interfaces/ICustomUnitNode';

import styles from '../sass/CustomUnitNode.module.scss';

function CustomUnitNode({ data, parentId }: ICustomUnitNode) {
  const {
    childrenCount, type, records,
  } = data;

  const renderNodeContent = ({
    name, naceSection, addressCountry, profitCenterId, keySupplierTier1Total, totalRevenueRolling12MActual,
    relianceOnFreeNaturalResources, criticalityRating, netBookValue, assetCategory, totalRevenueRolling12MPercents,
    keySupplierTier1OfTotal, wipFinishedGoodsStock, uniqueIdentifier,
  }: IValueChainRecord) => {
    switch (type) {
      case VALUE_CHAIN_UNIT_TYPES.OWN_OPERATION:
        return (
          <OwnOperationNode
            name={name}
            addressCountry={addressCountry}
            profitCenterId={profitCenterId}
            assetCategory={assetCategory}
            naceSection={naceSection}
            netBookValue={netBookValue}
          />
        );
      case VALUE_CHAIN_UNIT_TYPES.PROFIT_CENTER:
        return (
          <ProfitCentreNode
            name={name}
            uniqueIdentifier={uniqueIdentifier}
            netBookValue={netBookValue}
            totalGrossMarginRolling12MActual={totalRevenueRolling12MActual}
            totalGrossMarginRolling12MPercents={totalRevenueRolling12MPercents}
            totalRevenueRolling12MActual={totalRevenueRolling12MActual}
            totalRevenueRolling12MPercents={totalRevenueRolling12MPercents}
            wipFinishedGoodsStock={wipFinishedGoodsStock}
          />
        );
      case VALUE_CHAIN_UNIT_TYPES.CUSTOMER:
        return (
          <CustomerNode
            profitCenterId={profitCenterId}
            name={name}
            addressCountry={addressCountry}
            totalRevenueRolling12MPercents={totalRevenueRolling12MPercents}
            totalRevenueRolling12MActual={totalRevenueRolling12MActual}
            naceSection={naceSection}
          />
        );
      case VALUE_CHAIN_UNIT_TYPES.PRODUCT:
        return (
          <InputProductNode
            name={name}
            profitCenterId={profitCenterId}
            criticalityRating={criticalityRating}
            relianceOnFreeNaturalResources={relianceOnFreeNaturalResources}
          />
        );
      case VALUE_CHAIN_UNIT_TYPES.SUPPLIER:
        return (
          <SupplierNode
            name={name}
            profitCenterId={profitCenterId}
            naceSection={naceSection}
            keySupplierTier1OfTotal={keySupplierTier1OfTotal}
            keySupplierTier1Total={keySupplierTier1Total}
            addressCountry={addressCountry}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={clsx(styles.unitContainer, {
      [styles.dragTarget]: data.isDragTarget,
    })}
    >
      {records.map((record) => (
        <CustomUnitContainer
          key={`records_${record.id}`}
          isChild={!!parentId}
          type={type}
          businessUnitType={record.businessUnitType}
          estimated={record.estimated}
          childrenCount={childrenCount}
        >
          {renderNodeContent(record)}
        </CustomUnitContainer>
      ))}
    </div>
  );
}

export default CustomUnitNode;
