export default {
  title: 'Products',
  subTitle: 'Lorem ipsum dolor',
  totalPages: '{{offset}}-{{totalPages}} of {{total}}',
  archivingReasonTitle: 'Specify reason for archiving',
  archivingReasonDescription: 'Please provide a reason for archiving the selected record(s). You can restore '
        + 'them anytime.',
  archiveDetails: 'Archived record details',
  editInformation: 'Edit Information',
  addNewInputProduct: 'Add New Input Product',
  editInputProduct: 'Edit Input Product',
  fields: {
    productName: 'Input Product Name',
    productCode: 'Input Product Code',
    uniqueIdentifier: 'Unique Identifier',
    profitCentreId: 'Profit Centre ID',
    supplier: 'Supplier',
    supplierId: 'Supplier ID',
    addSupplier: 'Add supplier',
    dateFrom: 'Date From',
    dateTo: 'Date To',
    volume: 'Volume Qty',
    volumeUnit: 'Volume Unit',
    equityControl: 'Equity Control %',
    expectedGrowth: 'Expected Growth / Decline %',
    relianceNatural: 'Reliance on \'Free\' Natural Resources',
    relianceSocial: 'Reliance on \'Free\' Social Resources',
    rating: 'Criticality Rating',
    circularityPercentage: '% Circularity from Downstream \'Waste\'',
    suppliersTier1: 'Suppliers Tier 1 Total Cost of Sales Spend (rolling 12 months)',
    suppliersTier1InPercentage: 'Suppliers Tier 1 % of Total Cost of Sales Spend (rolling 12 months)',
    actions: 'Actions',
    upstreamTitle: 'Upstream Title',
    upstreamTier: 'Upstream Tier',
    startDate: 'Start Date',
    endDate: 'End Date',
  },
  selectionsGeneral: 'General',
  selectionsFinancials: 'Financials',
};
