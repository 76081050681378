import ValueChainMapFilters from './components/ValueChainMapFilters/ValueChainMapTableFilters';
import ValueChainMapHeading from './components/ValueChainMapHeading/ValueChainMapTableHeading';
import ValueChainMapTable from './components/ValueChainMapTable/ValueChainMapTable';

import type IValueChainView from './interfaces/IValueChainView';

import styles from './scss/ClientsView.module.scss';

function ValueChainMapView({
  control, valueChainList, onSearch, onFilterChange, onSortChange, onDeleteValueChain,
  onReadMoreClients, hasNext, isWithSearch, isPageLoading, onChangeStatus, onRenameValueChain, onEditValueChain,
  onArchiveValueChain, onDeArchiveValueChain, onDublicateValueChain, onCreateNewValueChain, onReviewValueChain,
  disableCreateMap,
}: IValueChainView) {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <ValueChainMapHeading
          isEmpty={!valueChainList.length && !isWithSearch}
          onCreateNewValueChain={onCreateNewValueChain}
          disableCreateMap={disableCreateMap}
        />
        {
          (valueChainList.length !== 0 || isWithSearch) ? (
            <ValueChainMapFilters
              control={control}
              onSearch={onSearch}
              onFilterChange={onFilterChange}
            />
          ) : null
        }
        <ValueChainMapTable
          control={control}
          onSortChange={onSortChange}
          companies={valueChainList}
          onReadMoreClients={onReadMoreClients}
          hasNext={hasNext}
          isWithSearch={isWithSearch}
          isPageLoading={isPageLoading}
          onChangeStatus={onChangeStatus}
          onRenameValueChain={onRenameValueChain}
          onDeleteValueChain={onDeleteValueChain}
          onEditValueChain={onEditValueChain}
          onArchiveValueChain={onArchiveValueChain}
          onDeArchiveValueChain={onDeArchiveValueChain}
          onDublicateValueChain={onDublicateValueChain}
          onCreateNewValueChain={onCreateNewValueChain}
          onReviewValueChain={onReviewValueChain}
        />
      </div>
    </div>
  );
}

export default ValueChainMapView;
