import React, { useCallback, useEffect, useState } from 'react';
import {
  addEdge, type Connection, type Edge, MarkerType, type Node, useEdgesState, useNodesState,
} from '@xyflow/react';

import { REACT_FLOW } from '../../../../../constants/general';
import { BUSINESS_STRUCTURE_MODALS } from '../../../../../constants/interfaces';
import useCreateNodesAndEdgesBusinessStructure from '../../../../../hooks/useCreateNodesAndEdgesBusinessStructure';
import {
  useGetValueChainMapBusinessModelQuery,
  useUpdateBusinessStructureOrgPathMutation,
  useUpdateBusinessStructureStatusMutation,
} from '../../../../../store/slices/valueChainMap/apis/valueChainMapApi';
import BusinessStructureView from './BusinessStructureView';

import type { TBusinessStructureModal } from '../../../../../constants/interfaces';
import type { IProfitCenter } from '../../../../../store/slices/valueChainMap/interfaces/IProfitCenter';
import type { IBusinessStructure } from '../interfaces/IBusinessStructure';

function BusinessStructure({
  valueChainMapId,
  onMoveEnd,
  isFullScreenActive,
  openProfitCenterModal,
  isAccessDisabled,
}: IBusinessStructure) {
  const { data, isLoading } = useGetValueChainMapBusinessModelQuery({ valueChainMapId: valueChainMapId || '' });
  const [updateBusinessStructureStatus, {
    isLoading: isUpdateBusinessStructureStatusLoading,
  }] = useUpdateBusinessStructureStatusMutation();
  const [updateBusinessStructureOrgPath, {
    isLoading: isUpdateBusinessStructureOrgPathLoading,
  }] = useUpdateBusinessStructureOrgPathMutation();

  const [nodes, setNodes, onNodesChange] = useNodesState([] as Node[]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge[]);
  const [disablingItem, setDisablingItem] = useState<IProfitCenter | undefined>(undefined);
  const [currentOpenedModal, setCurrentOpenedModal] = useState<TBusinessStructureModal | undefined>(undefined);
  const [draggingNode, setDraggingNode] = useState<Node | undefined>(undefined);
  const [targetNode, setTargetNode] = useState<Node | undefined>(undefined);

  const updateEdgeStyles = (edgesData: Edge[]): Edge[] => edgesData.map((e) => ({
    ...e,
    type: 'custom',
    style: { stroke: '#5A5F60' },
    animated: false,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#5A5F60',
      width: 16,
      height: 18,
    },
  }));

  const onActiveStatusCheck = useCallback((item: IProfitCenter) => {
    setDisablingItem(item);
    setCurrentOpenedModal(BUSINESS_STRUCTURE_MODALS.CONFIRM_DISABLE);
  }, []);

  const onDisabledInfoPress = useCallback((item: IProfitCenter) => {
    setDisablingItem(item);
    setCurrentOpenedModal(BUSINESS_STRUCTURE_MODALS.DISABLED_INFO);
  }, []);

  const { createNodesAndEdges } = useCreateNodesAndEdgesBusinessStructure(
    onActiveStatusCheck,
    onDisabledInfoPress,
    isAccessDisabled,
  );

  useEffect(() => {
    if (data) {
      const { nodes: newNodes, edges: newEdges } = createNodesAndEdges(data);
      setEdges(newEdges);
      setNodes(newNodes);
    }
  }, [createNodesAndEdges, setEdges, setNodes, data]);

  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges],
  );

  const onDisableConfirm = (reason: string) => {
    updateBusinessStructureStatus({
      valueChainMapId: Number(valueChainMapId),
      orgPath: disablingItem?.orgPath || '',
      disableReason: reason,
      isDisabled: !disablingItem?.isDisabled,
    }).unwrap().then(() => {
      setCurrentOpenedModal(undefined);
      setDisablingItem(undefined);
    });
  };

  const closeModal = () => {
    setCurrentOpenedModal(undefined);
    setDisablingItem(undefined);
  };

  const onDragStart = (event: React.MouseEvent<Element, MouseEvent>, node: Node) => {
    event.preventDefault();
    setDraggingNode(node);
  };

  const onNodeDrag = (event: React.MouseEvent<Element, MouseEvent>, node: Node) => {
    event.preventDefault();
    // calculate the center point of the node from position and dimensions
    const centerX = node.position.x;
    const centerY = node.position.y;

    // find if there is a node close to the center point
    const closeNode = nodes.find((n) => {
      let result = false;
      if (n.id !== node.id) {
        const targetCenterX = n.position.x;
        const targetCenterY = n.position.y;
        result = Math.abs(centerX - targetCenterX) < REACT_FLOW.DRAG_N_DROP_ENOUGH_DISTANCE
          && Math.abs(centerY - targetCenterY) < REACT_FLOW.DRAG_N_DROP_ENOUGH_DISTANCE;
      }
      return result;
    });

    // set the isDragTarget for the close node
    if (closeNode) {
      setNodes((prevNodes) => prevNodes.map((prevNode) => (prevNode.id === closeNode.id
        ? { ...closeNode, data: { ...closeNode.data, isDragTarget: true } }
        : prevNode)));
    } else {
      setNodes((prevNodes) => prevNodes.map((prevNode) => (
        {
          ...prevNode,
          data: { ...prevNode.data, isDragTarget: false },
        })));
    }

    setTargetNode(closeNode);
  };

  const onDragStop = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    if (targetNode) {
      setCurrentOpenedModal(BUSINESS_STRUCTURE_MODALS.CONFIRM_STRUCTURE_CHANGE);
    }
  };

  const onConfirmStructureChange = () => {
    if (typeof targetNode?.data.orgPath === 'string' && typeof draggingNode?.data.orgPath === 'string') {
      updateBusinessStructureOrgPath({
        valueChainMapId: Number(valueChainMapId),
        targetParentOrgPath: targetNode?.data.orgPath || '',
        childrenOrgPath: draggingNode?.data.orgPath || '',
      }).unwrap().then(() => {
        setTargetNode(undefined);
        setDraggingNode(undefined);
        setCurrentOpenedModal(undefined);
      });
    }
  };

  const onCancelStructureChange = () => {
    closeModal();
    setNodes((prevNodes) => prevNodes.map((prevNode) => {
      let result = prevNode;
      if (prevNode.id === draggingNode?.id) {
        result = { ...prevNode, position: draggingNode?.position };
      }
      if (prevNode.id === targetNode?.id) {
        result = { ...prevNode, data: { ...prevNode.data, isDragTarget: false } };
      }
      return result;
    }));
    setTargetNode(undefined);
    setDraggingNode(undefined);
  };

  return (
    <BusinessStructureView
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      edges={edges}
      nodes={nodes}
      updateEdgeStyles={updateEdgeStyles}
      onConnect={!isAccessDisabled ? onConnect : () => {}}
      onMoveEnd={!isAccessDisabled ? onMoveEnd : () => {}}
      isLoading={isLoading || isUpdateBusinessStructureStatusLoading || isUpdateBusinessStructureOrgPathLoading}
      currentOpenedModal={currentOpenedModal}
      closeModal={closeModal}
      onDisableConfirm={onDisableConfirm}
      onDragStart={!isAccessDisabled ? onDragStart : () => {}}
      onDragStop={!isAccessDisabled ? onDragStop : () => {}}
      onNodeDrag={!isAccessDisabled ? onNodeDrag : () => {}}
      onConfirmStructureChange={onConfirmStructureChange}
      targetNode={targetNode}
      draggingNode={draggingNode}
      onCancelStructureChange={onCancelStructureChange}
      openProfitCenterModal={openProfitCenterModal}
      disablingItem={disablingItem}
      isFullScreenActive={isFullScreenActive}
      isAccessDisabled={isAccessDisabled}
    />
  );
}

export default BusinessStructure;
