import { clsx } from 'clsx';

import type { ICustomBoardNode } from '../interfaces/ICustomBoardNode';

import styles from '../sass/CustomBoardNode.module.scss';

function CustomBoardNode({ data }: ICustomBoardNode) {
  return (
    <div
      className={clsx(styles.container, {
        [styles.dragTarget]: data.isDragTarget === true,
      })}
      style={{ height: data.accumulatedMaxBoardHeight || 'auto' }}
    >
      <p className={styles.title}>{data.name}</p>
      <div
        style={{ width: data.boardWidth }}
      />
    </div>
  );
}

export default CustomBoardNode;
