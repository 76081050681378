import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import {
  ADDRESS_BUILDING,
  ADDRESS_CITY,
  ADDRESS_COORDINATES,
  ADDRESS_COUNTRY,
  ADDRESS_POST_CODE,
  ADDRESS_STATE,
  ADDRESS_STREET,
} from 'constants/formFields';
import { MENU_POSITION } from 'constants/general';

import type IDataSetPanelOwnOperationsAdress from '../../interfaces/IDataSetPanelOwnOperationsAdress';

import styles from '../sass/ProfitCentreModal.module.scss';

function DataSetPanelOwnOperationsAdress({
  control,
  onChangeCountry,
  onFindCountries,
  onChangeState,
  isStatesDisabled,
  onFindStates,
  isCitiesDisabled,
  onFindCities,
  placeholder,
  modalRef,
}: IDataSetPanelOwnOperationsAdress) {
  const { t } = useTranslation();
  return (
    <DataSetsPanel title={t('ownOperations.selections.address')}>
      <Controller
        name={ADDRESS_COUNTRY}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Select
            placeholder={null}
            value={value || null}
            label={t('ownOperations.fields.country')}
            onChange={(newValue) => {
              onChange(newValue);
              onChangeCountry();
            }}
            className={styles.asyncSelect}
            loadOptions={onFindCountries}
            isSearchable
            error={error?.message}
            isClearable
            isAsync
            required
            modalRef={modalRef}
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={ADDRESS_STATE}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Select
            placeholder={null}
            label={t('ownOperations.fields.state')}
            value={value}
            onChange={(newValue) => {
              onChange(newValue);
              onChangeState();
            }}
            loadOptions={onFindStates}
            error={error?.message}
            isAsync
            isSearchable
            className={clsx(styles.asyncSelect, {
              [styles.inactiveSelect]: isStatesDisabled,
            })}
            isClearable
            required
            isDisabled={isStatesDisabled}
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={ADDRESS_POST_CODE}
        control={control}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <TextField
            value={value}
            name={name}
            error={error?.message}
            onChange={(event) => onChange(event.target.value)}
            label={t('ownOperations.fields.post')}
            placeholder={placeholder}
          />
        )}
      />
      <Controller
        name={ADDRESS_CITY}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Select
            label={t('ownOperations.fields.town')}
            value={value || null}
            placeholder={null}
            error={error?.message}
            onChange={onChange}
            isSearchable
            isDisabled={isCitiesDisabled}
            loadOptions={onFindCities}
            isClearable
            className={clsx(styles.asyncSelect, {
              [styles.inactiveSelect]: isCitiesDisabled || isStatesDisabled,
            })}
            isAsync
            menuPosition={MENU_POSITION.ABSOLUTE}
          />
        )}
      />
      <Controller
        name={ADDRESS_STREET}
        control={control}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <TextField
            placeholder={placeholder}
            label={t('ownOperations.fields.street')}
            value={value}
            name={name}
            onChange={(event) => onChange(event.target.value)}
            error={error?.message}
          />
        )}
      />
      <Controller
        name={ADDRESS_BUILDING}
        control={control}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <TextField
            placeholder={placeholder}
            name={name}
            label={t('ownOperations.fields.building')}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            error={error?.message}
          />
        )}
      />
      <Controller
        name={ADDRESS_COORDINATES}
        control={control}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <TextField
            value={value}
            name={name}
            error={error?.message}
            label={t('ownOperations.fields.coordinates')}
            placeholder={placeholder}
            onChange={(event) => onChange(event.target.value)}
          />
        )}
      />
    </DataSetsPanel>
  );
}

export default DataSetPanelOwnOperationsAdress;
