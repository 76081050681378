import {
  createContext, useContext, useMemo, useState,
} from 'react';

import type { TValueChainPanelNodesType } from '../../../../../../constants/interfaces';
import type { IDndProvider } from '../interfaces/IDndProvider';
import type { TContextType } from '../interfaces/TContextType';

const DndContext = createContext<TContextType>(['', () => {}]);

export function DndProvider({ children }: IDndProvider) {
  const [panelType, setPanelType] = useState<TValueChainPanelNodesType | ''>('');
  const value: TContextType = useMemo(() => [panelType, setPanelType], [panelType, setPanelType]);

  return (
    <DndContext.Provider value={value}>
      {children}
    </DndContext.Provider>
  );
}

export default DndContext;

export const useDnD = () => useContext(DndContext);
