import {
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
} from '@xyflow/react';
import { ReactComponent as TruckIcon } from 'assets/icons/car.svg';
import { ReactComponent as PlaneIcon } from 'assets/icons/plane.svg';
import { ReactComponent as SeaIcon } from 'assets/icons/ship.svg';
import { ReactComponent as RailIcon } from 'assets/icons/train.svg';

import {
  VALUE_CHAIN_CONNECTION_TYPES,
} from '../../../../../../constants/interfaces';

import type { TTransportMethod } from '../../../../../../constants/interfaces';
import type { ICustomEdge } from '../interfaces/ICustomEdge';

import '../sass/CustomEdge.scss';

function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}: ICustomEdge) {
  const offsetSourceY = sourceY + (data?.sourceOffset || 0);
  const offsetTargetY = targetY + (data?.targetOffset || 0);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY: offsetSourceY,
    sourcePosition,
    targetX,
    targetY: offsetTargetY,
    targetPosition,
    borderRadius: 40,
  });

  const transportMethodIcons: Record<TTransportMethod, JSX.Element> = {
    truck: <TruckIcon width={20} height={20} />,
    air: <PlaneIcon width={20} height={20} />,
    sea: <SeaIcon width={20} height={20} />,
    rail: <RailIcon width={20} height={20} />,
  };

  const renderIcons = () => data?.connection?.transportMethods
    ?.filter((method) => Object.keys(transportMethodIcons).includes(method))
    .map((method) => (
      <div className="button-edge__icon" key={method}>
        {transportMethodIcons[method]}
      </div>
    )) ?? null;

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={`url('#edge-marker-${data?.connectionType}')`}
        className={`button-edge__connectionLine ${data?.connectionType}`}
      />
      <EdgeLabelRenderer>
        <div
          className="button-edge__wrapper"
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          {data?.connectionType === VALUE_CHAIN_CONNECTION_TYPES.SUPPLIER_OF_GOODS ? (
            renderIcons()
          ) : null}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default CustomEdge;
