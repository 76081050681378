import React from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { clsx } from 'clsx';
import IconButton from 'components/IconButton/IconButton';

import type ITooltipPanel from './interfaces/ITooltipPanel';

import styles from './scss/TooltipPanel.module.scss';

function TooltipPanel({
  className, children, hintClassName, isDisabled, onEdit, onDelete, hideDeleteButton, isHovered,
  isAccessDisabled,
}: ITooltipPanel) {
  return (
    <div
      className={clsx(styles.root, className)}
    >
      {children}
      {!isAccessDisabled && (
        <p className={clsx(styles.hint, hintClassName, {
          [styles.hideElement]: isDisabled,
          [styles.isHovered]: isHovered,
        })}
        >
          <IconButton
            icon={<PenIcon />}
            onClick={() => onEdit()}
            className={styles.iconButton}
          />
          {hideDeleteButton ? (
            <IconButton
              icon={<DeleteIcon />}
              onClick={() => onDelete()}
              className={styles.iconButton}
            />
          ) : null}
        </p>
      )}
    </div>
  );
}

export default TooltipPanel;
