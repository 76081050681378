import { NUMBER_FORMATS } from '../constants/interfaces';
import formatNumber from './formatNumber';

import type { IPresignedData, IPresignedFields } from 'store/slices/files/interfaces/IPresignedFileResponse';

export const uploadFileToS3 = (file: File, presignedData: IPresignedData) => {
  const { url, fields } = presignedData;

  const formData = new FormData();

  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key as keyof IPresignedFields]);
  });

  formData.append('Content-Type', file.type);
  formData.append('file', file);

  return fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch((error) => error);
};

export const getKeyByValue = <T extends Record<string, string>>(
  value: string | undefined,
  object: T,
): keyof T | undefined => (
    Object.keys(object).find((key) => object[key] === value)
  );

export const formatBigNumber = (value: string) => {
  if (!value) return '-';

  const match = value.match(/^([\d,]+)\s*([A-Z]+)\s*\(([\d.]+)%\)$/);
  if (!match) {
    const simpleMatch = value.match(/^([\d,]+)\s*([A-Z]+)$/);
    if (!simpleMatch) return value;

    const [, numStr, currency] = simpleMatch;
    const num = parseFloat(numStr.replace(/,/g, ''));
    if (num < 1_000_000) {
      return value;
    }
    const millions = num / 1_000_000;
    if (millions < 1000) {
      const withOneDecimal = millions.toFixed(1);
      const formatted = withOneDecimal.endsWith('.0')
        ? withOneDecimal.slice(0, -2)
        : withOneDecimal;
      const withCommas = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `${withCommas}M ${currency}`;
    }
    const formattedMillions = Math.round(millions)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `${formattedMillions}M ${currency}`;
  }

  const [, numStr, currency, percentage] = match;
  const num = parseFloat(numStr.replace(/,/g, ''));
  if (num < 1_000_000) {
    return value;
  }
  const millions = num / 1_000_000;
  if (millions < 1000) {
    const withOneDecimal = millions.toFixed(1);
    const formatted = withOneDecimal.endsWith('.0')
      ? withOneDecimal.slice(0, -2)
      : withOneDecimal;
    const withCommas = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${withCommas}M ${currency} (${percentage}%)`;
  }
  const formattedMillions = Math.round(millions)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${formattedMillions}M ${currency} (${percentage}%)`;
};

export const formatRevenueAndPercentage = ({
  actual, percentage, currency,
}: {
  actual?: string | number,
  percentage?: string,
  currency?: string,
}): string => {
  let result;

  const percentageFormatted = percentage && formatNumber(
    {
      value: Number(percentage),
      type: NUMBER_FORMATS.percent,
    },
  );

  const actualFormatted = actual && formatNumber(
    {
      value: +actual,
      type: NUMBER_FORMATS.decimal,
    },
  );

  if (actual && percentage) {
    if (currency) {
      result = `${actualFormatted} ${currency} (${percentageFormatted})`;
    } else {
      result = `${actualFormatted} (${percentageFormatted})`;
    }
  } else if (actual) {
    if (currency) {
      result = `${actualFormatted} ${currency}`;
    } else {
      result = actual.toString();
    }
  } else if (percentage) {
    result = `${percentageFormatted}`;
  } else {
    result = '-';
  }

  return formatBigNumber(result);
};
