import { clsx } from 'clsx';

import type { IIconWithText } from './interfaces/IIconWithText';

import styles from './scss/IconWithText.module.scss';

function IconWithText({ text, icon, containerStyle }: IIconWithText) {
  return (
    <div className={clsx(styles.container, containerStyle)}>
      {icon}
      <p>{text}</p>
    </div>
  );
}

export default IconWithText;
