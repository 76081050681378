import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CustomerIcon } from 'assets/icons/user-check.svg';

import { formatRevenueAndPercentage } from '../../../../../../utils/helpers';
import CustomNodeItem from '../../BusinessStructure/components/CustomNodeItem';

import type { ICustomerNode } from '../interfaces/ICustomerNode';

import styles from '../sass/CustomUnitNode.module.scss';

function CustomerNode({
  name, totalRevenueRolling12MActual, totalRevenueRolling12MPercents, naceSection,
  addressCountry, profitCenterId,
}: ICustomerNode) {
  const { t } = useTranslation();

  const customNodeItems = [
    {
      title: t('valueChainMap.profitCenterId'),
      value: profitCenterId?.join(', ') || '-',
    },
    {
      title: t('valueChainMap.industryNace'),
      value: naceSection || '-',
      isCapitalize: true,
    },
    {
      title: t('valueChainMap.country'),
      value: addressCountry || '-',
      isCapitalize: true,
    },
    {
      title: t('valueChainMap.actualRevenue'),
      value: formatRevenueAndPercentage({
        actual: totalRevenueRolling12MActual?.amount,
        currency: totalRevenueRolling12MActual?.currency,
        percentage: totalRevenueRolling12MPercents?.toString(),
      }),
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <CustomerIcon />
        <p className={styles.title}>{name}</p>
      </div>
      <div className={styles.content}>
        {customNodeItems.map(({ title, value, isCapitalize }) => (
          <CustomNodeItem
            key={`customNodeItems_${title}`}
            title={title}
            value={value}
            isCapitalize={isCapitalize}
          />
        ))}
      </div>
    </>
  );
}

export default CustomerNode;
