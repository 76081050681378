import { UNIQUE_IDENTIFIER } from 'constants/formFields';
import {
  HTTP_METHODS, LINK_TYPES, PROPERTY_TYPES, PROPERTY_VALUES,
} from 'constants/general';
import {
  VALUE_CHAIN_CONNECTION_LOGICAL,
  VALUE_CHAIN_CONNECTION_LOGICAL_CREATE,
  VALUE_CHAIN_CONNECTION_SERVICE,
  VALUE_CHAIN_CONNECTION_SERVICE_CREATE,
  VALUE_CHAIN_CONNECTION_SUPPLIER,
  VALUE_CHAIN_CONNECTION_SUPPLIER_CREATE,
  VALUE_CHAIN_MAP_ARCHIVE, VALUE_CHAIN_MAP_BOARD, VALUE_CHAIN_MAP_BOARD_COLUMN, VALUE_CHAIN_MAP_BOARD_UNIT,
  VALUE_CHAIN_MAP_BUSINESS_STRUCTURE,
  VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_ORG_PATH,
  VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_STATUS,
  VALUE_CHAIN_MAP_CREATE,
  VALUE_CHAIN_MAP_DE_ARCHIVE,
  VALUE_CHAIN_MAP_DELETE,
  VALUE_CHAIN_MAP_DETAILS,
  VALUE_CHAIN_MAP_DUPLICATE,
  VALUE_CHAIN_MAP_LIST,
  VALUE_CHAIN_MAP_REVIEW,
  VALUE_CHAIN_MAP_UPDATE,
  VALUE_CHAIN_OWN_OPERATION_DATA,
  VALUE_CHAIN_PROFIT_CENTER_DATA,
} from 'constants/requestUrls';
import mainApi from 'store/apis/mainApi';
import { paginationForceRefetch, paginationMerge, paginationSerializeQueryArgs } from 'utils/storeHelpers';
import {
  deleteValueChainItemFromList,
  updateValueChainMapList,
} from 'utils/valueChainApi';

import {
  VALUE_CHAIN_MAP_BOARD_TAG,
  VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG,
  VALUE_CHAIN_MAP_DETAILS_TAG,
  VALUE_CHAIN_MAP_LIST_TAG,
} from '../../../tags/valueChainMap';

import type IOwnItem from 'store/slices/dataSets/interfaces/IOwnItem';
import type IProfitCenterItem from 'store/slices/dataSets/interfaces/IProfitCenterItem';
import type { IBaseQueryPaginationResponse } from 'store/types/IBaseQueryPaginationResponse';
import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';
import type { RootState } from '../../../types/TStore';
import type IArchiveValueChainItemRequest from '../interfaces/IArchiveValueChainItemRequest';
import type { ICreateBoardColumnRequest } from '../interfaces/ICreateBoardColumnRequest';
import type { ICreateLogicalConnection } from '../interfaces/ICreateLogicalConnection';
import type { ICreateServiceConnection } from '../interfaces/ICreateServiceConnection';
import type { ICreateSupplierConnection } from '../interfaces/ICreateSupplierConnection';
import type ICreateValueChainMapRequest from '../interfaces/ICreateValueChainMapRequest';
import type { IDeleteBoardColumnRequest } from '../interfaces/IDeleteBoardColumnRequest';
import type { IDeleteLogicalConnection } from '../interfaces/IDeleteLogicalConnection';
import type { IDeleteServiceConnection } from '../interfaces/IDeleteServiceConnection';
import type { IDeleteSupplierConnection } from '../interfaces/IDeleteSupplierConnection';
import type IDeleteValueChainItemRequest from '../interfaces/IDeleteValueChainItemrequest';
import type IDuplicateValueChainMapRequest from '../interfaces/IDuplicateValueChainMapRequest';
import type { IGetLogicalConnection } from '../interfaces/IGetLogicalConnection';
import type IGetOwnOperationResponse from '../interfaces/IGetOwnOperationResponse';
import type IGetProfitCentresResponse from '../interfaces/IGetProfitCentresResponse';
import type { IGetServiceConnection } from '../interfaces/IGetServiceConnection';
import type { IGetSupplierConnection } from '../interfaces/IGetSupplierConnection';
import type { IGetValueChainBoardResponse } from '../interfaces/IGetValueChainBoardResponse';
import type { IGetValueChainMapBusinessModelResponse } from '../interfaces/IGetValueChainMapBusinessModelResponse';
import type { IGetValueChainMapDetailsRequest } from '../interfaces/IGetValueChainMapDetailsRequest';
import type { IGetValueChainMapDetailsResponse } from '../interfaces/IGetValueChainMapDetailsResponse';
import type IOwnOperationsRequest from '../interfaces/IOwnOperationsRequest';
import type IProfitCenterRequest from '../interfaces/IProfitCenterRequest';
import type IRestoreValueChainItemRequest from '../interfaces/IRestoreValueChainItemRequest';
import type { IReviewValueChainItemRequest } from '../interfaces/IReviewValueChainItemRequest';
import type { IUpdateBoardColumnRequest } from '../interfaces/IUpdateBoardColumnRequest';
import type { IUpdateBoardUnitRequest } from '../interfaces/IUpdateBoardUnitRequest';
import type { IUpdateBusinessStructureOrgPathRequest } from '../interfaces/IUpdateBusinessStructureOrgPathRequest';
import type { IUpdateBusinessStructureStatusRequest } from '../interfaces/IUpdateBusinessStructureStatusRequest';
import type { IUpdateLogicalConnection } from '../interfaces/IUpdateLogicalConnection';
import type { IUpdateServiceConnection } from '../interfaces/IUpdateServiceConnection';
import type { IUpdateSupplierConnection } from '../interfaces/IUpdateSupplierConnection';
import type IUpdateValueChainItemRequest from '../interfaces/IUpdateValueChainItemRequest';
import type IValueChainMap from '../interfaces/IValueChainMap';
import type IValueChainMapRequest from '../interfaces/IValueChainMapRequest';

export const valueChainMapApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getValueChainMapList: builder.query<IBaseQueryPaginationResponse<IValueChainMap[]>, IValueChainMapRequest>({
      query: ({
        search, ...rest
      }) => ({
        url: VALUE_CHAIN_MAP_LIST,
        method: HTTP_METHODS.GET,
        params: {
          keyword: search,
          ...rest,
        },
      }),
      merge: paginationMerge,
      serializeQueryArgs: paginationSerializeQueryArgs,
      forceRefetch: paginationForceRefetch,
      providesTags: [VALUE_CHAIN_MAP_LIST_TAG],
    }),
    updateValueChainItem: builder.mutation<
    IBaseQueryResponse<IValueChainMap>,
    IUpdateValueChainItemRequest
    >({
      query: ({ chainId, status, name }) => ({
        url: VALUE_CHAIN_MAP_UPDATE.replace('{chainId}', `${chainId}`),
        method: HTTP_METHODS.PATCH,
        body: {
          ...(name ? { name } : {}),
          ...(status ? { status } : {}),
        },
      }),
      onQueryStarted: async ({ chainId }, { getState, dispatch, queryFulfilled }) => {
        const { data: updatedData } = await queryFulfilled;
        updateValueChainMapList({
          valueChainMapApi,
          chainId,
          state: getState() as RootState,
          updatedData,
          dispatch,
        });
      },
      invalidatesTags: (result, error, { chainId }) => [
        { type: VALUE_CHAIN_MAP_DETAILS_TAG, id: chainId },
      ],
    }),
    deleteValueChainItem:
      builder.mutation<void, IDeleteValueChainItemRequest>({
        query: ({ chainId }) => ({
          url: VALUE_CHAIN_MAP_DELETE.replace('{chainId}', `${chainId}`),
          method: HTTP_METHODS.DELETE,
        }),
        onQueryStarted: async ({ chainId }, { getState, dispatch }) => {
          deleteValueChainItemFromList({
            valueChainMapApi, chainId, state: getState() as RootState, dispatch,
          });
        },
      }),
    archiveValueChainItem: builder.mutation<
    IBaseQueryResponse<IValueChainMap>,
    IArchiveValueChainItemRequest
    >({
      query: ({ chainId, reason }) => ({
        url: VALUE_CHAIN_MAP_ARCHIVE.replace('{chainId}', `${chainId}`),
        method: HTTP_METHODS.PATCH,
        body: { reason },
      }),
      onQueryStarted: async ({ chainId }, { getState, dispatch, queryFulfilled }) => {
        const { data: updatedData } = await queryFulfilled;
        updateValueChainMapList({
          valueChainMapApi, chainId, state: getState() as RootState, updatedData, dispatch,
        });
      },
    }),
    reviewValueChainItem: builder.mutation<
    IBaseQueryResponse<IValueChainMap>,
    IReviewValueChainItemRequest
    >({
      query: ({ chainId, reason, status }) => ({
        url: VALUE_CHAIN_MAP_REVIEW.replace('{chainId}', `${chainId}`),
        method: HTTP_METHODS.PATCH,
        body: { reason, status },
      }),
      onQueryStarted: async ({ chainId }, {
        getState, dispatch, queryFulfilled,
      }) => {
        const { data: updatedData } = await queryFulfilled;

        updateValueChainMapList({
          valueChainMapApi, chainId, state: getState() as RootState, updatedData, dispatch,
        });
      },
    }),
    restoreValueChainItem: builder.mutation<
    IBaseQueryResponse<IValueChainMap>,
    IRestoreValueChainItemRequest
    >({
      query: ({ chainId }) => ({
        url: VALUE_CHAIN_MAP_DE_ARCHIVE.replace('{chainId}', `${chainId}`),
        method: HTTP_METHODS.PATCH,
      }),
      onQueryStarted: async ({ chainId }, { getState, dispatch, queryFulfilled }) => {
        const { data: updatedData } = await queryFulfilled;
        updateValueChainMapList({
          valueChainMapApi, chainId, state: getState() as RootState, updatedData, dispatch,
        });
      },
    }),
    getValueChainMapBusinessModel: builder.query<IGetValueChainMapBusinessModelResponse,
    { valueChainMapId: string }>({
      query: ({ valueChainMapId }) => ({
        url: VALUE_CHAIN_MAP_BUSINESS_STRUCTURE.replace('{valueChainMapId}', `${valueChainMapId}`),
        method: HTTP_METHODS.GET,
      }),
      providesTags: (result, error, { valueChainMapId }) => [
        { type: VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG, id: valueChainMapId },
      ],
    }),
    getValueChainMapDetails: builder.query<IBaseQueryResponse<IGetValueChainMapDetailsResponse>,
    IGetValueChainMapDetailsRequest>({
      query: ({ chainId }) => ({
        url: VALUE_CHAIN_MAP_DETAILS.replace('{chainId}', `${chainId}`),
        method: HTTP_METHODS.GET,
      }),
      providesTags: (result, error, { chainId }) => [
        { type: VALUE_CHAIN_MAP_DETAILS_TAG, id: chainId },
      ],
    }),
    checkValueChainMapStatus: builder.query<IBaseQueryResponse<IGetValueChainMapDetailsResponse>,
    IGetValueChainMapDetailsRequest>({
      query: ({ chainId }) => ({
        url: VALUE_CHAIN_MAP_DETAILS.replace('{chainId}', `${chainId}`),
        method: HTTP_METHODS.GET,
      }),
      providesTags: [VALUE_CHAIN_MAP_LIST_TAG],
      onQueryStarted: async ({ chainId }, { getState, dispatch, queryFulfilled }) => {
        const { data: updatedData } = await queryFulfilled;
        updateValueChainMapList({
          valueChainMapApi,
          chainId,
          state: getState() as RootState,
          updatedData,
          dispatch,
        });
      },
    }),
    createValueChainMap: builder.mutation<IBaseQueryResponse<IValueChainMap>,
    ICreateValueChainMapRequest>({
      query: ({ name }) => ({
        url: VALUE_CHAIN_MAP_CREATE,
        method: HTTP_METHODS.POST,
        body: { name },
      }),
    }),
    duplicateValueChainMap: builder.mutation<IBaseQueryResponse<IValueChainMap>,
    IDuplicateValueChainMapRequest>({
      query: ({ name, valueChainMapId }) => ({
        url: VALUE_CHAIN_MAP_DUPLICATE.replace('{chainId}', `${valueChainMapId}`),
        method: HTTP_METHODS.POST,
        body: { name },
      }),
    }),
    updateBusinessStructureStatus: builder.mutation<unknown, IUpdateBusinessStructureStatusRequest>({
      query: ({
        valueChainMapId, orgPath, disableReason, isDisabled,
      }) => ({
        url: VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_STATUS,
        method: HTTP_METHODS.PATCH,
        body: {
          valueChainMapId,
          orgPath,
          disableReason,
          isDisabled,
        },
      }),
      invalidatesTags: (result, error, { valueChainMapId }) => [
        { type: VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG, id: valueChainMapId },
        VALUE_CHAIN_MAP_BOARD_TAG,
      ],
    }),
    updateBusinessStructureOrgPath: builder.mutation<unknown, IUpdateBusinessStructureOrgPathRequest>({
      query: ({
        valueChainMapId, targetParentOrgPath, childrenOrgPath,
      }) => ({
        url: VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_ORG_PATH,
        method: HTTP_METHODS.PATCH,
        body: {
          valueChainMapId,
          targetParentOrgPath,
          childrenOrgPath,
        },
      }),
      invalidatesTags: (result, error, { valueChainMapId }) => [
        { type: VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG, id: valueChainMapId },
        VALUE_CHAIN_MAP_BOARD_TAG,
      ],
    }),
    getProfitCentres: builder.query<IBaseQueryResponse<IGetProfitCentresResponse>,
    IGetValueChainMapDetailsRequest>({
      query: ({ chainId }) => ({
        url: VALUE_CHAIN_PROFIT_CENTER_DATA.replace('{profitCenterId}', ''),
        method: HTTP_METHODS.GET,
        params: {
          valueChainMapId: chainId,
          linkType: LINK_TYPES.unlinked,
          propertyType: PROPERTY_TYPES.businessUnitType,
          propertyValue: PROPERTY_VALUES.logical,
          orderBy: UNIQUE_IDENTIFIER,
        },
      }),
    }),
    getOwnOperations: builder.query<IBaseQueryResponse<IGetOwnOperationResponse>,
    IGetValueChainMapDetailsRequest>({
      query: ({ chainId }) => ({
        url: VALUE_CHAIN_OWN_OPERATION_DATA.replace('{recordId}', ''),
        method: HTTP_METHODS.GET,
        params: {
          valueChainMapId: chainId,
          propertyType: PROPERTY_TYPES.businessUnitType,
          propertyValue: PROPERTY_VALUES.logical,
        },
      }),
    }),
    getValueChainMapBoard: builder
      .query<IGetValueChainBoardResponse, { valueChainMapId: string, filters?: string }>({
      query: ({ valueChainMapId, filters = '' }) => ({
        url: VALUE_CHAIN_MAP_BOARD.replace('{valueChainMapId}', valueChainMapId) + (filters ? `?${filters}` : ''),
        method: HTTP_METHODS.GET,
      }),
      providesTags: [VALUE_CHAIN_MAP_BOARD_TAG],
    }),
    updateBoardColumn: builder.mutation<unknown, IUpdateBoardColumnRequest>({
      query: ({ id, name }) => ({
        url: `${VALUE_CHAIN_MAP_BOARD_COLUMN}${id}`,
        method: HTTP_METHODS.PATCH,
        body: {
          name,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    createBoardColumn: builder.mutation<unknown, ICreateBoardColumnRequest>({
      query: ({ valueChainMapId, boardId, name }) => ({
        url: VALUE_CHAIN_MAP_BOARD_COLUMN,
        method: HTTP_METHODS.POST,
        body: {
          valueChainMapId,
          boardId,
          name,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    deleteBoardColumn: builder.mutation<unknown, IDeleteBoardColumnRequest>({
      query: ({ id }) => ({
        url: `${VALUE_CHAIN_MAP_BOARD_COLUMN}${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    getProfitCenterData: builder.query<IBaseQueryResponse<IProfitCenterItem>,
    IGetValueChainMapDetailsRequest>({
      query: ({ chainId }) => ({
        url: VALUE_CHAIN_PROFIT_CENTER_DATA.replace('{profitCenterId}', `${chainId}`),
        method: HTTP_METHODS.GET,
      }),
    }),
    getOwnOperationData: builder.query<IBaseQueryResponse<IOwnItem>,
    IGetValueChainMapDetailsRequest>({
      query: ({ chainId }) => ({
        url: VALUE_CHAIN_OWN_OPERATION_DATA.replace('{recordId}', `${chainId}`),
        method: HTTP_METHODS.GET,
      }),
    }),
    createProfitCenter: builder.mutation <IBaseQueryResponse<IProfitCenterItem>,
    IProfitCenterRequest>({
      query: ({ valueChainMapId, data }) => ({
        url: VALUE_CHAIN_PROFIT_CENTER_DATA.replace('{profitCenterId}', ''),
        params: {
          valueChainMapId,
        },
        method: HTTP_METHODS.POST,
        body: data,
      }),
    }),
    updateProfitCenter: builder.mutation <IBaseQueryResponse<IProfitCenterItem>,
    IProfitCenterRequest>({
      query: ({ valueChainMapId, data }) => ({
        url: VALUE_CHAIN_PROFIT_CENTER_DATA.replace('{profitCenterId}', ''),
        method: HTTP_METHODS.PATCH,
        params: { valueChainMapId },
        body: data,
      }),
    }),
    createOwnOperation: builder.mutation <IBaseQueryResponse<IOwnItem>,
    IOwnOperationsRequest>({
      query: ({ valueChainMapId, data }) => ({
        url: VALUE_CHAIN_OWN_OPERATION_DATA.replace('{recordId}', ''),
        method: HTTP_METHODS.POST,
        params: { valueChainMapId },
        body: data,
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    updateOwnOperation: builder.mutation <IBaseQueryResponse<IOwnItem>,
    IOwnOperationsRequest>({
      query: ({ valueChainMapId, data }) => ({
        url: VALUE_CHAIN_OWN_OPERATION_DATA.replace('{recordId}', ''),
        method: HTTP_METHODS.PATCH,
        params: { valueChainMapId },
        body: data,
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    updateBoardUnit: builder.mutation<unknown, IUpdateBoardUnitRequest>({
      query: ({
        columnId, parentId, unitId, valueChainMapId,
      }) => ({
        url: VALUE_CHAIN_MAP_BOARD_UNIT.replace('{id}', `${unitId}`),
        method: HTTP_METHODS.PATCH,
        body: {
          columnId,
          parentId,
          valueChainMapId,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    createLogicalConnection: builder.mutation<unknown, ICreateLogicalConnection>({
      query: ({
        valueChainMapId,
        fromUnitId,
        toUnitId,
        connectionType,
        comment,
        ownership,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_LOGICAL_CREATE,
        method: HTTP_METHODS.POST,
        body: {
          valueChainMapId,
          fromUnitId,
          toUnitId,
          connectionType,
          comment,
          ownership,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    getLogicalConnection: builder.query<unknown, IGetLogicalConnection>({
      query: ({
        id,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_LOGICAL.replace('{id}', `${id}`),
        method: HTTP_METHODS.GET,
      }),
    }),
    updateLogicalConnection: builder.mutation<unknown, IUpdateLogicalConnection>({
      query: ({
        id,
        toUnitId,
        connectionType,
        comment,
        ownership,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_LOGICAL.replace('{id}', `${id}`),
        method: HTTP_METHODS.PATCH,
        body: {
          toUnitId,
          connectionType,
          comment,
          ownership,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    deleteLogicalConnection: builder.mutation<unknown, IDeleteLogicalConnection>({
      query: ({
        id,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_LOGICAL.replace('{id}', `${id}`),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    createSupplierConnection: builder.mutation<unknown, ICreateSupplierConnection>({
      query: ({
        valueChainMapId,
        fromUnitId,
        toUnitId,
        transportMethods,
        distance,
        monetaryValue,
        currency,
        quantity,
        quantityUnit,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_SUPPLIER_CREATE,
        method: HTTP_METHODS.POST,
        body: {
          valueChainMapId,
          fromUnitId,
          toUnitId,
          transportMethods,
          distance,
          monetaryValue,
          currency,
          quantity,
          quantityUnit,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    getSupplierConnection: builder.query<unknown, IGetSupplierConnection>({
      query: ({
        id,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_SUPPLIER_CREATE.replace('{id}', `${id}`),
        method: HTTP_METHODS.GET,
      }),
    }),
    updateSupplierConnection: builder.mutation<unknown, IUpdateSupplierConnection>({
      query: ({
        id,
        toUnitId,
        transportMethods,
        distance,
        monetaryValue,
        currency,
        quantity,
        quantityUnit,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_SUPPLIER.replace('{id}', `${id}`),
        method: HTTP_METHODS.PATCH,
        body: {
          toUnitId,
          transportMethods,
          distance,
          monetaryValue,
          currency,
          quantity,
          quantityUnit,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    deleteSupplierConnection: builder.mutation<unknown, IDeleteSupplierConnection>({
      query: ({
        id,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_SUPPLIER.replace('{id}', `${id}`),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    createServiceConnection: builder.mutation<unknown, ICreateServiceConnection>({
      query: ({
        valueChainMapId,
        fromUnitId,
        toUnitId,
        serviceProviderType,
        monetaryValue,
        monetaryCurrency,
        comment,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_SERVICE_CREATE,
        method: HTTP_METHODS.POST,
        body: {
          valueChainMapId,
          fromUnitId,
          toUnitId,
          serviceProviderType,
          monetaryValue,
          monetaryCurrency,
          comment,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    getServiceConnection: builder.query<unknown, IGetServiceConnection>({
      query: ({
        id,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_SERVICE.replace('{id}', `${id}`),
        method: HTTP_METHODS.GET,
      }),
    }),
    updateServiceConnection: builder.mutation<unknown, IUpdateServiceConnection>({
      query: ({
        id,
        toUnitId,
        serviceProviderType,
        monetaryValue,
        monetaryCurrency,
        comment,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_SERVICE.replace('{id}', `${id}`),
        method: HTTP_METHODS.PATCH,
        body: {
          toUnitId,
          serviceProviderType,
          monetaryValue,
          monetaryCurrency,
          comment,
        },
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
    deleteServiceConnection: builder.mutation<unknown, IDeleteServiceConnection>({
      query: ({
        id,
      }) => ({
        url: VALUE_CHAIN_CONNECTION_SERVICE.replace('{id}', `${id}`),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [VALUE_CHAIN_MAP_BOARD_TAG, VALUE_CHAIN_MAP_BUSINESS_STRUCTURE_TAG],
    }),
  }),
});

export const {
  useGetValueChainMapListQuery,
  useUpdateValueChainItemMutation,
  useDeleteValueChainItemMutation,
  useArchiveValueChainItemMutation,
  useRestoreValueChainItemMutation,
  useGetValueChainMapBusinessModelQuery,
  useGetValueChainMapDetailsQuery,
  useCreateValueChainMapMutation,
  useDuplicateValueChainMapMutation,
  useLazyCheckValueChainMapStatusQuery,
  useUpdateBusinessStructureStatusMutation,
  useUpdateBusinessStructureOrgPathMutation,
  useGetProfitCentresQuery,
  useGetOwnOperationsQuery,
  useGetValueChainMapBoardQuery,
  useGetProfitCenterDataQuery,
  useGetOwnOperationDataQuery,
  useCreateProfitCenterMutation,
  useUpdateProfitCenterMutation,
  useCreateOwnOperationMutation,
  useUpdateOwnOperationMutation,
  useUpdateBoardColumnMutation,
  useCreateBoardColumnMutation,
  useUpdateBoardUnitMutation,
  useReviewValueChainItemMutation,
  useDeleteBoardColumnMutation,
  useCreateLogicalConnectionMutation,
  useGetLogicalConnectionQuery,
  useUpdateLogicalConnectionMutation,
  useDeleteLogicalConnectionMutation,
  useCreateSupplierConnectionMutation,
  useGetSupplierConnectionQuery,
  useUpdateSupplierConnectionMutation,
  useDeleteSupplierConnectionMutation,
  useCreateServiceConnectionMutation,
  useGetServiceConnectionQuery,
  useUpdateServiceConnectionMutation,
  useDeleteServiceConnectionMutation,
} = valueChainMapApi;

export default valueChainMapApi;
