import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'components/Modal/Modal';
import TextField from 'components/TextField/TextField';
import { NAME } from 'constants/formFields';
import { DESCRIPTION_LENGTH } from 'constants/general';
import { renameValueChainSchema } from 'utils/validators';

import type { TRenameValueChainSchema } from 'utils/validators';
import type IRenameModal from './interfaces/IRenameModal';

export default function RenameModal({
  open,
  title,
  onReject,
  onSubmit,
  isLoading,
  name,
}: IRenameModal) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<TRenameValueChainSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(renameValueChainSchema),
    defaultValues: {
      [NAME]: name,
    },
  });

  const onHandleConfirm = handleSubmit((values) => {
    onSubmit(values.name);
  });

  useEffect(() => {
    if (open) {
      reset();
      setValue(NAME, name);
    }
  }, [name, open, reset, setValue]);

  return (
    <Modal
      open={open}
      title={title ?? t('common.enterNewName')}
      closeTitle={t('common.cancel')}
      confirmTitle={t('common.save')}
      onReject={onReject}
      onConfirm={onHandleConfirm}
      isLoading={isLoading}
    >
      <Controller
        control={control}
        name={NAME}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            name={NAME}
            value={value}
            label={t('common.name')}
            placeholder={t('common.typeNewNameHere')}
            onChange={(e) => onChange(e.target.value.slice(0, DESCRIPTION_LENGTH))}
            error={error?.message}
          />
        )}
      />
    </Modal>
  );
}
