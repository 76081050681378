import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';

const valueChainMap = (state: RootState) => state.valueChainMap;

export const valueChainMapQueryParamsSelector = createSelector(
  [valueChainMap],
  ({ valueChainMapQueryParams }) => valueChainMapQueryParams,
);

export const currentCompanyMapTabSelector = createSelector(
  [valueChainMap],
  (state) => state.currentCompanyMapTab,
);
