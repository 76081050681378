import {
  Background, BackgroundVariant, Panel, ReactFlowProvider,
} from '@xyflow/react';
import { clsx } from 'clsx';

import RenameModal from '../../../components/RenameModal/RenameModal';
import { COMPANY_MAP_MODALS, COMPANY_MAP_TABS } from '../../../constants/interfaces';
import BottomRightControls from './components/BottomRightControls';
import BusinessStructure from './components/BusinessStructure/BusinessStructure';
import ProfitCentreModal from './components/BusinessStructure/components/ProfitCentreModal';
import TopCenterControls from './components/TopCenterControls';
import TopLeftControls from './components/TopLeftControls';
import TopRightControls from './components/TopRightControls';
import { DndProvider } from './components/ValueChain/components/DndContext';
import ValueChain from './components/ValueChain/ValueChain';

import type { ICompanyMap } from './interfaces/ICompanyMap';

import styles from './sass/CompanyMapView.module.scss';

function CompanyMapView({
  currentTab, onTabHandler, onRenameHandler, activeModal, closeModal, onRenameSubmit, valueChainMapId,
  isProfitCentreModalOpen, closeProfitCenterModal,
  openProfitCenterModal, currentZoom, onMoveEnd, data, isLoading, onChangeStatus,
  isFullScreenActive, onMaximizeHandler, isAccessDisabled,
}: ICompanyMap) {
  return (
    <div className={styles.container}>
      <ReactFlowProvider>
        <Background variant={BackgroundVariant.Cross} gap={60} color="#DBDDDE" size={20} bgColor="#f3f4f4" />
        <BottomRightControls currentZoom={currentZoom} onMaximizeHandler={onMaximizeHandler} />
        <Panel
          position="top-left"
          className={clsx(styles.topControls, styles.leftPanel, {
            [styles.fullScreen]: isFullScreenActive,
          })}
        >
          <TopLeftControls
            onRenameHandler={onRenameHandler}
            data={data}
            isLoading={isLoading}
            onChangeStatus={onChangeStatus}
            isFullScreenActive={isFullScreenActive}
            isAccessDisabled={isAccessDisabled}
          />
        </Panel>
        <Panel
          position="top-center"
          className={clsx(styles.topControls, {
            [styles.fullScreen]: isFullScreenActive,
          })}
        >
          <TopCenterControls
            onTabHandler={onTabHandler}
            currentTab={currentTab}
          />
        </Panel>
        {!isAccessDisabled && (
          <Panel
            position="top-right"
            className={clsx(styles.topControls, {
              [styles.fullScreen]: isFullScreenActive,
            })}
          >
            <TopRightControls />
          </Panel>
        )}
        {currentTab === COMPANY_MAP_TABS.BUSINESS_STRUCTURE ? (
          <BusinessStructure
            valueChainMapId={valueChainMapId}
            onMoveEnd={onMoveEnd}
            openProfitCenterModal={openProfitCenterModal}
            isFullScreenActive={isFullScreenActive}
            isAccessDisabled={isAccessDisabled}
          />
        ) : (
          <DndProvider>
            <ValueChain
              onMoveEnd={!isAccessDisabled ? onMoveEnd : () => {}}
              valueChainMapId={valueChainMapId}
              isFullScreenActive={isFullScreenActive}
              isAccessDisabled={isAccessDisabled}
            />
          </DndProvider>
        )}
      </ReactFlowProvider>
      {data ? (
        <RenameModal
          open={activeModal === COMPANY_MAP_MODALS.RENAME}
          onSubmit={onRenameSubmit}
          onReject={closeModal}
          name={data.name}
        />
      ) : null}
      {
        isProfitCentreModalOpen
          ? (
            <ProfitCentreModal
              valueChainMapId={valueChainMapId || ''}
              closeProfitCenterModal={closeProfitCenterModal}
            />
          ) : null
      }
    </div>
  );
}

export default CompanyMapView;
