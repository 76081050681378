import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TableCell from 'components/Table/TableCell';
import TableHead from 'components/Table/TableHead';
import TableSort from 'components/Table/TableSort';
import { SORT } from 'constants/formFields';
import { VALUE_CHAIN_HEAD_CELLS } from 'constants/valueChain';

import type IValueChainMapTableHead from '../../interfaces/IValueChainHead';

import styles from './sass/ValueChainMapTableHead.module.scss';

function ValueChainMapTableHead({
  control, onSortChange, isActionsVisible,
}: IValueChainMapTableHead) {
  const { t } = useTranslation();

  const cells = isActionsVisible
    ? VALUE_CHAIN_HEAD_CELLS
    : VALUE_CHAIN_HEAD_CELLS.filter(({ id }) => id !== 'actions');

  return (
    <TableHead>
      {cells.map(({ id, label, withSort }) => (withSort ? (
        <Controller
          name={SORT}
          key={`head_cells_${id}`}
          control={control}
          render={({
            field: { value },
          }) => (
            <TableCell head className={styles.date}>
              <TableSort
                active={id === value.orderKey}
                onClickSort={() => onSortChange(id)}
              >
                {t(label)}
              </TableSort>
            </TableCell>
          )}
        />
      ) : (
        <TableCell
          head
          className={styles.actions}
          key={`head_cells_${id}`}
        >
          {t(label)}
        </TableCell>
      )))}
    </TableHead>
  );
}

export default ValueChainMapTableHead;
