import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import { BUSINESS_UNIT_TYPE_LOGICAL_VALUE, DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import {
  BUSINESS_UNIT_TYPE,
  NACE_CODE,
  NACE_SECTION,
} from 'constants/formFields';
import { BUTTON_VARIANT } from 'constants/interfaces';
import { STEPS_PROFIT_CENTER_MODAL } from 'constants/valueChain';
import useDataSetsDate from 'hooks/useDataSetsDate';
import useFieldsErrors from 'hooks/useFieldsErrors';
import useGeoLocations from 'hooks/useGeoLocations';
import {
  useEnumQuery,
  useGetNaceCodeTreeQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectEnums, selectNaceCodes } from 'store/slices/dataSets/selectors';
import {
  useCreateOwnOperationMutation,
  useCreateProfitCenterMutation,
  useGetOwnOperationsQuery,
  useGetProfitCentresQuery,
  useUpdateOwnOperationMutation,
  useUpdateProfitCenterMutation,
} from 'store/slices/valueChainMap/apis/valueChainMapApi';
import getRequestDataForOwnOperations from 'utils/formHelpers/getRequestDataForOwnOperations';
import getRequestDataForProfitCentre from 'utils/formHelpers/getRequestDataForProfitCentre';

import { useAppDispatch } from '../../../../../../store/hooks/useApp';
import { setDataSetsState } from '../../../../../../store/slices/dataSets/slice';
import useCreateOwnOperationsForm from '../../hooks/useCreateOwnOperationsForm';
import useCreateProfitCentreForm from '../../hooks/useCreateProfitCentreForm';
import OwnOperationForm from './OwnOperationForm';
import ProfitCenterForm from './ProfitCenterForm';
import SelectProfitCenter from './SelectProfitCenter';

import type { TypesStepsProfitCenterModal } from 'constants/interfaces';
import type IOwnItem from 'store/slices/dataSets/interfaces/IOwnItem';
import type { IProfitCenter } from 'store/slices/valueChainMap/interfaces/IProfitCenter';
import type { TCreateOperationsSchema, TCreateProfitCentreSchema } from 'utils/validators';
import type IProfitCenterModal from '../../interfaces/IProfitCenterModal';

import styles from '../sass/ProfitCentreModal.module.scss';

export default function ProfitCenterModal({ closeProfitCenterModal, valueChainMapId }: IProfitCenterModal) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const { setFieldsErrors } = useFieldsErrors();
  const [selectedProfitCennter, setSelectedProfitCenter] = useState<IProfitCenter | IOwnItem | undefined>();
  const [selectedOwnOperation, setSelectedOwnOperation] = useState<IProfitCenter | IOwnItem | undefined>();
  const [
    currentType, setCurrentType,
  ] = useState<TypesStepsProfitCenterModal>(STEPS_PROFIT_CENTER_MODAL.SELECT_PROFIT_CENTER);
  const {
    data: profitCenterList, isLoading: isProfitCentersLoading, isFetching: isProfitCentersFetching,
  } = useGetProfitCentresQuery({ chainId: valueChainMapId }, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: ownOperationList, isLoading: isOwnOperationsLoading,
  } = useGetOwnOperationsQuery({ chainId: valueChainMapId });

  const { data: naceCodesData } = useGetNaceCodeTreeQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: selectNaceCodes(result.data) }),
  });

  const { data: enumData } = useEnumQuery({
    types: [DATA_SETS_ENUM_TYPES.currency, DATA_SETS_ENUM_TYPES.ownership, DATA_SETS_ENUM_TYPES.valueChainSection,
      DATA_SETS_ENUM_TYPES.businessUnitType, DATA_SETS_ENUM_TYPES.assetCategory, DATA_SETS_ENUM_TYPES.ownedLeased],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectEnums(result.data) }),
  });

  const {
    control,
    handleSubmit,
    setError,
    watch: profitCenterWatch,
    setValue: setProfitCenterValue,
    fieldsName,
    reset,
  } = useCreateProfitCentreForm({
    recordId: selectedProfitCennter?.id,
  });

  const {
    control: operationFormControl,
    handleSubmit: operationFormHandleSubmit,
    watch,
    setValue,
    setError: setOperationError,
    fieldsName: operationFieldsName,
    reset: resetOperationForm,
  } = useCreateOwnOperationsForm({
    recordId: selectedOwnOperation?.id,
    enumData,
  });

  useEffect(() => {
    setValue(BUSINESS_UNIT_TYPE, BUSINESS_UNIT_TYPE_LOGICAL_VALUE);
  }, [selectedProfitCennter, setValue]);

  const naceDivisions = watch(NACE_SECTION)?.divisions || [];

  const {
    dateOpened,
    onDateOpenedChange,
    startDate,
    onStartDateChange,
  } = useDataSetsDate<TCreateOperationsSchema>({ watch, setValue });

  const {
    startDate: startDateProfitCenter,
    onStartDateChange: onProfitCenterStartDateChange,
  } = useDataSetsDate<TCreateProfitCentreSchema>({ watch: profitCenterWatch, setValue: setProfitCenterValue });

  const {
    onFindCountries, onFindStates, onFindCities, isStatesDisabled, onChangeCountry, onChangeState,
    isCitiesDisabled,
  } = useGeoLocations<TCreateOperationsSchema>({
    watch, setValue,
  });

  const [createProfitCenter, { isLoading: isCreateProfitCenterLoading }] = useCreateProfitCenterMutation();
  const [updateProfitCenter, { isLoading: isUpdateProfitCenterLoading }] = useUpdateProfitCenterMutation();
  const [createOwnOperation, { isLoading: isCreateOwnOperationLoading }] = useCreateOwnOperationMutation();
  const [updateOwnOperation, { isLoading: isUpdateOwnOperationLoading }] = useUpdateOwnOperationMutation();

  const onOwnOperationSubmit = operationFormHandleSubmit((values) => {
    if (selectedOwnOperation?.id) {
      updateOwnOperation({
        data: getRequestDataForOwnOperations({
          values,
          recordId: `${selectedOwnOperation.id}`,
        }),
        valueChainMapId,
      })
        .unwrap()
        .then(() => closeProfitCenterModal());
    } else {
      createOwnOperation({
        data: getRequestDataForOwnOperations({
          values,
        }),
        valueChainMapId,
      })
        .unwrap()
        .then(() => closeProfitCenterModal());
    }
  });

  const stepTitles = {
    [STEPS_PROFIT_CENTER_MODAL.SELECT_PROFIT_CENTER]: t('valueChainMap.addProfitCentreToTheStructure'),
    [STEPS_PROFIT_CENTER_MODAL.CREATE_PROFIT_CENTER]: t('profitCentres.createProfitCentre.title'),
    [STEPS_PROFIT_CENTER_MODAL.SELECT_OWN_OPERATION]: t('valueChainMap.addOperationToProfitCenter'),
    [STEPS_PROFIT_CENTER_MODAL.CREATE_OWN_OPERATION]: t('ownOperations.addNewOwnOperation'),
  };

  const generateTitle = (step: TypesStepsProfitCenterModal) => {
    if (step === STEPS_PROFIT_CENTER_MODAL.CREATE_PROFIT_CENTER) {
      return selectedProfitCennter?.id
        ? t('profitCentres.createProfitCentre.editTitle')
        : t('profitCentres.createProfitCentre.title');
    }
    if (step === STEPS_PROFIT_CENTER_MODAL.CREATE_OWN_OPERATION) {
      return selectedOwnOperation?.id
        ? t('ownOperations.editOwnOperation')
        : t('ownOperations.createNewOwnOperation');
    }
    return stepTitles[step] || '';
  };

  const generateNextStep = (step: TypesStepsProfitCenterModal) => {
    const stepsOfModal = Object.values(STEPS_PROFIT_CENTER_MODAL);
    const currentIndex = stepsOfModal.indexOf(step);
    return currentIndex < stepsOfModal.length - 1
      ? stepsOfModal[currentIndex + 1]
      : step;
  };

  const onNaceSelectionChange = () => {
    setValue(NACE_CODE, null);
  };

  const addNewProfitCenterHandler = () => {
    setSelectedProfitCenter(undefined);
    dispatch(setDataSetsState({ selectedProfitCenter: undefined }));
    reset();
    setCurrentType(STEPS_PROFIT_CENTER_MODAL.CREATE_PROFIT_CENTER);
  };

  const addNewOwnOperationHandler = () => {
    setSelectedOwnOperation(undefined);
    resetOperationForm();
    setCurrentType(STEPS_PROFIT_CENTER_MODAL.CREATE_OWN_OPERATION);
  };

  const generatePrevStep = (step: TypesStepsProfitCenterModal) => {
    const stepsOfModal = Object.values(STEPS_PROFIT_CENTER_MODAL);
    const currentIndex = stepsOfModal.indexOf(step);
    return currentIndex > 0 ? stepsOfModal[currentIndex - 1] : step;
  };

  const prevClickHandler = () => {
    setCurrentType(generatePrevStep(currentType));
  };

  const isNextButtonDisabled = useCallback(() => {
    let result = false;
    if (currentType === STEPS_PROFIT_CENTER_MODAL.SELECT_PROFIT_CENTER && !selectedProfitCennter?.id) {
      result = true;
    }
    if (currentType === STEPS_PROFIT_CENTER_MODAL.SELECT_OWN_OPERATION && !selectedOwnOperation?.id) {
      result = true;
    }
    return result;
  }, [currentType, selectedProfitCennter, selectedOwnOperation]);

  const onProfitCentreSubmit = handleSubmit((values) => {
    if (selectedProfitCennter?.id) {
      updateProfitCenter({
        data: getRequestDataForProfitCentre({ values, recordId: `${selectedProfitCennter.id}` }),
        valueChainMapId,
      })
        .unwrap()
        .then(() => {
          setCurrentType(generateNextStep(currentType));
        })
        .catch((errors) => {
          setFieldsErrors({ errors, setError, fieldsName });
        });
    } else {
      createProfitCenter({
        data: getRequestDataForProfitCentre({ values }),
        valueChainMapId,
      })
        .unwrap()
        .then(({ data }) => {
          setSelectedProfitCenter({ id: data.id, uniqueIdentifier: data.uniqueIdentifier } as IProfitCenter);
          dispatch(setDataSetsState({
            selectedProfitCenter: { label: data.uniqueIdentifier, value: data.uniqueIdentifier },
          }));
          setCurrentType(generateNextStep(currentType));
        })
        .catch((errors) => {
          setFieldsErrors({ errors, setError: setOperationError, fieldsName: operationFieldsName });
        });
    }
  });

  const nextclickHandler = () => {
    if (currentType === STEPS_PROFIT_CENTER_MODAL.CREATE_PROFIT_CENTER) {
      onProfitCentreSubmit();
    } else if (currentType === STEPS_PROFIT_CENTER_MODAL.CREATE_OWN_OPERATION) {
      onOwnOperationSubmit();
    } else {
      setCurrentType(generateNextStep(currentType));
    }
  };

  return (
    <Modal
      open
      isButtonsVisible={false}
      className={styles.modal}
      title={generateTitle(currentType)}
    >
      <div ref={modalRef}>
        {
          currentType === STEPS_PROFIT_CENTER_MODAL.SELECT_PROFIT_CENTER && (
            <SelectProfitCenter
              addNewProfitCenterHandler={addNewProfitCenterHandler}
              list={profitCenterList?.data?.items || []}
              isLoading={isProfitCentersLoading || isOwnOperationsLoading || isProfitCentersFetching}
              onSelectItem={(item) => {
                setSelectedProfitCenter(item);
                dispatch(setDataSetsState({
                  selectedProfitCenter: { label: item.uniqueIdentifier, value: item.uniqueIdentifier },
                }));
              }}
              selectedItem={selectedProfitCennter}
            />
          )
        }
        {
          currentType === STEPS_PROFIT_CENTER_MODAL.CREATE_PROFIT_CENTER && (
            <ProfitCenterForm
              control={control}
              enumData={enumData}
              recordId={selectedProfitCennter?.id}
              startDate={startDateProfitCenter}
              onStartDateChange={onProfitCenterStartDateChange}
              isLoading={isCreateProfitCenterLoading || isUpdateProfitCenterLoading}
              modalRef={modalRef}
            />
          )
        }
        {
          currentType === STEPS_PROFIT_CENTER_MODAL.SELECT_OWN_OPERATION && (
            <SelectProfitCenter
              addNewProfitCenterHandler={addNewOwnOperationHandler}
              list={ownOperationList?.data?.items || []}
              ownOperation
              selectedItem={selectedOwnOperation}
              isLoading={isOwnOperationsLoading || isProfitCentersLoading}
              onSelectItem={(item) => setSelectedOwnOperation(item)}
            />
          )
        }
        {
          currentType === STEPS_PROFIT_CENTER_MODAL.CREATE_OWN_OPERATION && (
            <OwnOperationForm
              control={operationFormControl}
              enumData={enumData}
              recordId={selectedOwnOperation?.id}
              onDateOpenedChange={onDateOpenedChange}
              dateOpened={dateOpened}
              onNaceSelectionChange={onNaceSelectionChange}
              naceCodesData={naceCodesData}
              naceDivisions={naceDivisions}
              onChangeCountry={onChangeCountry}
              onFindCountries={onFindCountries}
              onFindStates={onFindStates}
              onFindCities={onFindCities}
              onChangeState={onChangeState}
              isCitiesDisabled={isCitiesDisabled}
              isStatesDisabled={isStatesDisabled}
              startDate={startDate}
              onStartDateChange={onStartDateChange}
              isLoading={isCreateOwnOperationLoading || isUpdateOwnOperationLoading}
              modalRef={modalRef}
              selectedProfitCenter={selectedProfitCennter?.uniqueIdentifier}
              profitCenterList={profitCenterList?.data?.items || []}
            />
          )
        }
        <div className={styles.buttons}>
          <Button
            title={t('common.cancel')}
            variant={BUTTON_VARIANT.cancel}
            onClick={closeProfitCenterModal}
          />
          <div className={styles.buttonsRight}>
            {
            currentType !== STEPS_PROFIT_CENTER_MODAL.SELECT_PROFIT_CENTER && (
              <Button
                title={t('common.prev')}
                onClick={prevClickHandler}
                startIcon={<ArrowLeftIcon />}
                variant={BUTTON_VARIANT.secondary}
              />
            )
          }
            <Button
              title={
              currentType !== STEPS_PROFIT_CENTER_MODAL.CREATE_OWN_OPERATION
                ? t('common.next')
                : t('common.confirm')
            }
              onClick={nextclickHandler}
              endIcon={currentType !== STEPS_PROFIT_CENTER_MODAL.CREATE_OWN_OPERATION ? <ArrowRightIcon /> : null}
              disabled={isNextButtonDisabled()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
