import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import AppRoutes from 'constants/AppRoutes';
import { SORT_DIRECTION_INDICATOR } from 'constants/dataSets';
import {
  ORDER_KEY, ORDER_TYPE, SEARCH, SORT, STATUS,
} from 'constants/formFields';
import { DEFAULT_OFFSET } from 'constants/general';
import { ORDERS, VALUE_CHAIN_STATUS } from 'constants/interfaces';
import { VALUE_CHAIN_PROCESSING_CHECK_INTERVAL } from 'constants/valueChain';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import i18n from 'i18n';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import {
  useCustomerListQuery, useOwnOperationListQuery, useProductListQuery, useProfitCenterListQuery, useSupplierListQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import selectProcessingValuesChainMapId from 'store/slices/localStorage/selectors';
import { removeProcessingValuesChainMapId, setProcessingValuesChainMapId } from 'store/slices/localStorage/slice';
import {
  useArchiveValueChainItemMutation,
  useCreateValueChainMapMutation,
  useDeleteValueChainItemMutation,
  useDuplicateValueChainMapMutation,
  useGetValueChainMapListQuery,
  useLazyCheckValueChainMapStatusQuery,
  useRestoreValueChainItemMutation,
  useReviewValueChainItemMutation,
  useUpdateValueChainItemMutation,
} from 'store/slices/valueChainMap/apis/valueChainMapApi';
import { valueChainMapQueryParamsSelector } from 'store/slices/valueChainMap/selectors';
import { resetState, setValueChainMapState } from 'store/slices/valueChainMap/slice';
import { valueChainListSchema } from 'utils/validators';

import ValueChainMapView from './ValueChainMapView';

import type { ISearchInputHandler } from 'components/TextField/interfaces/ITextField';
import type { TOrderByIdentifier, TValueChainStatus } from 'constants/interfaces';
import type { TValueChainMapListSchema } from 'utils/validators';

export default function ValueChainMap() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const valueChainMapQueryParams = useAppSelector(valueChainMapQueryParamsSelector);
  const processingValuesChainMapId = useAppSelector(selectProcessingValuesChainMapId);
  const {
    control,
    reset,
    getValues,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<TValueChainMapListSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(valueChainListSchema),
    defaultValues: {
      [SEARCH]: valueChainMapQueryParams.search,
      [STATUS]: valueChainMapQueryParams.status,
      [SORT]: {
        [ORDER_KEY]: '',
        [ORDER_TYPE]: ORDERS.asc,
      },
    },
  });

  const searchValue = watch(SEARCH);
  const statusValue = watch(STATUS);

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  const {
    data: valueChainMapList, isLoading, refetch,
  } = useGetValueChainMapListQuery({
    search: valueChainMapQueryParams.search || undefined,
    status: valueChainMapQueryParams.status?.value || undefined,
    orderBy: valueChainMapQueryParams.orderBy,
    limit: DEFAULT_OFFSET,
    offset: valueChainMapQueryParams.offset,
  });
  const { data: profitCenterData, isLoading: isProfitCenterListLoading } = useProfitCenterListQuery({});
  const { data: ownOperationData, isLoading: isOwnOperationListLoading } = useOwnOperationListQuery({});
  const { data: supplierData, isLoading: isSupplierListLoading } = useSupplierListQuery({});
  const { data: customerData, isLoading: isCustomerListLoading } = useCustomerListQuery({});
  const { data: productData, isLoading: isProductListLoading } = useProductListQuery({});

  const [updateValueChainItem] = useUpdateValueChainItemMutation();
  const [deleteValueChainItem] = useDeleteValueChainItemMutation();
  const [archiveValueChainItem] = useArchiveValueChainItemMutation();
  const [reviewValueChainItem] = useReviewValueChainItemMutation();
  const [restoreValueChainItem] = useRestoreValueChainItemMutation();
  const [duplicateValueChainMap] = useDuplicateValueChainMapMutation();
  const [createValueChainMap] = useCreateValueChainMapMutation();
  const [checkValueChainMapStatus] = useLazyCheckValueChainMapStatusQuery();
  const debouncedSearch = useDebouncedCallback(() => {
    const { search } = getValues();
    dispatch(setValueChainMapState({
      valueChainMapQueryParams: { ...valueChainMapQueryParams, search, offset: 0 },
    }));
  });

  const onSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  const onReadMoreClients = () => {
    dispatch(setValueChainMapState({
      valueChainMapQueryParams: {
        ...valueChainMapQueryParams,
        offset: valueChainMapQueryParams.offset + DEFAULT_OFFSET,
      },
    }));
  };

  const debouncedSort = useDebouncedCallback((order: string) => {
    dispatch(setValueChainMapState({
      valueChainMapQueryParams: {
        ...valueChainMapQueryParams, orderBy: order, offset: 0,
      },
    }));
  });

  const onFilterChange = () => {
    const { status } = getValues();

    dispatch(setValueChainMapState({
      valueChainMapQueryParams: { ...valueChainMapQueryParams, status, offset: 0 },
    }));
  };

  const onSortChange = (orderByIdentifier: TOrderByIdentifier) => {
    const { sort: { orderKey, orderType } } = getValues();
    const isAsc = orderKey === orderByIdentifier && orderType === ORDERS.asc;
    const order = isAsc ? `${SORT_DIRECTION_INDICATOR}${orderByIdentifier}` : orderByIdentifier;
    setValue(SORT, { [ORDER_KEY]: orderByIdentifier, [ORDER_TYPE]: isAsc ? ORDERS.desc : ORDERS.asc });
    debouncedSort(order);
  };

  useEffect(() => {
    if ((searchValue && !valueChainMapQueryParams?.search) || (statusValue && !valueChainMapQueryParams?.status)) {
      const querySearchValue = searchValue || valueChainMapQueryParams?.search || undefined;
      dispatch(setValueChainMapState({
        valueChainMapQueryParams: {
          ...valueChainMapQueryParams, search: querySearchValue, offset: 0,
        },
      }));
    }
  }, [searchValue, statusValue, valueChainMapQueryParams, setValue, dispatch]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    refetch();
    return () => {
      dispatch(resetState());
    };
  }, [refetch, dispatch]);

  const onChangeStatus = (id: number, status: TValueChainStatus) => {
    updateValueChainItem({ chainId: id, status })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.statusHasBeenUpdated')));
  };

  const onRenameValueChain = (id: number, name: string) => {
    updateValueChainItem({ chainId: id, name })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.valueChainMapHasBeenRenamed')));
  };

  const onDeleteValueChain = (id: number) => {
    deleteValueChainItem({ chainId: id })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.valueChainMapHasBeenDeleted')));
  };

  const onArchiveValueChain = (id: number, reason: string) => {
    archiveValueChainItem({ chainId: id, reason })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.valueChainMapHasBeenArchived')));
  };

  const onReviewValueChain = (id: number, reason: string, status: TValueChainStatus) => {
    reviewValueChainItem({ chainId: id, reason, status })
      .unwrap()
      .then(() => {
        if (status === VALUE_CHAIN_STATUS.approved) {
          toast.success(i18n.t('notifications.valueChainMapHasBeenApproved'));
        } else if (status === VALUE_CHAIN_STATUS.requiresRework) {
          toast.success(i18n.t('notifications.valueChainMapHasBeenReWork'));
        }
      });
  };

  const onDeArchiveValueChain = (id: number) => {
    restoreValueChainItem({ chainId: id })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.valueChainMapHasBeenDeArchived')));
  };

  const onEditValueChain = (valueChainMapId: number) => {
    navigate(generatePath(AppRoutes.companyMap, { valueChainMapId: `${valueChainMapId}` }));
  };

  const onDublicateValueChain = (id: number, name: string) => {
    duplicateValueChainMap({ valueChainMapId: id, name })
      .unwrap()
      .then((data) => {
        refetch();
        dispatch(setProcessingValuesChainMapId(data.data.id));
        toast.success(i18n.t('notifications.valueChainMapHasBeenDublicated'));
      });
  };

  const onCreateNewValueChain = (name: string) => {
    createValueChainMap({ name })
      .unwrap()
      .then((data) => {
        refetch();
        dispatch(setProcessingValuesChainMapId(data.data.id));
        toast.success(i18n.t('notifications.valueChainMapHasBeenCreated'));
      });
  };

  const onCheckDisableCreateMap = () => profitCenterData?.data.items.length === 0
  || ownOperationData?.data.items.length === 0 || supplierData?.data.items.length === 0
  || customerData?.data.items.length === 0 || productData?.data.items.length === 0;

  useEffect(() => {
    if (processingValuesChainMapId.length > 0) {
      const intervalId = setInterval(() => {
        processingValuesChainMapId.forEach((id) => {
          checkValueChainMapStatus({ chainId: `${id}` })
            .unwrap()
            .then((data) => {
              const updatedItem = data.data;
              if (updatedItem.status !== VALUE_CHAIN_STATUS.processing) {
                toast.success(i18n.t('notifications.valueChainMapNameHasBeenCreated', { name: updatedItem.name }));
                dispatch(removeProcessingValuesChainMapId(id));
              }
            })
            .catch(() => {
              dispatch(removeProcessingValuesChainMapId(id));
            });
        });
      }, VALUE_CHAIN_PROCESSING_CHECK_INTERVAL);
      return () => clearInterval(intervalId);
    }
    return undefined;
  }, [checkValueChainMapStatus, processingValuesChainMapId, dispatch]);

  return (
    <ValueChainMapView
      control={control}
      valueChainList={valueChainMapList?.data.items || []}
      onReadMoreClients={onReadMoreClients}
      hasNext={!!valueChainMapList?.data.meta.hasNext}
      onSearch={onSearchChange}
      onFilterChange={onFilterChange}
      onSortChange={onSortChange}
      isWithSearch={isDirty}
      isPageLoading={isLoading || isProfitCenterListLoading || isOwnOperationListLoading
        || isSupplierListLoading || isCustomerListLoading || isProductListLoading}
      onChangeStatus={onChangeStatus}
      onRenameValueChain={onRenameValueChain}
      onDeleteValueChain={onDeleteValueChain}
      onEditValueChain={onEditValueChain}
      onArchiveValueChain={onArchiveValueChain}
      onDublicateValueChain={onDublicateValueChain}
      onDeArchiveValueChain={onDeArchiveValueChain}
      onCreateNewValueChain={onCreateNewValueChain}
      onReviewValueChain={onReviewValueChain}
      disableCreateMap={onCheckDisableCreateMap()}
    />
  );
}
