export default {
  clientActivated: 'Client\'s account has been activated. '
    + 'Invitation(s) to company admin(s) has been successfully sent.',
  clientDeactivated: 'Client\'s account has been deactivated. '
    + 'All users have been deactivated too.',
  userInvited: 'Invitation has been sent',
  userActivated: 'The user has been activated',
  userDeactivated: 'The user has been deactivated',
  userDeleted: 'The user has been deleted',
  informationUpdated: 'The information has been updated',
  userResetPassword: 'Reset password link has been sent to the user. '
    + 'This does not change their password, nor does it force a change.',
  userResetMfa: 'MFA has been reset. The user will be required to set it up again during their next login.',
  userUpdated: 'The information has been updated',
  userCreatedInDraftStatus: 'The user has been created in draft status',
  passwordSuccessfullyChanged: 'You password has been changed',
  unableSaveNewPassword: 'Unable to save the new password. Please check your information and try again',
  mfaCreated: 'MFA has been successfully created',
  somethingWentWrong: 'Something went wrong. Please try again later',
  clientCreatedInDraft: 'The client has been created in draft status',
  informationUpdatedAndSendInvite: 'The information has been updated. Invitation to the user has been '
      + 'successfully sent.',
  recordHasBeenAdded: 'The record has been added',
  recordHasBeenUpdated: 'The record has been updated',
  recordHasBeenArchived: 'The record(s) has been archived',
  recordHasBeenDeArchived: 'The record has been restored',
  statusHasBeenUpdated: 'Status has been updated.',
  valueChainMapHasBeenRenamed: 'Value Chain Map has been renamed.',
  valueChainMapHasBeenDeleted: 'Value Chain Map has been deleted.',
  valueChainMapHasBeenArchived: 'Value Chain Map has been archived.',
  valueChainMapHasBeenApproved: 'Value Chain Map has been approved.',
  valueChainMapHasBeenReWork: 'Value Chain Map has been returned for re-work.',
  valueChainMapHasBeenDeArchived: 'Value Chain Map has been restored.',
  valueChainMapHasBeenDublicated:
    'Value Chain Map duplication may take a few minutes. You will be notified once it\'s ready',
  valueChainMapHasBeenCreated: 'Value Chain Map creation may take a few minutes. You will be notified once it\'s ready',
  valueChainMapNameHasBeenCreated: 'Value Chain Map {{name}} has been created',
  swimlaneHasBeenAdded: 'Swimlane has been added',
  swimlaneHasBeenRenamed: 'Swimlane has been renamed',
  swimlaneHasBeenDeleted: 'Swimlane has been deleted',
  swimlaneCannotBeDeleted: 'Swimlane cannot be deleted because it contains units',
};
