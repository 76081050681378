import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select/Select';
import Textarea from 'components/Textarea/Textarea';
import TextField from 'components/TextField/TextField';
import { TYPE_OF_SERVICE_PROVIDER_OPTIONS } from 'constants/dataSets';
import {
  COMMENT_SERVICE, MONETARY_VALUES, TYPE_OF_SERVICE_PROVIDER, UNIT_CURRENCY_VALUES,
} from 'constants/formFields';
import { DESCRIPTION_LENGTH } from 'constants/general';

import usePercentageFormat from '../hooks/usePercentageFormat';

import type IFieldError from 'store/types/IFieldError';
import type IServiceForm from '../interfaces/IServiceForm';

import styles from '../scss/ServiceFrom.module.scss';

function ServiceForm({ control, enumData }: IServiceForm) {
  const { t } = useTranslation();
  const { handlePercentageChange } = usePercentageFormat();
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.container} ref={wrapperRef}>
      <div className={styles.row}>
        <Controller
          control={control}
          name={TYPE_OF_SERVICE_PROVIDER}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Select
              className={styles.fieldSelect}
              options={TYPE_OF_SERVICE_PROVIDER_OPTIONS}
              placeholder={t('valueChainMap.typeOfServiceProvider')}
              value={value ?? ''}
              onChange={onChange}
              menuPosition="absolute"
              placeholderClassName={styles.placeholder}
              modalRef={wrapperRef}
              label={t('valueChainMap.typeOfServiceProvider')}
              maxMenuHeight={150}
              error={(error as IFieldError)?.value?.message}
              hideErrorText
            />
          )}
        />
        <div className={styles.volumesWrapper}>
          <Controller
            control={control}
            name={MONETARY_VALUES}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                className={styles.fieldTextField}
                name={name}
                value={value}
                error={error?.message}
                onChange={(event) => handlePercentageChange(event.target.value, onChange, true)}
                label={t('valueChainMap.monetaryValues')}
              />
            )}
          />
          <Controller
            control={control}
            name={UNIT_CURRENCY_VALUES}
            render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
              <Select
                className={styles.fieldSelectVolumes}
                options={enumData.currency}
                placeholder={t('valueChainMap.currency')}
                value={error?.message && !isDirty ? '' : value}
                onChange={onChange}
                menuPosition="absolute"
                placeholderClassName={styles.placeholder}
                modalRef={wrapperRef}
                maxMenuHeight={150}
                error={(error as IFieldError)?.value?.message}
                hideErrorText
              />
            )}
          />
        </div>
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name={COMMENT_SERVICE}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <Textarea
              name={name}
              value={value}
              label={t('valueChainMap.comment')}
              placeholder={t('common.typeYourMessage')}
              onChange={(e) => onChange(e.target.value.slice(0, DESCRIPTION_LENGTH))}
              error={error?.message}
              charactersLimit={DESCRIPTION_LENGTH}
            />
          )}
        />
      </div>
    </div>
  );
}

export default ServiceForm;
