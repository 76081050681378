import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { PROFIT_CENTER_ID } from 'constants/formFields';
import {
  useGetNaceCodeTreeQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectProfitCenter } from 'store/slices/dataSets/selectors';
import { useGetOwnOperationDataQuery } from 'store/slices/valueChainMap/apis/valueChainMapApi';
import getOwnOperationsDefaultValues, { combineProfitCenters } from 'utils/formHelpers/getOwnOperationsDefaultValues';
import { createOwnOperationsSchema } from 'utils/validators';

import type { TCreateOperationsSchema } from 'utils/validators';
import type ICreateOwnOperationsForm from '../interfaces/ICreateOwnOperationsForm';

const useCreateOwnOperationsForm = ({ recordId, enumData }: ICreateOwnOperationsForm) => {
  const selectedProfitCenter = useSelector(selectProfitCenter);
  const { data } = useGetOwnOperationDataQuery(recordId ? { chainId: `${recordId}` } : skipToken);

  const { data: naceCodeTreeData } = useGetNaceCodeTreeQuery();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isLoading },
    getValues,
    setError,
    setValue,
    watch,
  } = useForm<TCreateOperationsSchema>({
    resolver: yupResolver(createOwnOperationsSchema),
    mode: 'onSubmit',
    defaultValues: getOwnOperationsDefaultValues(
      {
        values: combineProfitCenters(data?.data, selectedProfitCenter?.value),
        naceCodeTreeData,
        enumData,
        logical: true,
      },
    ),
  });

  useEffect(() => {
    if (data?.data) {
      reset(getOwnOperationsDefaultValues({
        naceCodeTreeData,
        values: combineProfitCenters(data?.data, selectedProfitCenter?.value),
        enumData,
        logical: true,
      }));
    }
  }, [data, enumData, reset, selectedProfitCenter, naceCodeTreeData]);

  const fieldsName = Object.keys(getValues());

  const handleReset = () => {
    reset(getOwnOperationsDefaultValues({
      logical: true,
    }));
    if (selectedProfitCenter) {
      setValue(PROFIT_CENTER_ID, [selectedProfitCenter]);
    }
  };

  return {
    handleSubmit,
    control,
    reset: handleReset,
    isDirty,
    setError,
    isLoading,
    watch,
    fieldsName,
    setValue,
  };
};

export default useCreateOwnOperationsForm;
