import { NAME_FILTERS } from 'constants/dataSets';

import type {
  IBoardFilter, IBoardFilterItemObj, IFilterItemChildren, IFilterItemDeepChildren,
  IFilterListTabsItem,
} from 'store/slices/valueChainMap/interfaces/IBoardFilter';
import type { IBoardFilterItem } from 'store/slices/valueChainMap/interfaces/IBoardFilterItem';

export const checkTypeFilter = (text: string): boolean => text.includes(NAME_FILTERS.OWN_OPERATION);

export const onGenerateConnections = (filtersObj: IBoardFilter) => {
  const filtersDataObj = { ...filtersObj };
  if (!Array.isArray(filtersDataObj.connections)) {
    const connections = filtersDataObj.connections as Record<string, boolean>;
    const arrayConnections = Object.keys(connections).map((key) => ({
      name: key,
      checked: connections[key],
      uniqueIdentifier: key,
    }));

    filtersDataObj.connections = arrayConnections;
  }
  return filtersDataObj;
};

export const getCountersTabsFilters = (filtersArray: IBoardFilterItemObj) => {
  const countCheckedLeafNodes = (items: IFilterItemChildren[] | IFilterItemDeepChildren[]) => {
    let count = 0;
    items.forEach((item) => {
      if (item.children && item.children.length > 0) {
        count += countCheckedLeafNodes(item.children);
      } else if (item.checked) {
        count += 1;
      }
    });
    return count;
  };
  const orgPaths = countCheckedLeafNodes(filtersArray.orgPaths || []);
  const geoPaths = countCheckedLeafNodes(filtersArray.geoPaths || []);
  const sum = orgPaths + geoPaths;
  return { orgPaths, geoPaths, sum };
};

export const getSelectedFilters = (currentFilters: IBoardFilterItem[]) => {
  let counter = 0;
  if (Array.isArray(currentFilters)) {
    currentFilters.forEach((filter) => {
      if (filter.checked) {
        counter += 1;
      }
    });
  }
  return counter;
};

export const resetAllItemsToFalse = (items: IFilterListTabsItem[]): IFilterListTabsItem[] => items.map((item) => ({
  ...item,
  checked: false,
  children: item.children ? resetAllItemsToFalse(item.children) : [],
}));

export const resetAllNestedItemsToFalse = (filterObj: IBoardFilterItemObj): IBoardFilterItemObj => ({
  ...filterObj,
  orgPaths: filterObj.orgPaths
    ? resetAllItemsToFalse(filterObj.orgPaths)
    : [],
  geoPaths: filterObj.geoPaths
    ? resetAllItemsToFalse(filterObj.geoPaths)
    : [],
});

export const resetAllConnectionsToFalse = (
  connections: IBoardFilterItem[],
): IBoardFilterItem[] => connections.map((item) => ({
  ...item,
  checked: false,
}));
