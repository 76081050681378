import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { clsx } from 'clsx';

import { VALUE_CHAIN_MAP } from '../../../../../../constants/general';
import { getUnitHeight } from '../../../../../../utils/reactFlowHelpers';
import UnitStatuses from './UnitStatuses';

import type { ICustomUnitContainer } from '../interfaces/ICustomUnitContainer';

import styles from '../sass/CustomUnitNode.module.scss';

function CustomUnitContainer({
  businessUnitType, type, estimated, childrenCount, children, isChild,
}: ICustomUnitContainer) {
  return (
    <div className={clsx(styles.container, { [styles.childContainer]: isChild })}>
      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />
      {businessUnitType || estimated ? (
        <UnitStatuses type={type} estimated={estimated} businessUnitType={businessUnitType} />
      ) : null}
      <div
        className={clsx(styles.unit, styles[type])}
        style={{
          paddingBottom: (!isChild && childrenCount && childrenCount > 0)
            ? `${(childrenCount * getUnitHeight(type, undefined, estimated))
            + VALUE_CHAIN_MAP.UNIT_PADDING}px` : '0',
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default CustomUnitContainer;
