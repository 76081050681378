import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InputProductIcon } from 'assets/icons/product.svg';

import CustomNodeItem from '../../BusinessStructure/components/CustomNodeItem';

import type { IInputProductNode } from '../interfaces/IInputProductNode';

import styles from '../sass/CustomUnitNode.module.scss';

function InputProductNode({
  name, profitCenterId, relianceOnFreeNaturalResources, criticalityRating,
}: IInputProductNode) {
  const { t } = useTranslation();

  const customNodeItems = [
    {
      title: t('valueChainMap.profitCenterId'),
      value: profitCenterId?.join(', ') || '-',
    },
    {
      title: t('valueChainMap.relianceOnFreeNaturalResources'),
      value: relianceOnFreeNaturalResources?.join(', ') || '-',
      isCapitalize: true,
    },
    {
      title: t('valueChainMap.criticalityRating'),
      value: criticalityRating || '-',
      isCapitalize: true,
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <InputProductIcon />
        <p className={styles.title}>{name}</p>
      </div>
      <div className={styles.content}>
        {customNodeItems.map(({ title, value, isCapitalize }) => (
          <CustomNodeItem
            key={`customNodeItems_${title}`}
            title={title}
            value={value}
            isCapitalize={isCapitalize}
          />
        ))}
      </div>
    </>
  );
}

export default InputProductNode;
