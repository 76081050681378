export default {
  welcomeMessage: 'Try to build something great',
  unknownError: 'Unknown error',
  all: 'All',
  draft: 'Draft',
  invited: 'Invited',
  active: 'Active',
  deactivated: 'Deactivated',
  new: 'New',
  preview: 'Preview',
  saveDraft: 'Save as Draft',
  cancel: 'Cancel',
  next: 'Next',
  name: 'Name',
  type: 'Type',
  country: 'Country',
  description: 'Description',
  direct: 'Direct',
  partner: 'Partner',
  prev: 'Prev',
  back: 'Back',
  save: 'Save',
  admin: 'Admin {{ number }}',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  emailPlaceholder: 'name@example.com',
  addAdmin: 'Add Admin',
  finish: 'Finish',
  status: 'Status',
  enabled: 'Enabled',
  disabled: 'Disabled',
  visibleEnabled: 'Visible & Enabled',
  dimmedDisabled: 'Dimmed & Disabled',
  invisibleDisabled: 'Invisible & Disabled',
  access: 'Access',
  primary: 'Primary',
  secondary: 'Secondary',
  headings1: 'Headings 1',
  headings2: 'Headings 2',
  subtitle: 'Subtitle',
  loremIpsumTitle: 'Lorem ipsum dolor sit amet',
  loremIpsumText: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris '
    + 'nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit '
    + 'in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  loremIpsumTextShort: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa '
    + 'qui officia deserunt mollit anim id est laborum.',
  loremIpsumTextError: 'Excepteur sint occaecat cupidatat non proident',
  browse: 'Browse',
  dragHere: 'or drag a file here',
  companyLogo: 'Company Logo',
  partnerLogo: 'Partner Logo',
  acceptedFormats: 'Accepted formats: .png, .jpg . Maximum upload size: 5MB',
  cropAndSave: 'Crop and Save',
  close: 'Close',
  confirm: 'Confirm',
  password: 'Password',
  continue: 'Continue',
  done: 'Done',
  currentPassword: 'Current Password',
  newPassword: 'New password',
  repeatNewPassword: 'Repeat new password',
  enterPassword: 'Enter password',
  notifications: 'Notifications',
  verified: 'Verified',
  security: 'Security',
  loginAsAdmin: 'Login as company admin',
  companyProfile: 'Company Profile',
  users: 'Users',
  sendInvite: 'Send Invite',
  resendInvite: 'Resend Invite',
  deactivate: 'Deactivate',
  activate: 'Activate',
  logos: 'Logos',
  colors: 'Colors',
  administrator: 'Administrator',
  facilitator: 'Facilitator',
  contributor: 'Contributor',
  auditor: 'Auditor',
  signatory: 'Signatory',
  role: 'Role',
  activateInvite: 'Activate & Invite',
  noData: 'No data',
  deactivationReason: 'Specify deactivation reason',
  archivedVCDetails: 'Archived VC map details',
  requiresReworkVCDetails: 'Requires re-work VC map details',
  deactivationDetails: 'Deactivation details',
  reason: 'Reason',
  typeYourMessage: 'Type your message here',
  person: 'Person',
  date: 'Date',
  phoneNumber: 'Phone Number',
  invalidPhone: 'Invalid Phone',
  configured: 'Configured',
  notConfigured: 'Not configured',
  delete: 'Delete',
  deleteUser: 'Are you sure you want to delete the user?',
  deleteValueChainMap: 'Are you sure you want to delete "{{name}}"?',
  deleteValueChainMapSwimlane: 'Are you sure you want to delete "{{name}}" swimlane?',
  thisActionCannotBeUndone: 'This action cannot be undone',
  infoWillLost: 'All information will be lost',
  userActivity: 'User activity will be suspended but all information will be retained.',
  clientActivity: 'Client activity will be suspended but all information will be retained.',
  keepEditing: 'Keep editing',
  discardChanges: 'Yes, discard changes',
  charactersLimit: '{{counter}}/{{limit}} characters',
  reset: 'Reset',
  ok: 'OK',
  search: 'Search',
  currency: 'Currency',
  create: 'Create',
  apply: 'Apply',
  loginAsUser: 'Login as a user',
  notProvided: '(not provided)',
  hyphen: '-',
  notFound: 'Not found',
  editRecord: 'Edit record',
  archiveRecord: 'Archive record',
  restoreRecord: 'Restore record',
  noResultsWithFilters: 'No results matching your search. <br/> Try using different keywords or filters.',
  noResults: 'No results matching your search. <br/> Try using different keywords.',
  current: 'Current',
  inProgress: 'In progress',
  underReview: 'Under review',
  readyForReview: 'Ready for review',
  requestApproval: 'Request approval',
  approved: 'Approved',
  requiresRework: 'Requires re-work',
  archived: 'Archived',
  enterNewName: 'Enter a new name',
  typeNewNameHere: 'Type new name here',
  changeOrgPathModalTitle: 'Confirm structure change',
  changeOrgPathModalDescription: 'You are about to move <b>{{prevPath}}</b> to a new parent record'
    + ' <b>{{newPath}}</b>. <br><br>This change will be applied immediately, and the new structure will take'
    + ' effect. <br><br>Are you sure you want to proceed with this move?',
  deletionRestricted: 'Deletion restricted',
  deletionRestrictedDescription: 'This swimlane cannot be deleted because it contains one or more cards.',
  deletionRestrictedDescriptionBottom: 'To proceed, please move all cards to another swimlane and try again.',
  filter: 'Filter',
  resetAll: 'Reset all',
  hideSelected: 'Hide selected',
};
