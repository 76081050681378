import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ProcessingIcon } from 'assets/icons/processing.svg';
import InfoModal from 'components/InfoModal/InfoModal';
import { VALUE_CHAIN_STATUS } from 'constants/interfaces';
import { VALUE_CHAIN_STATUSES } from 'constants/valueChain';
import useDropdownDirection from 'hooks/useDropdownDirection';
import useOutsideClick from 'hooks/useOutsideClick';

import type { TValueChainStatus } from 'constants/interfaces';
import type IStatusBadgeWithSelector from './interfaces/IStatusBadgeWithSelector';

import styles from './scss/StatusBadgeWithSelector.module.scss';

export default function StatusBadgeWithSelector({
  selectedStatus,
  onStatusChange,
  tableRef,
  archiveInfo,
  vcMapActions,
  isAccessDisabled,
  singleElement,
}: IStatusBadgeWithSelector) {
  const { t } = useTranslation();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const modalRef = useOutsideClick(() => setIsDropdownVisible(false));
  const { dropdownDirection } = useDropdownDirection({
    isMoreActionsModalOpen: isDropdownVisible,
    modalRef,
    tableRef,
    singleElement,
  });

  const selectHandler = (status: TValueChainStatus) => {
    onStatusChange(status);
    setIsDropdownVisible(false);
  };

  const isArchived = selectedStatus === VALUE_CHAIN_STATUS.archived;
  const isRequiresRework = selectedStatus === VALUE_CHAIN_STATUS.requiresRework;
  const isProcessing = selectedStatus === VALUE_CHAIN_STATUS.processing;
  const accessStatus = vcMapActions?.canChangeStatus ?? !isAccessDisabled;
  const canSelectStatus = !isArchived && !isProcessing && accessStatus;
  const excludedStatuses: TValueChainStatus[] = [
    VALUE_CHAIN_STATUS.approved,
    VALUE_CHAIN_STATUS.requiresRework,
    VALUE_CHAIN_STATUS.archived,
    VALUE_CHAIN_STATUS.processing,
  ];

  const statuses = isRequiresRework ? [VALUE_CHAIN_STATUS.inProgress] : Object
    .values(VALUE_CHAIN_STATUS)
    .filter((status) => !excludedStatuses.includes(status));

  return (
    <div className={styles.selectorContainer} ref={modalRef}>
      <div
        className={`${styles.status} ${styles[selectedStatus]}`}
        onClick={() => setIsDropdownVisible(canSelectStatus ? !isDropdownVisible : false)}
        aria-hidden="true"
      >
        {isProcessing ? <ProcessingIcon className={styles.processingIcon} /> : null}
        <span>{VALUE_CHAIN_STATUSES[selectedStatus]?.text}</span>
        {canSelectStatus ? (
          <ChevronIcon
            className={`${styles.arrowIcon} ${isDropdownVisible ? styles.active : ''}`}
          />
        ) : null}
      </div>
      {isArchived || isRequiresRework ? (
        <InfoIcon className={styles.infoIcon} onClick={() => setIsInfoModalOpen(true)} />
      ) : null}
      {isDropdownVisible ? (
        <div className={`${styles.statusDropdown} ${styles[dropdownDirection]}`}>
          {
          Object.values(statuses).map((status) => (
            <div
              key={status}
              onClick={() => selectHandler(status)}
              aria-hidden="true"
              className={`${styles.status} ${styles[status]}`}
            >
              <span>{VALUE_CHAIN_STATUSES[status]?.text}</span>
            </div>
          ))
        }
        </div>
      ) : null}
      {(isInfoModalOpen && archiveInfo) ? (
        <InfoModal
          title={isArchived ? t('common.archivedVCDetails') : t('common.requiresReworkVCDetails')}
          archiveInfo={archiveInfo}
          onClose={() => setIsInfoModalOpen(false)}
          isLoading={false}
          open={isInfoModalOpen}
        />
      ) : null}
    </div>
  );
}
