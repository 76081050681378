import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import Button from 'components/Button/Button';
import { BUTTON_VARIANT } from 'constants/interfaces';
import formatCamelCase from 'utils/formatText';

import type IHeaderFilterPanel from '../interfaces/IHeaderFilterPanel';

import styles from '../sass/FiltersPanel.module.scss';

function HeaderFilterPanel({
  selectedFilter, onBackStepFilter, onResetAllCurrent, ghostButtonTitle,
}: IHeaderFilterPanel) {
  return (
    <div className={styles.headerFilters}>
      <Button
        title={formatCamelCase(selectedFilter)}
        variant={BUTTON_VARIANT.ghost}
        onClick={onBackStepFilter}
        startIcon={<ArrowLeftIcon />}
        className={styles.headerText}
      />
      <Button
        title={ghostButtonTitle}
        variant={BUTTON_VARIANT.ghost}
        onClick={onResetAllCurrent}
      />
    </div>
  );
}

export default HeaderFilterPanel;
