import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'assets/icons/toast-error.svg';

import type { IErrorMessages } from 'store/slices/dataSets/interfaces/IErrorItem';
import type { IImportMultipleErrorsItemProps } from '../interfaces/IImportMultipleErrorsItemProps';

import styles from '../scss/ImportMultipleErrorsModal.module.scss';

function ImportMultipleErrorsItem({ sheetKey, sheetErrors }: IImportMultipleErrorsItemProps) {
  const { t } = useTranslation();
  const currentSheetErrors = sheetErrors[sheetKey];

  return (
    <div className={styles.errorItemList}>
      <div className={styles.errorItemListTitle}>
        <span>{`${sheetKey}.`}</span>
        <span>{t('dataSetsManagement.importErrorsModal.validationErrors')}</span>
      </div>
      {Object.entries(currentSheetErrors).map(([itemError, errorData]) => (
        <div
          key={itemError}
          className={styles.errorItemWrapperListContent}
        >
          <span>
            <b>{t('dataSetsManagement.importErrorsModal.errorRow')}</b>
            {' '}
            {itemError}
          </span>
          {Object.entries(errorData).map(([itemErrorColumn, errorMessages]) => (
            <div
              key={itemErrorColumn}
              className={styles.errorItemListContent}
            >
              <div className={styles.errorLeftWrapper}>
                <span>
                  <b>{t('dataSetsManagement.importErrorsModal.errorColumn')}</b>
                  {' '}
                  {itemErrorColumn}
                </span>
              </div>
              <div className={styles.errorRightWrapper}>
                <ErrorIcon />
                <div className={styles.errorList}>
                  {errorMessages.map((errorMessage: IErrorMessages) => (
                    <span key={errorMessage.error}>
                      {errorMessage.error}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default ImportMultipleErrorsItem;
