import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Button from 'components/Button/Button';

import ProfitCenterList from './ProfitCenterList';

import type ISelectProfitCenter from '../../interfaces/ISelectProfitCenterModal';

import styles from '../sass/ProfitCentreModal.module.scss';

export default function SelectProfitCenter({
  addNewProfitCenterHandler,
  list,
  ownOperation,
  isLoading,
  onSelectItem,
  selectedItem,
}: ISelectProfitCenter) {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.addNewWrapper}>
        <p className={styles.text}>
          {t(ownOperation ? 'valueChainMap.createNewOwnOperation' : 'valueChainMap.createNewProfitCentre')}
        </p>
        <Button
          title={t(ownOperation ? 'valueChainMap.addNewOwnOperation' : 'valueChainMap.addNewProfitCenter')}
          onClick={addNewProfitCenterHandler}
          variant="action"
          startIcon={<PlusIcon />}
        />
      </div>
      <div>
        <p className={styles.text}>
          {t(
            ownOperation
              ? 'valueChainMap.orSelectFromExistingOwnOperations'
              : 'valueChainMap.orSelectFromExistingProfitCentres',
          )}
        </p>
        <ProfitCenterList
          list={list}
          isLoading={isLoading}
          ownOperation={ownOperation}
          onSelectItem={onSelectItem}
          selectedItem={selectedItem}
        />
      </div>
    </>
  );
}
