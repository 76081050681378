export default {
  title: 'Create New User',
  subTitle: 'Permissions',
  description: 'Configure access rights for the user',
  featureModule: 'Feature & module:',
  entityEditing: 'Entity Editing',
  entityLinking: 'Entity Linking',
  userManagement: 'User Management',
  multiLingual: 'Multi-Lingual',
  uIConfiguration: 'UI Configuration',
  dataSetsManagement: 'Data Sets Management',
  saveAndInvite: 'Save and Invite',
  accessTypes: {
    noAccess: 'No Access',
    viewOnly: 'View Only',
    viewEdit: 'View & Edit',
    viewAudit: 'View & Audit',
    dimmedDisabled: 'Disabled',
    signOff: 'Sign Off',
  },
  selectNeededPillars: 'Select needed pillars:',
  companyAdminPillarsWarning: 'Administrators have full access by default. Their permissions cannot be edited or '
      + 'restricted to ensure they can manage their company’s data and users effectively.',
  companyAdminOwnEditingWarning: 'You do not have the ability to edit your own permissions or role '
      + 'to ensure the integrity and security of the system.\n'
      + 'If you need changes to your permissions or role, please contact another administrator.',
};
