import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useFullScreenHandle from 'hooks/useFullScreenHandle';
import usePillarModulePermission from 'hooks/usePillarModulePermission';

import { REACT_FLOW } from '../../../constants/general';
import {
  COMPANY_MAP_MODALS, type TCompanyMapModal, type TCompanyMapTab,
  type TValueChainStatus,
  USER_PILLAR_MODULES,
  VALUE_CHAIN_STATUS,
} from '../../../constants/interfaces';
import i18n from '../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/useApp';
import selectFullScreenActive from '../../../store/slices/general/selectors';
import {
  useGetValueChainMapDetailsQuery,
  useUpdateValueChainItemMutation,
} from '../../../store/slices/valueChainMap/apis/valueChainMapApi';
import { currentCompanyMapTabSelector } from '../../../store/slices/valueChainMap/selectors';
import { setValueChainMapState } from '../../../store/slices/valueChainMap/slice';
import CompanyMapView from './CompanyMapView';

function CompanyMap() {
  const [activeModal, setActiveModal] = useState<TCompanyMapModal | undefined>(undefined);
  const [currentZoom, setCurrentZoom] = useState<number>(REACT_FLOW.MIN_ZOOM);
  const [isProfitCentreModalOpen, setIsProfitCentreModalOpen] = useState<boolean>(false);

  const { valueChainMapId } = useParams();
  const fullScreenHandle = useFullScreenHandle();
  const dispatch = useAppDispatch();
  const isFullScreenActive = useAppSelector(selectFullScreenActive);
  const currentCompanyMapTab = useAppSelector(currentCompanyMapTabSelector);

  const {
    isAuditOrViewAccess,
    isLoading: isPillarModuleLoading,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.dataSetsManagement },
  );

  const { data, isLoading } = useGetValueChainMapDetailsQuery({ chainId: valueChainMapId || '' });
  const isStatusLow = data?.data.status === VALUE_CHAIN_STATUS.archived
    || data?.data.status === VALUE_CHAIN_STATUS.requiresRework
    || data?.data.status === VALUE_CHAIN_STATUS.approved;
  const isAccessDisabled = isAuditOrViewAccess || isStatusLow;

  const [updateValueChainItem] = useUpdateValueChainItemMutation();

  const onTabHandler = (tab: TCompanyMapTab) => {
    dispatch(setValueChainMapState({ currentCompanyMapTab: tab }));
  };

  const onRenameHandler = () => {
    setActiveModal(COMPANY_MAP_MODALS.RENAME);
  };

  const closeModal = () => {
    setActiveModal(undefined);
  };

  const onRenameSubmit = (name: string) => {
    updateValueChainItem({ chainId: Number(valueChainMapId), name })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.valueChainMapHasBeenRenamed')));
    setActiveModal(undefined);
  };

  const onChangeStatus = (status: TValueChainStatus) => {
    updateValueChainItem({ chainId: Number(valueChainMapId), status })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.statusHasBeenUpdated')));
  };

  const onMoveEnd = (zoom: number) => {
    setCurrentZoom(zoom);
  };

  const onMaximizeHandler = () => {
    const { exit, enter } = fullScreenHandle;
    if (isFullScreenActive) {
      exit();
    } else {
      enter();
    }
  };
  const loader = isPillarModuleLoading && isLoading;
  return (
    <CompanyMapView
      currentTab={currentCompanyMapTab}
      onTabHandler={onTabHandler}
      activeModal={activeModal}
      onRenameHandler={onRenameHandler}
      closeModal={closeModal}
      onRenameSubmit={onRenameSubmit}
      valueChainMapId={valueChainMapId}
      currentZoom={currentZoom}
      onMoveEnd={onMoveEnd}
      data={data?.data}
      isLoading={loader}
      onChangeStatus={onChangeStatus}
      isProfitCentreModalOpen={isProfitCentreModalOpen}
      closeProfitCenterModal={() => setIsProfitCentreModalOpen(false)}
      openProfitCenterModal={() => setIsProfitCentreModalOpen(true)}
      isFullScreenActive={isFullScreenActive}
      onMaximizeHandler={onMaximizeHandler}
      isAccessDisabled={isAccessDisabled}
    />
  );
}

export default CompanyMap;
