import { useState } from 'react';
import { clsx } from 'clsx';
import TooltipPanel from 'components/TooltipPanel/TooltipPanel';
import { VALUE_CHAIN_MAP_CUSTOM_NODES } from 'constants/interfaces';

import { VALUE_CHAIN_MAP } from '../../../../../../constants/general';

import type { ICustomSwimlaneNode } from '../interfaces/ICustomSwimlaneNode';

import styles from '../sass/CustomSwimlaneNode.module.scss';

function CustomSwimlaneNode({ data }: ICustomSwimlaneNode) {
  const [isHovered, setIsHovered] = useState(false);
  const calculatedHeight = data.accumulatedMaxBoardHeight - VALUE_CHAIN_MAP.TITLE_HEIGHT
    - VALUE_CHAIN_MAP.BOARD_PADDING;

  return (
    <div
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(styles.container, {
        [styles.dragTarget]: data.isDragTarget,
      })}
      style={{ height: calculatedHeight || 'auto' }}
    >
      <TooltipPanel
        onEdit={() => data.onEditSwimlane(data)}
        onDelete={() => data.onDeleteSwimlane(data)}
        hideDeleteButton={data.type === VALUE_CHAIN_MAP_CUSTOM_NODES.CUSTOM}
        isAccessDisabled={data.isAccessDisabled}
        isHovered={isHovered}
      >
        <p
          className={clsx(styles.title, 'nopan', {
            [styles.isHovered]: isHovered && !data.isAccessDisabled,
          })}
          onMouseEnter={() => setIsHovered(true)}
        >
          {data.name}
        </p>
      </TooltipPanel>
    </div>
  );
}

export default CustomSwimlaneNode;
