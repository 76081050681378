import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader/Loader';

import DataSetPanelOwnOperationsAdress from './DataSetPanelOwnOperationsAdress';
import DataSetPanelOwnOperationsFinansials from './DataSetPanelOwnOperationsFinansials';
import DataSetPanelOwnOperationsGeneral from './DataSetPanelOwnOperationsGeneral';
import DataSetPanelOwnOperationsName from './DataSetPanelOwnOperationsName';
import StepCounter from './StepCounter';

import type IOwnOperationForm from '../../interfaces/IOwnOperationModalForm';

import styles from '../sass/ProfitCentreModal.module.scss';

export default function OwnOperationForm({
  control,
  recordId,
  enumData,
  onDateOpenedChange,
  dateOpened,
  onNaceSelectionChange,
  naceCodesData,
  naceDivisions,
  onChangeCountry,
  onFindCountries,
  onFindStates,
  onFindCities,
  onChangeState,
  isCitiesDisabled,
  isStatesDisabled,
  startDate,
  onStartDateChange,
  isLoading,
  modalRef,
  selectedProfitCenter,
  profitCenterList = [],
}: IOwnOperationForm) {
  const { t } = useTranslation();
  const placeholder = recordId ? t('common.notProvided') : '';
  const preparedProfitCenterList = profitCenterList.map((item) => ({
    label: item.uniqueIdentifier,
    value: item.uniqueIdentifier,
  }));

  return (
    <>
      <StepCounter ownOperation />
      <div className={styles.OwnOperationModalFormWrapper}>
        <Loader isPageLoading={isLoading} isFullLoader={false} />

        <DataSetPanelOwnOperationsGeneral
          control={control}
          recordId={recordId}
          enumData={enumData}
          onDateOpenedChange={onDateOpenedChange}
          dateOpened={dateOpened}
          placeholder={placeholder}
          selectedProfitCenter={selectedProfitCenter}
          preparedProfitCenterList={preparedProfitCenterList}
        />
        <DataSetPanelOwnOperationsName
          control={control}
          naceCodesData={naceCodesData}
          onNaceSelectionChange={onNaceSelectionChange}
          naceDivisions={naceDivisions}
          placeholder={placeholder}
        />
        <DataSetPanelOwnOperationsFinansials
          control={control}
          onStartDateChange={onStartDateChange}
          startDate={startDate}
          enumData={enumData}
          placeholder={placeholder}
        />
        <DataSetPanelOwnOperationsAdress
          control={control}
          onChangeCountry={onChangeCountry}
          onFindCountries={onFindCountries}
          onChangeState={onChangeState}
          isStatesDisabled={isStatesDisabled}
          onFindStates={onFindStates}
          isCitiesDisabled={isCitiesDisabled}
          onFindCities={onFindCities}
          placeholder={placeholder}
          modalRef={modalRef}
        />
      </div>
    </>
  );
}
