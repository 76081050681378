import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  type Connection,
  type Edge,
  type Node, useEdgesState, useNodesState, useReactFlow,
} from '@xyflow/react';
import { DATA_SETS_ENUM_TYPES, FILTERS_SCHEMA_TYPES, NAME_FILTERS } from 'constants/dataSets';
import { useEnumQuery } from 'store/slices/dataSets/apis/dataSetsApi';
import { selectEnums } from 'store/slices/dataSets/selectors';

import { REACT_FLOW, VALUE_CHAIN_MAP } from '../../../../../constants/general';
import {
  VALUE_CHAIN_MAP_CUSTOM_NODES,
  VALUE_CHAIN_MAP_MODALS, VALUE_CHAIN_PANEL_NODES_TYPES,
} from '../../../../../constants/interfaces';
import useCreateNodesAndEdgesValueChain from '../../../../../hooks/useCreateNodesAndEdgesValueChain';
import {
  useCreateBoardColumnMutation,
  useDeleteBoardColumnMutation,
  useGetValueChainMapBoardQuery,
  useUpdateBoardColumnMutation,
  useUpdateBoardUnitMutation,
} from '../../../../../store/slices/valueChainMap/apis/valueChainMapApi';
import onDragOverByType from '../../../../../utils/dnd';
import {
  getTargetedBoard,
  isTargetNodeMatching,
} from '../../../../../utils/reactFlowHelpers';
import { useDnD } from './components/DndContext';
import useCreateConnection from './hooks/useCreateConnection';
import ValueChainView from './ValueChainView';

import type IExpandedState from 'components/ConnectionVCModal/interfaces/IExpandedState';
import type {
  IBoardFilter, IFilterItemChildren, IFilterItemDeepChildren, IFilterListTabsItem,
} from 'store/slices/valueChainMap/interfaces/IBoardFilter';
import type { IBoardFilterItem } from 'store/slices/valueChainMap/interfaces/IBoardFilterItem';
import type {
  TValueChainMapModal,
} from '../../../../../constants/interfaces';
import type { IValueChainBoardColumn } from '../../../../../store/slices/valueChainMap/interfaces/IValueChainBoardColumn';
import type { ISubmitConnection } from './interfaces/ISubmitConnection';
import type { IValueChain } from './interfaces/IValueChain';

function ValueChain({
  onMoveEnd, valueChainMapId, isFullScreenActive, isAccessDisabled,
}: IValueChain) {
  const { t } = useTranslation();
  const [nodes, setNodes, onNodesChange] = useNodesState([] as Node[]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge[]);
  const [accumulatedMaxBoardHeight, setAccumulatedMaxBoardHeight] = useState(VALUE_CHAIN_MAP.TITLE_HEIGHT * 2);
  const [activeModal, setActiveModal] = useState<TValueChainMapModal | undefined>(undefined);
  const [selectedBoard, setSelectedBoard] = useState<IValueChainBoardColumn | undefined>(undefined);
  const [creatingTargetId, setCreatingTargetId] = useState<number | undefined>(undefined);
  const [targetNode, setTargetNode] = useState<Node | undefined>(undefined);
  const [draggingNode, setDraggingNode] = useState<Node | undefined>(undefined);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentConnection, setCurrentConnection] = useState<Connection | undefined>(undefined);
  const [selectedEdge, setSelectedEdge] = useState<Edge[] | undefined>(undefined);
  const [openFilters, setOpenFilters] = useState(false);
  const [filtersData, setFiltersData] = useState<IBoardFilter | undefined>(undefined);
  const [currentFilters, setCurrentFilters] = useState<string>('');

  const [panelType, setPanelType] = useDnD();
  const { screenToFlowPosition } = useReactFlow();

  const {
    data, isLoading, isFetching,
  } = useGetValueChainMapBoardQuery({
    valueChainMapId: valueChainMapId || '',
    filters: currentFilters,
  });
  const {
    isCreateLogicalConnectionLoading,
    isUpdateLogicalConnectionLoading,
    isDeleteLogicalConnectionLoading,
    isCreateSupplierConnectionLoading,
    isUpdateSupplierConnectionLoading,
    isDeleteSupplierConnectionLoading,
    isCreateServiceConnectionLoading,
    isUpdateServiceConnectionLoading,
    isDeleteServiceConnectionLoading,
    onActionConnect,
  } = useCreateConnection({ selectedEdge: selectedEdge || [] });

  const [updateBoardColumn, { isLoading: isUpdateBoardColumnLoading }] = useUpdateBoardColumnMutation();
  const [createBoardColumn, { isLoading: isCreateBoardColumnLoading }] = useCreateBoardColumnMutation();
  const [deleteBoardColumn, { isLoading: isDeleteBoardColumnLoading }] = useDeleteBoardColumnMutation();
  const [updateBoardUnit, { isLoading: isUpdateBoardUnitLoading }] = useUpdateBoardUnitMutation();

  const { data: enumData, isLoading: isEnumLoading } = useEnumQuery({
    types: [DATA_SETS_ENUM_TYPES.currency, DATA_SETS_ENUM_TYPES.volumeQtyUnit],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectEnums(result.data) }),
  });

  const updateEdgeStyles = (edgesData: Edge[]): Edge[] => edgesData.map((e) => ({
    ...e,
    animated: false,
    selectable: false,
  }));

  const closeModal = () => {
    setSelectedBoard(undefined);
    setActiveModal(undefined);
    setCreatingTargetId(undefined);
    setSelectedEdge(undefined);
    setCurrentConnection(undefined);
    setNodes((prevNodes) => prevNodes.map((prevNode) => (prevNode.type === VALUE_CHAIN_MAP_CUSTOM_NODES.BOARD
      ? { ...prevNode, data: { ...prevNode.data, isDragTarget: false } }
      : prevNode)));
  };

  const onEditSwimlane = useCallback((board: IValueChainBoardColumn) => {
    setActiveModal(VALUE_CHAIN_MAP_MODALS.RENAME);
    setSelectedBoard(board);
  }, []);

  const onDeleteSwimlane = useCallback((board: IValueChainBoardColumn) => {
    setDeleteModalOpen(true);
    setActiveModal(undefined);
    setSelectedBoard(board);
  }, []);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedBoard(undefined);
  };

  const onDeleteSwimlaneConfirm = () => {
    if (selectedBoard?.units.length !== 0
      || selectedBoard?.type !== VALUE_CHAIN_MAP_CUSTOM_NODES.CUSTOM) {
      closeDeleteModal();
      setActiveModal(VALUE_CHAIN_MAP_MODALS.REFUSED_DELETE_SWIMLANE);
      return;
    }
    deleteBoardColumn({ id: Number(selectedBoard?.id) })
      .unwrap()
      .then(() => {
        toast.success(t('notifications.swimlaneHasBeenDeleted'));
        closeModal();
      });
    closeDeleteModal();
  };

  const { createNodesAndEdges } = useCreateNodesAndEdgesValueChain(
    onEditSwimlane,
    onDeleteSwimlane,
    isAccessDisabled,
    setAccumulatedMaxBoardHeight,
  );

  useEffect(() => {
    if (data) {
      // create nodes and edges from the data
      const { nodes: newNodes, edges: newEdges } = createNodesAndEdges(data.data.boards);
      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [createNodesAndEdges, data, setEdges, setNodes]);

  useEffect(() => {
    // update boards and swimlanes while new nodes are added
    setNodes((nodesData) => nodesData.map((node) => {
      if (node.type === VALUE_CHAIN_MAP_CUSTOM_NODES.BOARD || node.type === VALUE_CHAIN_MAP_CUSTOM_NODES.SWIMLANE) {
        return {
          ...node,
          data: {
            ...node.data,
            accumulatedMaxBoardHeight,
          },
        };
      }
      return node;
    }));
  }, [accumulatedMaxBoardHeight, setNodes, data]);

  const onConnect = useCallback(
    (params: Connection) => {
      setCurrentConnection(params);
      setSelectedEdge(undefined);
      setActiveModal(VALUE_CHAIN_MAP_MODALS.VALUE_CONNECTION);
    },
    [],
  );

  const getGroupEdges = useCallback((edge: Edge) => {
    const groupEdges = edges.filter((edgeItem) => edgeItem.target === edge.target
    && edgeItem.source === edge.source);
    setSelectedEdge(groupEdges);
  }, [edges]);

  const onEdgeDoubleClick = useCallback((event: React.MouseEvent, edge: Edge) => {
    getGroupEdges(edge);
    setActiveModal(VALUE_CHAIN_MAP_MODALS.VALUE_CONNECTION);
  }, [getGroupEdges]);

  const onEdgeClick = useCallback((event: React.MouseEvent, edge: Edge) => {
    getGroupEdges(edge);
  }, [getGroupEdges]);

  const onRenameSubmit = (name: string) => {
    updateBoardColumn({ id: Number(selectedBoard?.id), name })
      .unwrap()
      .then(() => {
        toast.success(t('notifications.swimlaneHasBeenRenamed'));
        closeModal();
      });
  };

  const onDragOver = useCallback((event: React.DragEvent<HTMLLIElement>) => {
    onDragOverByType({
      event, panelType, nodes, setNodes, screenToFlowPosition,
    });
  }, [nodes, panelType, screenToFlowPosition, setNodes]);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLLIElement>) => {
      event.preventDefault();

      // check if the dropped element is valid
      if (panelType) {
        const dragPosition = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        // Add the dropped swimlane to the board
        if (panelType === VALUE_CHAIN_PANEL_NODES_TYPES.SWIMLANE) {
          const targetedBoard = getTargetedBoard(dragPosition, nodes);

          if (targetedBoard) {
            setCreatingTargetId(Number(targetedBoard?.data.id));
            setActiveModal(VALUE_CHAIN_MAP_MODALS.ADD_SWIMLANE);
          }
        }

        setPanelType('');
      }
    },
    [panelType, screenToFlowPosition, setPanelType, nodes],
  );

  const onAddSwimlane = (name: string) => {
    createBoardColumn({ valueChainMapId: Number(valueChainMapId), name, boardId: Number(creatingTargetId) })
      .unwrap()
      .then(() => {
        toast.success(t('notifications.swimlaneHasBeenAdded'));
        closeModal();
      });
  };

  const onNodeDrag = (event: React.MouseEvent<Element, MouseEvent>, node: Node) => {
    event.preventDefault();
    let absoluteX = node.position.x;
    let absoluteY = node.position.y;

    // calculate the absolute position of the node if parentId exist
    if (node.parentId) {
      const parentNode = nodes.find((n) => n.id === node.parentId);
      absoluteX += parentNode?.position.x || 0;
      absoluteY += parentNode?.position.y || 0;
    }

    // calculate the center point of the node from position and dimensions
    const centerX = absoluteX;
    const centerY = absoluteY;

    // find if there is a node close to the center point
    const closeNode = nodes.find((n) => {
      let result = false;
      if (n.id !== node.id) {
        const targetCenterX = n.position.x;
        const targetCenterY = n.position.y;
        result = Math.abs(centerX - targetCenterX) < REACT_FLOW.DRAG_N_DROP_ENOUGH_DISTANCE
          && Math.abs(centerY - targetCenterY) < REACT_FLOW.DRAG_N_DROP_ENOUGH_DISTANCE;
      }
      return result;
    });

    setNodes((prevNodes) => prevNodes.map((prevNode) => (
      {
        ...prevNode,
        data: { ...prevNode.data, isDragTarget: false },
      })));

    // set the isDragTarget for the close node
    if (closeNode) {
      // check if close node is following conditions
      if (isTargetNodeMatching(closeNode, node)) {
        setNodes((prevNodes) => prevNodes.map((prevNode) => (prevNode.id === closeNode.id
          ? { ...closeNode, data: { ...closeNode.data, isDragTarget: true } }
          : prevNode)));
        setTargetNode(closeNode);
      } else {
        setTargetNode(undefined);
      }
    } else {
      setTargetNode(undefined);
    }
  };

  const onNodeDragStart = (event: React.MouseEvent<Element, MouseEvent>, node: Node) => {
    event.preventDefault();
    setDraggingNode(node);
  };

  const onNodeDragStop = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    if (targetNode) {
      const targetId = Number(targetNode?.data?.id);
      const isTargetSwimlane = targetNode?.type === VALUE_CHAIN_MAP_CUSTOM_NODES.SWIMLANE;

      updateBoardUnit({
        unitId: Number(draggingNode?.data.id),
        columnId: isTargetSwimlane ? targetId : null,
        parentId: isTargetSwimlane ? null : targetId,
        valueChainMapId: Number(valueChainMapId),
      });
    } else {
      setNodes((prevNodes) => prevNodes.map((prevNode) => (
        prevNode.id === draggingNode?.id
          ? { ...prevNode, position: draggingNode.position }
          : prevNode
      )));
      setDraggingNode(undefined);
    }
  };

  const onAddServiceConnection = (values: ISubmitConnection, connection: IExpandedState, action: string) => {
    onActionConnect(values, currentConnection, connection, valueChainMapId, action);
  };

  const onOpenFilters = () => {
    setOpenFilters(!openFilters);
  };

  const checkIsSelectedFilter = () => {
    const hasSelectedValue = (
      obj: IBoardFilter |
      IBoardFilterItem |
      IFilterItemChildren |
      IFilterItemDeepChildren |
      Record<string, boolean>,
    ): boolean => {
      if (typeof obj === 'boolean') {
        return obj;
      }
      if (Array.isArray(obj)) {
        return obj.some((value) => hasSelectedValue(value));
      }
      if (typeof obj === 'object' && obj !== null) {
        return Object.values(obj).some((value) => hasSelectedValue(value));
      }
      return false;
    };
    return filtersData ? hasSelectedValue(filtersData) : false;
  };

  const getCheckedFilters = (
    selectedFilters: IBoardFilter,
    typeFilters: string,
    nameFields: string,
  ): string => {
    const filterValue = selectedFilters[typeFilters];
    if (!Array.isArray(filterValue)) {
      return '';
    }
    return filterValue
      .filter((conn: IBoardFilterItem) => conn.checked)
      .map((conn: IBoardFilterItem) => `&${nameFields}=${conn.uniqueIdentifier}`)
      .join('');
  };

  const getCheckedOwnOperation = (
    selectedFilters: IFilterListTabsItem[],
    nameFields: string,
  ): string => {
    const getDeepestCheckedPaths = (items: IFilterListTabsItem[]): string[] => items.reduce((acc: string[], item) => {
      if (!item.children || item.children.length === 0) {
        if (item.checked) {
          acc.push(item.geoPath || item.orgPath || '');
        }
        return acc;
      }
      return [...acc, ...getDeepestCheckedPaths(item.children)];
    }, []);
    const checkedPaths = getDeepestCheckedPaths(selectedFilters);
    return checkedPaths.map((path) => `&${nameFields}=${path}`).join('');
  };

  const generateFiltersRequest = useCallback((selectedFilters?: IBoardFilter) => {
    let stringFilters = '';
    if (selectedFilters) {
      FILTERS_SCHEMA_TYPES.forEach((filter) => {
        if (filter.value !== NAME_FILTERS.OWN_OPERATION) {
          stringFilters += getCheckedFilters(selectedFilters, filter.value, filter.type);
        }
        if (filter.value === NAME_FILTERS.OWN_OPERATION && filter.type === NAME_FILTERS.GEO_PATH) {
          const ownOperations = selectedFilters?.ownOperations;
          if (ownOperations && 'geoPaths' in ownOperations && Array.isArray(ownOperations.geoPaths)) {
            stringFilters += getCheckedOwnOperation(ownOperations.geoPaths, filter.type);
          }
        }
        if (filter.value === NAME_FILTERS.OWN_OPERATION && filter.type === NAME_FILTERS.ORG_PATH) {
          const ownOperations = selectedFilters?.ownOperations;
          if (ownOperations && 'orgPaths' in ownOperations && Array.isArray(ownOperations.orgPaths)) {
            stringFilters += getCheckedOwnOperation(ownOperations.orgPaths, filter.type);
          }
        }
      });
    }
    return stringFilters.slice(1);
  }, []);

  const onApplyFilters = () => {
    const newFilters = generateFiltersRequest(filtersData);
    setCurrentFilters(newFilters);
  };

  return (
    <ValueChainView
      onMoveEnd={onMoveEnd}
      nodes={nodes}
      updateEdgeStyles={updateEdgeStyles}
      edges={edges}
      onConnect={!isAccessDisabled ? onConnect : () => {}}
      onEdgeDoubleClick={!isAccessDisabled ? onEdgeDoubleClick : () => {}}
      onEdgeClick={!isAccessDisabled ? onEdgeClick : () => {}}
      onNodesChange={!isAccessDisabled ? onNodesChange : () => {}}
      onEdgesChange={!isAccessDisabled ? onEdgesChange : () => {}}
      isLoading={isLoading
        || isUpdateBoardColumnLoading
        || isCreateBoardColumnLoading
        || isDeleteBoardColumnLoading
        || isUpdateBoardUnitLoading
        || isFetching
        || isEnumLoading
        || isCreateLogicalConnectionLoading
        || isUpdateLogicalConnectionLoading
        || isDeleteLogicalConnectionLoading
        || isCreateSupplierConnectionLoading
        || isUpdateSupplierConnectionLoading
        || isDeleteSupplierConnectionLoading
        || isCreateServiceConnectionLoading
        || isUpdateServiceConnectionLoading
        || isDeleteServiceConnectionLoading}
      onNodeDrag={!isAccessDisabled ? onNodeDrag : () => {}}
      onNodeDragStart={!isAccessDisabled ? onNodeDragStart : () => {}}
      onNodeDragStop={!isAccessDisabled ? onNodeDragStop : () => {}}
      selectedBoard={selectedBoard}
      activeModal={!isAccessDisabled ? activeModal : undefined}
      closeModal={closeModal}
      onRenameSubmit={onRenameSubmit}
      onDrop={!isAccessDisabled ? onDrop : () => {}}
      onDragOver={!isAccessDisabled ? onDragOver : () => {}}
      onAddSwimlane={!isAccessDisabled ? onAddSwimlane : () => {}}
      isFullScreenActive={isFullScreenActive}
      isAccessDisabled={isAccessDisabled}
      isDeleteModalOpen={isDeleteModalOpen}
      setDeleteModalOpen={setDeleteModalOpen}
      onDeleteSwimlaneConfirm={onDeleteSwimlaneConfirm}
      enumData={enumData || {}}
      onAddServiceConnection={onAddServiceConnection}
      selectedEdge={selectedEdge || []}
      onOpenFilters={onOpenFilters}
      openFilters={openFilters}
      filters={data?.data.filters}
      filtersData={filtersData}
      setFiltersData={setFiltersData}
      selectedFilter={checkIsSelectedFilter()}
      onApplyFilters={onApplyFilters}
    />
  );
}

export default ValueChain;
