import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OwnOperationIcon } from 'assets/icons/factory.svg';

import { formatRevenueAndPercentage } from '../../../../../../utils/helpers';
import CustomNodeItem from '../../BusinessStructure/components/CustomNodeItem';

import type { IOwnOperationNode } from '../interfaces/IOwnOperationNode';

import styles from '../sass/CustomUnitNode.module.scss';

function OwnOperationNode({
  name, profitCenterId, naceSection, addressCountry, assetCategory, netBookValue,
}: IOwnOperationNode) {
  const { t } = useTranslation();

  const customNodeItems = [
    {
      title: t('valueChainMap.profitCenterId'),
      value: profitCenterId?.join(', ') || '-',
    },
    {
      title: t('valueChainMap.industryNace'),
      value: naceSection || '-',
      isCapitalize: true,
    },
    {
      title: t('valueChainMap.country'),
      value: addressCountry || '-',
      isCapitalize: true,
    },
    {
      title: t('valueChainMap.bookValue'),
      value: formatRevenueAndPercentage({
        actual: netBookValue?.amount,
        currency: netBookValue?.currency,
      }),
    },
    {
      title: t('valueChainMap.assetCategory'),
      value: assetCategory?.join(', ') || '-',
      isCapitalize: true,
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <OwnOperationIcon />
        <p className={styles.title}>{name}</p>
      </div>
      <div className={styles.content}>
        {customNodeItems.map(({ title, value, isCapitalize }) => (
          <CustomNodeItem
            key={`customNodeItems_${title}`}
            title={title}
            value={value}
            isCapitalize={isCapitalize}
          />
        ))}
      </div>
    </>
  );
}

export default OwnOperationNode;
