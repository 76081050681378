import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import StatusBadgeWithSelector from 'components/StatusBadgeWithSelector/StatusBadgeWithSelector';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import { EXTEND_DATE_FORMAT } from 'constants/general';
import { USER_PILLAR_MODULES, USER_PILLARS, VALUE_CHAIN_STATUS } from 'constants/interfaces';
import dayjs from 'dayjs';

import useVCMapPermission from '../../../../../hooks/useVCMapPermission';
import ValueChainMapTableActions from '../ValueChainMapTableActions/ValueChainMapTableActions';

import type { TValueChainStatus } from 'constants/interfaces';
import type IValueChainMapTableRow from '../../interfaces/IValueChainRow';

import styles from './scss/ValueChainMapTableRow.module.scss';

function ValueChainMapTableRow({
  valueChainItem, onChangeStatus, onRenameValueChain, onDeleteValueChain, onEditValueChain, tableRef,
  onArchiveValueChain, onDeArchiveValueChain, onDublicateValueChain, onReviewValueChain, singleElement,
}: IValueChainMapTableRow) {
  const { t } = useTranslation();

  const {
    isActionsVisible,
    isSignOffAccess,
    vcMapActions,
  } = useVCMapPermission(
    {
      pillarModuleKey: USER_PILLAR_MODULES[USER_PILLARS.valueChainAssessment].valueChainMapManagement,
      currentStatus: valueChainItem.status,
    },
  );

  const onHandleEditVC = () => {
    const statusProcessing = valueChainItem?.status === VALUE_CHAIN_STATUS.processing;
    if (!statusProcessing) {
      onEditValueChain(valueChainItem.id);
    }
  };

  return (
    <TableRow>
      <TableCell className={styles.nameCell}>
        <button
          className={clsx(
            styles.clientButton,
            { [styles.processing]: valueChainItem.status === VALUE_CHAIN_STATUS.processing },
          )}
          type="button"
          onClick={onHandleEditVC}
        >
          <p className={styles.clientName}>{valueChainItem.name}</p>
        </button>
      </TableCell>
      <TableCell>
        <p>{dayjs(valueChainItem.createdAt).format(EXTEND_DATE_FORMAT)}</p>
        <p className={styles.adminName}>
          {t('by')}
          {` ${valueChainItem.createdBy.firstName} ${valueChainItem.createdBy.lastName}`}
        </p>
      </TableCell>
      <TableCell>
        <p>{dayjs(valueChainItem.updatedAt).format(EXTEND_DATE_FORMAT)}</p>
        <p className={styles.adminName}>
          {t('by')}
          {` ${valueChainItem.updatedBy.firstName} ${valueChainItem.updatedBy.lastName}`}
        </p>
      </TableCell>
      <TableCell>
        <StatusBadgeWithSelector
          selectedStatus={valueChainItem.status}
          onStatusChange={(status: TValueChainStatus) => onChangeStatus(valueChainItem.id, status)}
          tableRef={tableRef}
          archiveInfo={valueChainItem?.latestArchivation || valueChainItem?.latestStatus}
          vcMapActions={vcMapActions}
          singleElement={singleElement}
        />
      </TableCell>
      {isActionsVisible || (isSignOffAccess && valueChainItem.status === VALUE_CHAIN_STATUS.requestApproval) ? (
        <TableCell>
          {valueChainItem.status !== VALUE_CHAIN_STATUS.processing && (
            <ValueChainMapTableActions
              vcMapActions={vcMapActions}
              valueChainItemId={valueChainItem.id}
              status={valueChainItem.status}
              onHandleEdit={() => onEditValueChain(valueChainItem.id)}
              isArchived={valueChainItem.status === VALUE_CHAIN_STATUS.archived}
              isSignOffAccess={isSignOffAccess}
              onRenameValueChain={onRenameValueChain}
              onDeleteValueChain={() => onDeleteValueChain(valueChainItem.id)}
              valueChainName={valueChainItem.name}
              tableRef={tableRef}
              onArchiveValueChain={onArchiveValueChain}
              onDeArchiveValueChain={onDeArchiveValueChain}
              onDublicateValueChain={onDublicateValueChain}
              onReviewValueChain={onReviewValueChain}
            />
          )}
        </TableCell>
      ) : null}
    </TableRow>
  );
}

export default ValueChainMapTableRow;
