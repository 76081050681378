export default {
  title: 'Data Sets Management',
  emptyData: 'There are no {{name}} yet',
  emptyDataWithEmptyDataSet: '{{name}} are linked to {{emptyDataSetName}}.<br> Please upload {{emptyDataSetName}} '
      + 'list first.',
  openProfitCentres: 'Open Profit Centres',
  openSuppliers: 'Open Suppliers',
  downloadExample: 'Download Example',
  addRecord: 'Add Record',
  addFilter: 'Add Filter',
  showArchived: 'Show archived',
  archiveSelectedItems: 'Archive selected items',
  totalPages: '{{offset}}-{{totalPages}} of {{total}}',
  selectedOf: '{{selected}} selected of {{total}}',
  suppliers: {
    title: 'Suppliers',
    subTitle: 'Lorem ipsum dolor',
  },
  customers: {
    title: 'Customers',
    subTitle: 'Lorem ipsum dolor',
  },
  products: {
    title: 'Products',
    subTitle: 'Lorem ipsum dolor',
  },
  profitCentres: {
    title: 'Profit Centres',
    subTitle: 'Lorem ipsum dolor',
  },
  menu: {
    ownOperations: 'Own Operations',
    suppliers: 'Suppliers',
    customers: 'Customers',
    products: 'Products',
    profitCentres: 'Profit Centres',
  },
  uploadFile: {
    title: 'Upload File',
    subTitle: 'Accepted formats: xlsx. Maximum upload size: 5MB',
  },
  mappingModal: {
    title: 'Columns Mapping',
    targetColumn: 'Target column',
    sourceColumn: 'Source column',
    fieldsAreMapped: '{{matched}}/{{total}} fields are mapped',
    selectPlaceholder: 'Select column to map',
    targetSheet: 'Target Sheet',
    sourceSheet: 'Source Sheet',
  },
  importErrorsModal: {
    title: 'Import Errors',
    errorPlaceColumn: '<b>Column:</b> {{column}}',
    errorPlaceRow: '<b>Row:</b> {{row}}',
    errorColumn: 'Column:',
    errorRow: 'Row:',
    validationErrors: 'Validation errors',
  },
};
