import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { clsx } from 'clsx';

import Button from '../../../../components/Button/Button';
import StatusBadgeWithSelector from '../../../../components/StatusBadgeWithSelector/StatusBadgeWithSelector';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { BUTTON_VARIANT, TOOLTIP_POSITIONS } from '../../../../constants/interfaces';

import type { ITopLeftControls } from './interfaces/ITopLeftControls';

import styles from './sass/TopLeftControls.module.scss';

function TopLeftControls({
  onRenameHandler, data, isLoading, onChangeStatus, isFullScreenActive, isAccessDisabled,
}: ITopLeftControls) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (!isAccessDisabled) onRenameHandler();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isAccessDisabled && e.key === 'Enter') onRenameHandler();
  };

  return (
    <div className={clsx(styles.leftPanel, {
      [styles.fullScreen]: isFullScreenActive,
      [styles.minimizeElement]: isAccessDisabled,
    })}
    >
      <div className={styles.leftContent}>
        {isFullScreenActive ? null : (
          <>
            <Button
              className={styles.button}
              variant={BUTTON_VARIANT.ghost}
              title={t('common.back')}
              startIcon={<ArrowLeftIcon />}
              onClick={() => navigate(-1)}
            />
            <div className={styles.divider} />
          </>
        )}
        {!isLoading && data ? (
          <>
            <Tooltip
              hint={t('valueChainMap.clickToRename')}
              position={TOOLTIP_POSITIONS.BOTTOM}
              className={styles.hintWrapper}
              hintClassName={styles.hint}
              isDisabled={isAccessDisabled}
            >
              <div
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
              >
                <p className={styles.title}>
                  {data?.name}
                </p>
              </div>
            </Tooltip>
            <StatusBadgeWithSelector
              selectedStatus={data.status}
              onStatusChange={onChangeStatus}
              isAccessDisabled={isAccessDisabled}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default TopLeftControls;
